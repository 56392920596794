import { StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { BaseLang, Button, CellContext, Column, DeleteIcon, Input, ModalButton, message, useLang } from "@vaultinum/vaultinum-sdk";
import { useCallback, useState } from "react";
import { deleteStaffUser, getStaffUsers, updateStaffUserRoles } from "../../../services/staffUserService";
import { StandardModelListView } from "../components";
import AddStaffUserModal from "./AddStaffUserModal";

function getColumns(
    wasAdminWhenLogged: boolean,
    working: boolean,
    onUpdate: (staffUser: StaffUser, role: StaffUserRole, isActive: boolean) => void
): Column<StaffUser>[] {
    return [
        { header: "Email", accessorKey: "email" },
        {
            header: "Name",
            accessorFn: row => `${row.firstName} ${row.lastName}`.trim()
        },
        ...Object.values(StaffUserRole).map(role => ({
            header: role,
            enableColumnFilter: false,
            accessorFn: (row: StaffUser) => row.roles.includes(role),
            cell: (cell: CellContext<StaffUser, unknown>) => {
                const staffUser = cell.row.original;
                return (
                    <div className="flex justify-center">
                        <Input.Checkbox
                            checked={staffUser.roles.includes(role)}
                            onChange={e => onUpdate(staffUser, role, e.target.checked)}
                            disabled={!wasAdminWhenLogged || working}
                        />
                    </div>
                );
            },
            width: 120
        }))
    ];
}

export default function StaffUsersPage({ wasAdminWhenLogged }: { wasAdminWhenLogged: boolean }) {
    const lang = useLang<BaseLang>();
    const [showAddUser, setShowAddUser] = useState(false);
    const [working, setWorking] = useState(false);

    const updateRole = async (staffUser: StaffUser, role: StaffUserRole, add: boolean) => {
        const roles = add ? [...staffUser.roles, role] : staffUser.roles.filter(item => item !== role);
        setWorking(true);
        try {
            await updateStaffUserRoles(staffUser.id, roles);
        } catch (e) {
            void message.error(e.message);
        }
        setWorking(false);
    };

    const deleteStaffUserModal = useCallback(
        (staffUser: StaffUser) => {
            if (wasAdminWhenLogged) {
                return (
                    <ModalButton.Confirm
                        children={`This will permanently delete staff user ${staffUser.email}`}
                        title="Delete Staff User"
                        icon={DeleteIcon}
                        onConfirm={() => deleteStaffUser(staffUser.id)}
                        buttonProps={{
                            type: "default",
                            title: "Delete StaffUser",
                            icon: DeleteIcon,
                            isLoading: false,
                            isDisabled: working
                        }}
                        lang={lang}
                    />
                );
            }

            return undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wasAdminWhenLogged, working]
    );

    const columns = getColumns(wasAdminWhenLogged, working, updateRole);

    return (
        <>
            {wasAdminWhenLogged && (
                <div className="flex">
                    <AddStaffUserModal isOpen={showAddUser} onClose={() => setShowAddUser(false)} />
                    <Button onClick={() => setShowAddUser(true)} isDisabled={working} size="sm" isLoading={working} children="Add Staff User" />
                </div>
            )}
            <StandardModelListView<StaffUser>
                name="Staff Users"
                getModelItems={getStaffUsers}
                searchOptions={{ hideIgnored: true }}
                columns={columns}
                extraActions={deleteStaffUserModal}
                storageKey="table-staffusers"
            />
        </>
    );
}
