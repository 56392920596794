import classNames from "classnames";
import { ReactNode, useState } from "react";

export type TabItemsInterface = {
    icon: React.ElementType;
    title: string;
    isDisabled?: boolean;
    children?: ReactNode;
};

type TabTitleInterface = {
    isSelected?: boolean;
    onClick?: () => void;
} & Omit<TabItemsInterface, "children">;

function TabTitle({ icon: Icon, title, isDisabled, isSelected, onClick }: TabTitleInterface): JSX.Element {
    return (
        <div
            onClick={!isDisabled ? onClick : undefined}
            className={classNames("flex items-center gap-2 border-b-2 py-3 transition", {
                "border-primary": isSelected,
                "border-transparent": !isSelected,
                "cursor-pointer": !isDisabled,
                "cursor-default": isDisabled
            })}
        >
            <span>{<Icon color={isDisabled ? "grey" : "slate"} />}</span>
            <span
                className={classNames("text-sm font-light", {
                    "cursor-not-allowed text-grey-primary": isDisabled,
                    "text-primary": !isDisabled
                })}
                children={title}
            />
        </div>
    );
}

export default function Tabs({
    items,
    justifyPosition,
    isBordered
}: {
    items: TabItemsInterface[];
    justifyPosition: "start" | "center" | "end";
    isBordered?: boolean;
}): JSX.Element {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    return (
        <>
            <div className={classNames(`flex items-center space-x-6 justify-${justifyPosition}`, { "mb-16 border-b": isBordered })}>
                {items.map((item, index) => (
                    <TabTitle key={item.title} isSelected={index === selectedTabIndex} onClick={() => setSelectedTabIndex(index)} {...item} />
                ))}
            </div>
            <div>{items[selectedTabIndex].children}</div>
        </>
    );
}
