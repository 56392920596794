/* eslint-disable @typescript-eslint/no-explicit-any */
import { query, Question, Section, Survey, SurveyRecord } from "@vaultinum/vaultinum-api";
import { Audit } from "@vaultinum/vaultinum-kys-api";
import { getFirestore, runTransaction, Transaction } from "@vaultinum/vaultinum-sdk";
import { v4 as uuidv4 } from "uuid";
import { updateLang } from "../services/surveyLangService";

export type ScopedRecord = SurveyRecord & { surveyScope?: Audit.Scope };

export function cloneOption(option: Question.Option, surveyLang: Survey.Lang, uid: string): Question.Option {
    const newId = uuidv4();
    const existingOptionLang = surveyLang.options[option.id];
    updateLang(surveyLang, uid).option(newId).text(existingOptionLang.text);
    if (existingOptionLang.why) {
        updateLang(surveyLang, uid).option(newId).why(existingOptionLang.why);
    }
    if (existingOptionLang.fixes) {
        Object.keys(existingOptionLang.fixes).forEach(fixKey => {
            updateLang(surveyLang, uid)
                .option(newId)
                .fix(fixKey, existingOptionLang.fixes?.[fixKey] || "");
        });
    }
    return {
        ...option,
        id: newId,
        questions: option.questions?.map(question => cloneQuestion(question, surveyLang, uid))
    };
}

export function cloneQuestion(question: Question, surveyLang: Survey.Lang, uid: string): Question {
    const newId = uuidv4();
    const existingQuestionLang = surveyLang.questions[question.id];
    updateLang(surveyLang, uid).question(newId).text(existingQuestionLang.text);
    if (existingQuestionLang.description) {
        updateLang(surveyLang, uid).question(newId).description(existingQuestionLang.description);
    }
    return {
        ...question,
        id: newId,
        options: question.options.map(option => cloneOption(option, surveyLang, uid))
    };
}

export function cloneSection(section: Section, surveyLang: Survey.Lang, uid: string): Section {
    const newId = uuidv4();
    const existingSectionLang = surveyLang.sections[section.id];
    updateLang(surveyLang, uid).section(newId).title(existingSectionLang.title);
    if (existingSectionLang.description) {
        updateLang(surveyLang, uid).section(newId).description(existingSectionLang.description);
    }
    return {
        ...section,
        id: newId,
        questions: section.questions?.map(question => cloneQuestion(question, surveyLang, uid)),
        sections: section.sections?.map(section => cloneSection(section, surveyLang, uid))
    };
}

export function cloneDataWithType(data: Section | Question | Question.Option, type: Survey.NodeType, surveyLang: Survey.Lang, uid: string) {
    switch (type) {
        case Survey.NodeType.SECTION:
            return cloneSection(data as Section, surveyLang, uid);
        case Survey.NodeType.QUESTION:
            return cloneQuestion(data as Question, surveyLang, uid);
        case Survey.NodeType.OPTION:
            return cloneOption(data as Question.Option, surveyLang, uid);
        default:
            return null;
    }
}

const doDelete = async (
    surveyLangs: Survey.Lang[],
    nodeType: Survey.NodeType,
    userUID: string,
    nodeId: string,
    performDeletion: (parentTransaction?: Transaction) => void,
    parentUpdates: Record<string, any> = {},
    parentTransaction?: Transaction
): Promise<Record<string, any>> => {
    surveyLangs.forEach(surveyLang => {
        const updateDoc = updateLang(surveyLang, userUID)[nodeType.toLowerCase()](nodeId);
        // eslint-disable-next-line no-param-reassign
        parentUpdates[surveyLang.lang] = { ...(parentUpdates[surveyLang.lang] || {}), ...updateDoc.delete(true), ref: updateDoc.ref() };
    });
    performDeletion(parentTransaction);
    return Promise.resolve(parentUpdates);
};

const runBatchUpdate = async (
    performDeletion: (transaction: Transaction, updates?: Record<string, any>) => Promise<Record<string, any> | void>,
    parentUpdates: Record<string, any> = {},
    parentTransaction?: Transaction
) => {
    if (parentTransaction) {
        return performDeletion(parentTransaction, parentUpdates);
    }
    const batchUpdates = async (transaction: Transaction) => {
        const updates = await performDeletion(transaction, parentUpdates);
        if (updates) {
            Object.keys(updates).forEach(lang => {
                const ref = updates[lang].ref; // Get document reference
                delete updates[lang].ref; // Then delete it as it is not needed anymore
                transaction.update(ref, updates[lang]);
            });
        }
    };
    return runTransaction(getFirestore(), batchUpdates).catch(error => {
        console.error(error);
        throw new Error("An error occured, try to remove a subset first.");
    });
};

export function recursiveDeleteOption(
    surveyLangs: Survey.Lang[],
    option: Question.Option,
    uid: string,
    parentUpdates: Record<string, any> = {},
    parentTransaction?: Transaction
): Promise<Record<string, any> | void> {
    return runBatchUpdate(
        transaction =>
            doDelete(
                surveyLangs,
                Survey.NodeType.OPTION,
                uid,
                option.id,
                () => option.questions?.forEach(question => recursiveDeleteQuestion(surveyLangs, question, uid, parentUpdates, transaction)),
                parentUpdates
            ),
        parentUpdates,
        parentTransaction
    );
}

export function recursiveDeleteQuestion(
    surveyLangs: Survey.Lang[],
    question: Question,
    uid: string,
    parentUpdates: Record<string, any> = {},
    parentTransaction?: Transaction
): Promise<Record<string, any> | void> {
    return runBatchUpdate(
        transaction =>
            doDelete(
                surveyLangs,
                Survey.NodeType.QUESTION,
                uid,
                question.id,
                () => question.options.forEach(option => recursiveDeleteOption(surveyLangs, option, uid, parentUpdates, transaction)),
                parentUpdates
            ),
        parentUpdates,
        parentTransaction
    );
}

export function recursiveDeleteSection(
    surveyLangs: Survey.Lang[],
    section: Section,
    uid: string,
    parentUpdates: Record<string, any> = {},
    parentTransaction?: Transaction
): Promise<Record<string, any> | void> {
    return runBatchUpdate(
        transaction =>
            doDelete(
                surveyLangs,
                Survey.NodeType.SECTION,
                uid,
                section.id,
                () => {
                    section.questions?.forEach(question => recursiveDeleteQuestion(surveyLangs, question, uid, parentUpdates, transaction));
                    section.sections?.forEach(sct => recursiveDeleteSection(surveyLangs, sct, uid, parentUpdates, transaction));
                },
                parentUpdates
            ),
        parentUpdates,
        parentTransaction
    );
}

export async function deleteDataTargetFromSurveyLang(
    surveyVersion: Survey.Version,
    target: Survey.NodeTarget,
    selectedSurveyLang: Survey.Lang,
    surveyLangs: Survey.Lang[],
    uid: string
): Promise<Survey.Version> {
    if (target.type === Survey.NodeType.SECTION) {
        const section = query(surveyVersion).getSectionFromId(target.nodeId);
        if (target.property === Survey.NodeTargetProperty.TITLE && section) {
            await recursiveDeleteSection(surveyLangs, section, uid);
        } else if (target.property === Survey.NodeTargetProperty.DESCRIPTION) {
            updateLang(selectedSurveyLang, uid).section(target.nodeId).description(null);
        }
    }
    if (target.type === Survey.NodeType.QUESTION) {
        const question = getQuestion(surveyVersion, target.nodeId);
        if (target.property === Survey.NodeTargetProperty.TEXT && question) {
            await recursiveDeleteQuestion(surveyLangs, question, uid);
        } else if (target.property === Survey.NodeTargetProperty.DESCRIPTION) {
            updateLang(selectedSurveyLang, uid).question(target.nodeId).description(null);
        }
    }
    if (target.type === Survey.NodeType.OPTION) {
        const option = getOption(surveyVersion, target.nodeId);
        if (target.property === Survey.NodeTargetProperty.TEXT && option) {
            await recursiveDeleteOption(surveyLangs, option, uid);
        } else if (target.property === Survey.NodeTargetProperty.WHY) {
            delete option?.context?.why;
            updateLang(selectedSurveyLang, uid).option(target.nodeId).why(null);
        } else if (target.property === Survey.NodeTargetProperty.FIXES && target.subNodeId) {
            if (option?.context?.fixes) {
                option.context.fixes = option?.context?.fixes?.filter(fix => fix.id !== target.subNodeId);
            }
            updateLang(selectedSurveyLang, uid).option(target.nodeId).fix(target.subNodeId, null);
        }
    }
    return surveyVersion;
}

const getMatchingItemFromList = <T, R>(list: T[] | undefined, id: string, matchingFunc: (item: T, id: string) => R): R | null => {
    if (list) {
        for (const item of list) {
            const matchingItem = matchingFunc(item, id);
            if (matchingItem) {
                return matchingItem;
            }
        }
    }
    return null;
};

export function getQuestion(surveyVersion: Survey.Version, questionId: string): Question | null {
    const getMatchingQuestionFromQuestion = (question: Question, questionId: string): Question | null => {
        if (question.id === questionId) {
            return question;
        }
        return getMatchingItemFromList(question.options, questionId, getMatchingQuestionFromOption);
    };
    const getMatchingQuestionFromOption = (option: Question.Option, questionId: string): Question | null => {
        return option.questions ? getMatchingItemFromList(option.questions, questionId, getMatchingQuestionFromQuestion) : null;
    };
    const getMatchingQuestionFromSection = (section: Section, questionId: string): Question | null => {
        const matchingQuestion = getMatchingItemFromList(section.questions, questionId, getMatchingQuestionFromQuestion);
        if (matchingQuestion) {
            return matchingQuestion;
        }
        return getMatchingItemFromList(section.sections, questionId, getMatchingQuestionFromSection);
    };
    return getMatchingItemFromList(surveyVersion.sections, questionId, getMatchingQuestionFromSection);
}

export function getOption(surveyVersion: Survey.Version, optionId: string): Question.Option | null {
    const getMatchingOptionFromQuestion = (question: Question, optionId: string): Question.Option | null => {
        return getMatchingItemFromList(question.options, optionId, getMatchingOptionFromOption);
    };
    const getMatchingOptionFromOption = (option: Question.Option, optionId: string): Question.Option | null => {
        if (option.id === optionId) {
            return option;
        }
        return getMatchingItemFromList(option.questions, optionId, getMatchingOptionFromQuestion);
    };
    const getMatchingOptionFromSection = (section: Section, optionId: string): Question.Option | null => {
        const matchingOption = getMatchingItemFromList(section.questions, optionId, getMatchingOptionFromQuestion);
        if (matchingOption) {
            return matchingOption;
        }
        return getMatchingItemFromList(section.sections, optionId, getMatchingOptionFromSection);
    };
    return getMatchingItemFromList(surveyVersion.sections, optionId, getMatchingOptionFromSection);
}

export function updateSection(surveyVersion: Survey.Version, targetSection: Section): Survey.Version {
    const updateForSection = (section: Section, targetSection: Section): Section => {
        if (section.id === targetSection.id) {
            return targetSection;
        }
        return {
            ...section,
            sections: section.sections?.map(subSection => updateForSection(subSection, targetSection))
        };
    };

    return {
        ...surveyVersion,
        sections: surveyVersion.sections.map(section => updateForSection(section, targetSection))
    };
}

export function updateQuestion(surveyVersion: Survey.Version, targetQuestion: Question): Survey.Version {
    const updateForOption = (option: Question.Option): Question.Option => ({
        ...option,
        questions: option.questions?.map(question => updateForQuestion(question))
    });

    const updateForQuestion = (question: Question): Question => {
        if (question.id === targetQuestion.id) {
            return targetQuestion;
        }
        return {
            ...question,
            options: question.options.map(option => updateForOption(option))
        };
    };

    const updateForSection = (section: Section): Section => ({
        ...section,
        questions: section.questions?.map(question => updateForQuestion(question)),
        sections: section.sections?.map(subSection => updateForSection(subSection))
    });

    return {
        ...surveyVersion,
        sections: surveyVersion.sections.map(section => updateForSection(section))
    };
}

export function updateOption(surveyVersion: Survey.Version, targetOption: Question.Option) {
    const updatForOption = (option: Question.Option, targetOption: Question.Option): Question.Option => {
        if (option.id === targetOption.id) {
            return targetOption;
        }
        return {
            ...option,
            questions: option.questions?.map(question => updateForQuestion(question, targetOption))
        };
    };

    const updateForQuestion = (question: Question, targetOption: Question.Option): Question => ({
        ...question,
        options: question.options.map(option => updatForOption(option, targetOption))
    });

    const updateForSection = (section: Section, targetOption: Question.Option): Section => ({
        ...section,
        questions: section.questions?.map(question => updateForQuestion(question, targetOption)),
        sections: section.sections?.map(subSection => updateForSection(subSection, targetOption))
    });

    return {
        ...surveyVersion,
        sections: surveyVersion.sections.map(section => updateForSection(section, targetOption))
    };
}

export function isSurveyPublished(surveyVersion: Survey.Version) {
    return surveyVersion.publishedLangs.length > 0;
}
