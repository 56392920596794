import { Account, Cart, PriceVariationType } from "@vaultinum/vaultinum-api";
import { ReactNode, useMemo, useState } from "react";
import Stripe from "stripe";
import { Button, openNotificationWithIcon } from "../../../design-system";
import { formatAsCurrency } from "../../../helpers";
import { CommonLang, getLangCode, useLang } from "../../../lang";
import { useAuthContext } from "../../contexts";
import { PaymentMethodDetail } from "./methods";
import { PricingNames, TaxLine } from "./pricing-col-card";

function OrderSummary({ cart }: { cart: Cart }): JSX.Element {
    const lang = useLang<CommonLang>();
    const { userProfile } = useAuthContext();
    const langCode = getLangCode(userProfile);

    const taxes = useMemo(() => {
        if (!cart.taxes?.value) {
            return 0;
        }
        if (cart.taxes?.type === PriceVariationType.Percent) {
            return Math.round(cart.amount * (cart.taxes.value / 100) * 100) / 100;
        }
        return cart.taxes.value;
    }, [cart]);

    const total = useMemo(() => {
        return cart.amount + taxes;
    }, [taxes]);

    const totalDiscount = useMemo(() => {
        if (!cart.discount?.value) {
            return 0;
        }
        if (cart.discount?.type === PriceVariationType.Percent) {
            return Math.round(total * (cart.discount.value / 100) * 100) / 100;
        }
        return cart.discount.value / 100;
    }, [total, cart]);

    return (
        <div className="space-y-2">
            <span className="text-xs text-neutral-400">{lang.payment.summary.order}</span>
            <div className="space-y-1">
                <PricingNames products={cart.products} lang={lang} />
                <TaxLine percentage={cart.taxes?.value || 0} amount={taxes} langCode={langCode} />
                {!!cart.discount?.value && (
                    <div className="flex">
                        <span>{cart.discount.code || lang.payment.payment.discount}</span>
                        <span className="ml-auto">- {formatAsCurrency(totalDiscount, langCode)}</span>
                    </div>
                )}
                <div className="flex">
                    <span className="font-bold">{lang.payment.payment.total}</span>
                    <span className="ml-auto font-semibold">{formatAsCurrency(total - totalDiscount, langCode)}</span>
                </div>
            </div>
        </div>
    );
}

function BillingAddress({ account }: { account: Account }): JSX.Element {
    const lang = useLang<CommonLang>();
    const { billingDetails } = account;
    return (
        <div className="space-y-2">
            <span className="text-xs text-neutral-400">{lang.payment.summary.billingAddress}</span>
            <div className="space-y-1">
                <p>{billingDetails?.address?.line1}</p>
                <p>{billingDetails?.address?.line2}</p>
                <p>{billingDetails?.address?.city}</p>
                <p>{billingDetails?.address?.postalCode}</p>
                <p>{billingDetails?.address?.country}</p>
            </div>
        </div>
    );
}

function SelectedPaymentMethod({ paymentMethod }: { paymentMethod: Stripe.PaymentMethod }): JSX.Element {
    const lang = useLang<CommonLang>();
    return (
        <div className="space-y-2">
            <span className="text-xs text-neutral-400">{lang.payment.summary.paymentMethod}</span>
            <PaymentMethodDetail paymentMethod={paymentMethod} />
        </div>
    );
}

export default function PaymentSummary({
    account,
    cart,
    icon,
    paymentMethod,
    title,
    downloadReceipt
}: {
    account: Account;
    cart: Cart;
    title: string;
    paymentMethod: Stripe.PaymentMethod;
    icon?: ReactNode;
    downloadReceipt?: () => Promise<void>;
}): JSX.Element {
    const [isDownloading, setIsDownloading] = useState(false);
    const lang = useLang<CommonLang>();

    async function onClick() {
        if (downloadReceipt) {
            setIsDownloading(true);
            try {
                await downloadReceipt();
            } catch {
                openNotificationWithIcon({ type: "error", message: lang.payment.downloadReceiptError });
            } finally {
                setIsDownloading(false);
            }
        }
    }

    return (
        <div className="w-full space-y-6 rounded bg-white p-6 shadow-lg" data-id="payment-summary">
            <div className="flex items-center space-x-3">
                {icon}
                <span className="text-2xl font-bold text-primary">{title}</span>
            </div>
            <div className="block w-full divide-y-2 sm:flex sm:divide-x-2 sm:divide-y-0">
                <div className="w-full pb-4 sm:w-1/3 sm:pb-0 sm:pr-10">
                    <OrderSummary cart={cart} />
                </div>
                <div className="w-full py-4 sm:w-1/3 sm:px-10 sm:py-0">
                    <BillingAddress account={account} />
                </div>
                <div className="w-full pt-4 sm:w-1/3 sm:pl-10 sm:pt-0">
                    <SelectedPaymentMethod paymentMethod={paymentMethod} />
                </div>
            </div>
            {!!downloadReceipt && <Button onClick={onClick} isLoading={isDownloading} children={lang.payment.downloadReceipt} />}
        </div>
    );
}
