import { FULL_AUDIT_COLLECTION, FULL_AUDIT_REPORTS_COLLECTION, FULL_AUDIT_REPORTS_PLUGINS_COLLECTION, Report, Update } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport, PluginExecStatus } from "@vaultinum/vaultinum-kys-api";
import {
    collection,
    collectionGroup,
    CollectionReference,
    converter,
    deleteFullAuditCodeAnalysisReportId,
    doc,
    DocumentReference,
    fullAuditDoc,
    getFirestore,
    getItem,
    getItems,
    onSnapshot,
    orderBy,
    query,
    Query,
    Unsubscribe,
    updateDoc,
    where
} from "@vaultinum/vaultinum-sdk";

const CONVERTER = converter<CodeAnalysisReport>();

function reportCollection(fullAuditId: string): CollectionReference<CodeAnalysisReport> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

function reportDoc(fullAuditId: string, reportId: string): DocumentReference<CodeAnalysisReport> {
    return doc(reportCollection(fullAuditId), reportId);
}

export function reportGroupCollection(): Query<CodeAnalysisReport> {
    return collectionGroup(getFirestore(), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

export function getFullAuditCodeAnalysisReport(fullAuditId: string, reportId: string | undefined): Promise<CodeAnalysisReport | null>;
export function getFullAuditCodeAnalysisReport(
    fullAuditId: string,
    reportId: string | undefined,
    onUpdate: (report: CodeAnalysisReport | null) => void
): Unsubscribe;
export function getFullAuditCodeAnalysisReport(
    fullAuditId: string,
    reportId: string | undefined,
    onUpdate?: (report: CodeAnalysisReport | null) => void
): Unsubscribe | Promise<CodeAnalysisReport | null> {
    if (onUpdate) {
        if (!reportId) {
            onUpdate(null);
            return () => {};
        } else {
            return getItem(reportDoc(fullAuditId, reportId), onUpdate);
        }
    }
    if (!reportId) {
        return Promise.resolve(null);
    }
    return getItem(reportDoc(fullAuditId, reportId));
}

export function getActiveFullAuditCodeAnalysisReports(onUpdate: (reports: CodeAnalysisReport[]) => void): Unsubscribe {
    return getItems(query(reportGroupCollection(), where("isArchived", "!=", "true"), where("reportType", "==", Report.ReportType.CODE_ANALYSIS)), onUpdate);
}

function updateFullAuditCodeAnalysisReport(report: CodeAnalysisReport, props: Update<Pick<CodeAnalysisReport, "isArchived" | "stepStatus">>) {
    return updateDoc(reportDoc(report.fullAuditId, report.id), props);
}
export async function archiveAndRemoveCodeAuditReportFromFullAudit(report: CodeAnalysisReport): Promise<void> {
    await updateFullAuditCodeAnalysisReport(report, { isArchived: true });
    await deleteFullAuditCodeAnalysisReportId(report.fullAuditId);
}

export function getInsightPlugins(fullAuditId: string, reportId: string, onUpdate: (plugins: PluginExecStatus[]) => void): () => void {
    return onSnapshot(
        query(
            collection(
                getFirestore(),
                FULL_AUDIT_COLLECTION,
                fullAuditId,
                FULL_AUDIT_REPORTS_COLLECTION,
                reportId,
                FULL_AUDIT_REPORTS_PLUGINS_COLLECTION
            ).withConverter(converter<PluginExecStatus>()),
            orderBy("order", "asc")
        ),
        querySnapshot => onUpdate(querySnapshot.docs.map(doc => doc.data())),
        () => onUpdate([])
    );
}
