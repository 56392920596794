import { components, type GroupBase, type SingleValueProps } from "react-select";
import { BaseSelects, CreatableProps, Option, SelectProps } from "../Input/BaseSelect";

export namespace Select {
    export function Basic<T>(props: Omit<SelectProps<T>, "isSearchable">) {
        return <BaseSelects.Select isSearchable={false} {...props} />;
    }
    export function Search<T>(props: Omit<SelectProps<T>, "isSearchable">) {
        return <BaseSelects.Select isSearchable {...props} />;
    }

    export function Multiple<T>(props: Omit<SelectProps<T, true>, "isMulti">) {
        return <BaseSelects.Select closeMenuOnSelect={false} isClearable isMulti {...props} />;
    }

    export function Creatable<T, Group extends GroupBase<Option<T>>>(props: Omit<CreatableProps<T, true, Group>, "isMulti">) {
        return <BaseSelects.CreatableSelect {...props} isMulti />;
    }

    const SingleValue = (props: SingleValueProps<Option<string>>) => <components.SingleValue {...props} children={props.data.value} />;

    export function CountriesCode({ data, ...props }: BaseSelects.CountriesCallingCodesProps & SelectProps<string>): JSX.Element {
        const options: Option<string>[] = data.map(({ name, countryCode }) => ({
            value: countryCode,
            label: `${countryCode} - ${name}`
        }));
        return (
            <Select.Search
                data-id="country-code"
                className="w-28"
                components={{ SingleValue }}
                options={options}
                styles={{
                    menu: provided => ({ ...provided, width: "200px" }),
                    placeholder: provided => ({ ...provided, display: "none" })
                }}
                {...props}
            />
        );
    }
}
