import * as RadioGroup from "@radix-ui/react-radio-group";

export namespace Radio {
    export function Group({
        value,
        onValueChange,
        children,
        className,
        disabled
    }: {
        value: string;
        onValueChange: (value: string) => void;
        children: React.ReactNode;
        className?: string;
        disabled?: boolean;
    }) {
        return <RadioGroup.Root value={value} onValueChange={onValueChange} className={className} disabled={disabled} children={children} />;
    }

    export function Item(props: RadioGroup.RadioGroupItemProps) {
        return <RadioGroup.Item {...props} />;
    }
}
