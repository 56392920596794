import { getNodeTargetValue, query, Question, Section, Survey } from "@vaultinum/vaultinum-api";
import { User } from "@vaultinum/vaultinum-sdk";
import { keyBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { sameTarget } from "../../../../../../common/AccountTools";
import { includesWithCase } from "../../../../../../common/StringTools";
import { cloneDataWithType, getOption, getQuestion, updateOption, updateQuestion, updateSection } from "../../../../../../common/SurveyTools";
import { updateLang } from "../../../../../../services/surveyLangService";
import { SurveyTreeNode } from "./TreeNode";

export function canBeSibling(sourceNode?: SurveyTreeNode, targetNode?: SurveyTreeNode): boolean {
    if (!sourceNode || targetNode?.key.startsWith(sourceNode.key)) {
        return false;
    }
    if (sourceNode.target?.type === targetNode?.target?.type && sourceNode.target?.property === targetNode?.target?.property) {
        return true;
    }
    if (
        sourceNode.target?.type === Survey.NodeType.OPTION &&
        sourceNode.target?.property === Survey.NodeTargetProperty.TEXT &&
        targetNode?.target?.type === Survey.NodeType.QUESTION
    ) {
        return true;
    }
    if (
        sourceNode.target?.type === Survey.NodeType.QUESTION &&
        targetNode?.target?.type === Survey.NodeType.SECTION &&
        targetNode?.target?.property === Survey.NodeTargetProperty.TITLE
    ) {
        return true;
    }
    if (sourceNode.target?.type === Survey.NodeType.QUESTION && targetNode?.target?.type === Survey.NodeType.OPTION) {
        const question = sourceNode.data as Question;
        const targetOption = targetNode.data as Question.Option;
        return !question.options.some(option => option.id === targetOption.id);
    }
    if (sourceNode.target?.type === Survey.NodeType.SECTION && sourceNode.target.property === Survey.NodeTargetProperty.TITLE && !targetNode?.target) {
        return true;
    }
    return false;
}

export function shouldBeInsertAsChild(sourceNode: SurveyTreeNode, targetNode?: SurveyTreeNode): boolean {
    if (sourceNode.target?.type === Survey.NodeType.QUESTION && targetNode?.target?.type === Survey.NodeType.SECTION) {
        return true;
    }
    if (sourceNode.target?.type === Survey.NodeType.QUESTION && targetNode?.target?.type === Survey.NodeType.OPTION) {
        return true;
    }
    if (sourceNode.target?.type === Survey.NodeType.OPTION && targetNode?.target?.type === Survey.NodeType.QUESTION) {
        return true;
    }
    return false;
}

const convertTreeNodeToOption = (node: SurveyTreeNode): Question.Option => {
    const option = node.data as Question.Option;
    if (node.children) {
        return {
            ...option,
            questions: node.children.filter(subNode => subNode.target?.type === Survey.NodeType.QUESTION).map(convertTreeNodeToQuestion)
        };
    }
    return option;
};

const convertTreeNodeToQuestion = (node: SurveyTreeNode): Question => {
    const question = node.data as Question;
    if (node.children) {
        return {
            ...question,
            options: node.children
                .filter(subNode => subNode.target?.type === Survey.NodeType.OPTION && subNode.target.property === Survey.NodeTargetProperty.TEXT)
                .map(convertTreeNodeToOption)
        };
    }
    return question;
};

const convertTreeNodeToSection = (node: SurveyTreeNode): Section => {
    const section = node.data as Section;
    return {
        ...section,
        sections: node.children
            ?.filter(subNode => subNode.target?.type === Survey.NodeType.SECTION && subNode.target.property === Survey.NodeTargetProperty.TITLE)
            .map(convertTreeNodeToSection),
        questions: node.children?.filter(subNode => subNode.target?.type === Survey.NodeType.QUESTION).map(convertTreeNodeToQuestion)
    };
};

export function convertTreeNodesToSurvey(refSurvey: Survey.Version, nodes: SurveyTreeNode[]): Survey.Version {
    return {
        ...refSurvey,
        sections: nodes
            .filter(node => node.target?.type === Survey.NodeType.SECTION && node.target.property === Survey.NodeTargetProperty.TITLE)
            .map(convertTreeNodeToSection)
    };
}

export function removeTreeNodes(nodes: SurveyTreeNode[], sourceNodes: SurveyTreeNode[]): SurveyTreeNode[] {
    let result = nodes.filter(subNode => !sourceNodes.some(sourceNode => sourceNode.key === subNode.key));
    result = result.map(node => {
        if (node.children) {
            return {
                ...node,
                children: removeTreeNodes(node.children, sourceNodes)
            };
        }
        return node;
    });
    return result;
}

export function addChildrenToNode(nodes: SurveyTreeNode[], targetNode: SurveyTreeNode, newChildren: SurveyTreeNode[]): SurveyTreeNode[] {
    let result = nodes;
    const targetInChildren = nodes.some(child => child.key === targetNode.key);
    // In case of "Add node" type
    if (!targetNode.target && targetInChildren) {
        return [...nodes, ...newChildren];
    }
    result = result.map(node => {
        if (node.key === targetNode.key) {
            return {
                ...node,
                children: [...(node.children || []), ...newChildren]
            };
        }
        return {
            ...node,
            children: node.children ? addChildrenToNode(node.children, targetNode, newChildren) : undefined
        };
    });
    return result;
}

export function checkCanAddChildren(targetNode: SurveyTreeNode, children: SurveyTreeNode[]): null {
    if (!targetNode.target) {
        return null;
    }
    if (targetNode.target.type === Survey.NodeType.SECTION) {
        if (!children.every(child => child.target && [Survey.NodeType.SECTION, Survey.NodeType.QUESTION].includes(child.target.type))) {
            throw Error("You can only add Sections and/or Questions to a Section node");
        }
    } else if (targetNode.target.type === Survey.NodeType.QUESTION) {
        if (!children.every(child => child.target && Survey.NodeType.OPTION === child.target.type)) {
            throw Error("You can only add Options to a Question node");
        }
    } else if (targetNode.target.type === Survey.NodeType.OPTION) {
        if (!children.every(child => child.target && Survey.NodeType.QUESTION === child.target.type)) {
            throw Error("You can only add Questions to a Option node");
        }
    }
    return null;
}

export function cloneNode(node: SurveyTreeNode, surveyLang: Survey.Lang, user: User): SurveyTreeNode {
    if (node.data && node.target) {
        const clonedData = cloneDataWithType(node.data, node.target?.type, surveyLang, user.uid);
        return {
            ...node,
            data: clonedData,
            children: node.children ? node.children.map(child => cloneNode(child, surveyLang, user)) : undefined
        };
    }
    return node;
}

export function moveTreeNode(nodes: SurveyTreeNode[], sourceNode: SurveyTreeNode, targetNode: SurveyTreeNode, insertAsChild: boolean): SurveyTreeNode[] {
    if (nodes.length === 0 || sameTarget(sourceNode.target, targetNode.target)) {
        return nodes;
    }
    let result = nodes.filter(subNode => !sameTarget(subNode.target, sourceNode.target));
    const targetNodeIndex = result.findIndex(subNode => sameTarget(subNode.target, targetNode.target));
    if (!insertAsChild && targetNodeIndex > -1) {
        const order = sourceNode.key.localeCompare(targetNode.key);
        if (order < 0) {
            result = [...result.slice(0, targetNodeIndex + 1), sourceNode, ...result.slice(targetNodeIndex + 1)];
        } else {
            result = [...result.slice(0, targetNodeIndex), sourceNode, ...result.slice(targetNodeIndex)];
        }
    }
    return result.map(node => ({
        ...node,
        children: [
            ...(insertAsChild && sameTarget(node.target, targetNode.target) ? [sourceNode] : []),
            ...moveTreeNode(node.children || [], sourceNode, targetNode, insertAsChild)
        ]
    }));
}

export function nodeMatchSearch(node: SurveyTreeNode, surveyLang: Survey.Lang, search: string, caseSensitive?: boolean): boolean {
    if (node.target) {
        const text = getNodeTargetValue(node.target, surveyLang);
        return !!(search.startsWith(node.key) || includesWithCase(text, search, caseSensitive));
    }
    return false;
}

const filterTreeNode = (
    node: SurveyTreeNode,
    surveyLang: Survey.Lang,
    search: string,
    suggestMode: boolean,
    caseSensitive?: boolean
): SurveyTreeNode | null => {
    const children = node.children?.map(child => filterTreeNode(child, surveyLang, search, suggestMode, caseSensitive)).filter(child => child) as
        | SurveyTreeNode[]
        | undefined;
    if (!children?.length) {
        if (!node.target && (suggestMode || search.length)) {
            return null;
        }
        if (search.length && !nodeMatchSearch(node, surveyLang, search, caseSensitive)) {
            return null;
        }
    }
    return { ...node, children };
};

export function filterTreeNodes(
    nodes: SurveyTreeNode[],
    surveyLang: Survey.Lang,
    search: string,
    suggestMode: boolean,
    caseSensitive?: boolean
): SurveyTreeNode[] {
    return nodes.map(child => filterTreeNode(child, surveyLang, search, suggestMode, caseSensitive)).filter(child => child) as SurveyTreeNode[];
}

export function getNodeAndChildren(node: SurveyTreeNode): SurveyTreeNode[] {
    return [node, ...(node.children?.flatMap(getNodeAndChildren) || [])];
}

export function getNodeTargets(node: SurveyTreeNode): Survey.NodeTarget[] {
    return getNodeAndChildren(node)
        .filter(node => node.target)
        .map(node => node.target as Survey.NodeTarget);
}

export function containsNodeTarget(nodeTargets: Survey.NodeTarget[], target: Survey.NodeTarget): boolean {
    return nodeTargets.some(nodeTarget => sameTarget(nodeTarget, target));
}

export function getNodePath(nodes: SurveyTreeNode[], targetNode?: SurveyTreeNode): string[] {
    const getNodePathWithIndex = (nodes: { [key: string]: SurveyTreeNode }, targetNode?: SurveyTreeNode): string[] => {
        if (targetNode && targetNode.parentKey !== "") {
            const parentNode = nodes[targetNode.parentKey];
            return [...getNodePathWithIndex(nodes, parentNode), targetNode.parentKey];
        }
        return [];
    };
    const nodeIndex = keyBy(nodes, node => node.key);
    return getNodePathWithIndex(nodeIndex, targetNode);
}

export const newEmptySection = (): Section => ({
    id: uuidv4()
});

export function newEmptyFix(tags: string[] = []) {
    return {
        id: uuidv4(),
        tags,
        type: Question.FixType.QUICK
    };
}

export const newEmptyQuestion = (): Question => ({
    id: uuidv4(),
    optionsType: Question.OptionType.Radio,
    options: []
});

export const newEmptyOption = (isContext?: boolean): Question.Option => ({
    id: uuidv4(),
    evaluations: [],
    modifiers: isContext ? { textInput: true, fileUpload: true } : {},
    questions: []
});

export function addNode(surveyVersion: Survey.Version, surveyLang: Survey.Lang, parentTarget: Survey.NodeTarget, nodeType: Survey.NodeType, user: User) {
    let updateSurveyVersion = surveyVersion;
    if (nodeType === Survey.NodeType.QUESTION) {
        const newQuestion = newEmptyQuestion();
        if (parentTarget.type === Survey.NodeType.OPTION) {
            const option = getOption(surveyVersion, parentTarget.nodeId);
            if (option) {
                updateSurveyVersion = updateOption(surveyVersion, {
                    ...option,
                    questions: [...(option.questions || []), newQuestion]
                });
                updateLang(surveyLang, user.uid).question(newQuestion.id).text("");
            }
        }
        if (parentTarget.type === Survey.NodeType.SECTION) {
            const section = query(surveyVersion).getSectionFromId(parentTarget.nodeId);
            if (section) {
                updateSurveyVersion = updateSection(surveyVersion, {
                    ...section,
                    questions: [...(section.questions || []), newQuestion]
                });
                updateLang(surveyLang, user.uid).question(newQuestion.id).text("");
            }
        }
    }
    if (nodeType === Survey.NodeType.OPTION && parentTarget.type === Survey.NodeType.QUESTION) {
        const question = getQuestion(surveyVersion, parentTarget.nodeId);
        if (question) {
            const text = question.options.length < 3 ? ["Yes", "No", "Do not know"][question.options.length] : "";
            const newOption = newEmptyOption();
            updateSurveyVersion = updateQuestion(surveyVersion, {
                ...question,
                options: [...question.options, newOption]
            });
            updateLang(surveyLang, user.uid).option(newOption.id).text(text);
        }
    }
    return updateSurveyVersion;
}
