import { FULL_AUDIT_SURVEY_RECORDS_COLLECTION, QuestionAnswer, QuestionAnswerDeed, SurveyRecord, SurveyVariantRecord } from "@vaultinum/vaultinum-api";
import { CollectionReference, DocumentReference, Query, Unsubscribe, collectionGroup, doc, query, setDoc, where } from "firebase/firestore";
import { first } from "lodash";
import { IndexedDataEventSource, collection, converter, getFirestore, getItems } from "../../../common";
import { fullAuditDoc } from "./fullAuditService";

const CONVERTER = converter<SurveyRecord>();

export function surveyRecordGroupCollection(): Query<SurveyRecord> {
    return collectionGroup(getFirestore(), FULL_AUDIT_SURVEY_RECORDS_COLLECTION).withConverter(CONVERTER);
}

export function surveyRecordCollection(fullAuditId: string): CollectionReference<SurveyRecord> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_SURVEY_RECORDS_COLLECTION).withConverter(CONVERTER);
}

export function surveyRecordDoc(fullAuditId: string, surveyRecordId: string): DocumentReference<SurveyRecord> {
    return doc(surveyRecordCollection(fullAuditId), surveyRecordId);
}

// # Cache variables required for survey records
// - Last cached full audit id - required to reset cached values when selecting a new audit
let lastCachedFullAuditId: string | null = null;
// - Last sent survey records - required to do a local update of records when answering a question
let lastSentSurveyRecords: SurveyRecord[] | null = null;
// - Last updated user id index - required to check if update from firestore needs to be sent to the onUpdate callback
let lastUpdatedUIDIndex: Record<string, QuestionAnswerDeed> = {};
let localUID: string | null = null;
const fullAuditSurveyRecordCache = new IndexedDataEventSource<SurveyRecord[], string>((fullAuditId, onUpdate) => {
    const recordsCollection = surveyRecordCollection(fullAuditId);
    if (lastCachedFullAuditId !== fullAuditId) {
        // Fetched FullAudit has changed - reset cache
        lastSentSurveyRecords = null;
        lastUpdatedUIDIndex = {};
    }
    lastCachedFullAuditId = fullAuditId;
    const unsubscribe = getItems(recordsCollection, updatedSurveyRecords => {
        let updateRequired = !lastSentSurveyRecords || lastSentSurveyRecords.length !== updatedSurveyRecords.length;
        // Check if one of the received records was updated by another user
        updatedSurveyRecords.forEach(surveyRecord => {
            const questions = Object.values(surveyRecord.questions || {});
            const lastUpdatedQuestion = first(questions.sort((a, b) => (b.lastUpdated?.getTime() || 0) - (a.lastUpdated?.getTime() || 0)));
            const lastKnownUpdatedQuestion = lastUpdatedUIDIndex[surveyRecord.id];
            if (!lastUpdatedQuestion) {
                updateRequired = true;
            } else if (
                lastUpdatedQuestion.userUID !== localUID &&
                lastUpdatedQuestion.lastUpdated.getTime() !== lastKnownUpdatedQuestion?.lastUpdated.getTime()
            ) {
                updateRequired = true;
                lastUpdatedUIDIndex[surveyRecord.id] = lastUpdatedQuestion;
            }
        });
        if (updateRequired) {
            lastSentSurveyRecords = updatedSurveyRecords;
            onUpdate(updatedSurveyRecords);
        }
    });
    return () => {
        unsubscribe();
        lastCachedFullAuditId = null;
        lastSentSurveyRecords = null;
        lastUpdatedUIDIndex = {};
        localUID = null;
    };
});

export function getSurveyRecords(fullAuditId: string): Promise<SurveyRecord[]>;
export function getSurveyRecords(fullAuditId: string, onUpdate: (surveyRecords: SurveyRecord[]) => void): () => void;
export function getSurveyRecords(fullAuditId: string, onUpdate?: (surveyRecords: SurveyRecord[]) => void) {
    if (onUpdate) {
        return fullAuditSurveyRecordCache.addListener(fullAuditId, surveyRecords => onUpdate(surveyRecords));
    }
    return fullAuditSurveyRecordCache.getData(fullAuditId);
}

export function getSurveyRecord(fullAuditId: string | undefined, surveyRecordId: string): Promise<SurveyRecord | null>;
export function getSurveyRecord(fullAuditId: string | undefined, surveyRecordId: string, onUpdate: (surveyRecord: SurveyRecord) => void): () => void;
export function getSurveyRecord(
    fullAuditId: string | undefined,
    surveyRecordId: string,
    onUpdate?: (surveyRecord: SurveyRecord) => void
): (() => void) | Promise<SurveyRecord | null> {
    if (onUpdate) {
        if (!fullAuditId) {
            return () => null;
        }
        return fullAuditSurveyRecordCache.addListener(fullAuditId, surveyRecords => {
            const surveyRecord = surveyRecords.find(({ id }) => id === surveyRecordId);
            if (surveyRecord) {
                onUpdate(surveyRecord);
            }
        });
    }
    if (!fullAuditId) {
        return Promise.resolve(null);
    }
    return fullAuditSurveyRecordCache.getData(fullAuditId).then(surveyRecords => surveyRecords.find(({ id }) => id === surveyRecordId) ?? null);
}

export function getSurveyRecordsBySurveyKey(surveyKey: string): Promise<SurveyRecord[]>;
export function getSurveyRecordsBySurveyKey(surveyKey: string, onUpdate: (surveyRecords: SurveyRecord[]) => void): Unsubscribe;
export function getSurveyRecordsBySurveyKey(surveyKey: string, onUpdate?: (surveyRecords: SurveyRecord[]) => void): Promise<SurveyRecord[]> | Unsubscribe {
    const q = query(surveyRecordGroupCollection(), where("surveyKey", "==", surveyKey));
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}

export async function answerQuestion(userUID: string, surveyVariantRecord: SurveyVariantRecord, questionAnswer: QuestionAnswer): Promise<SurveyRecord> {
    const questionAnswerDeed: QuestionAnswerDeed = {
        ...questionAnswer,
        lastUpdated: new Date(),
        userUID
    };
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { variants, defaultVariants, preambleSection, ...surveyRecord } = surveyVariantRecord;
    const newRecord = {
        ...surveyRecord,
        questions: {
            ...surveyRecord.questions,
            [questionAnswerDeed.questionId]: questionAnswerDeed
        }
    };
    // # Keep track of latest change in local cache
    // - Keep track of who did the last change - this will be used when checking against Firestore updates
    lastUpdatedUIDIndex[surveyVariantRecord.id] = questionAnswerDeed;
    localUID = userUID;
    // - Do a local update of the answered surveyRecord to provide immediate feedback to the UI
    lastSentSurveyRecords = (lastSentSurveyRecords || []).map(record => (record.id === newRecord.id ? newRecord : record));
    // - Notify listeners (this will trigger the UI update)
    fullAuditSurveyRecordCache.onUpdate(surveyRecord.fullAuditId, lastSentSurveyRecords);
    // # Update the firestore SurveyRecord
    await setDoc(
        surveyRecordDoc(surveyRecord.fullAuditId, surveyRecord.id),
        { questions: { [questionAnswerDeed.questionId]: questionAnswerDeed } },
        { merge: true }
    );
    return newRecord;
}
