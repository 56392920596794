import { ColCardInterface } from "../..";
import { CardCommon } from "../Card/CardCommon";

export namespace ColCardCommon {
    export function HeaderWithActions({ "data-id": dataId, header, actions }: CardCommon.WithActions & Pick<ColCardInterface, "data-id" | "header">) {
        return (
            <div className="flex">
                {header && <div>{header}</div>}
                <div className="ml-auto" children={CardCommon.Actions({ actions, "data-id": dataId })} />
            </div>
        );
    }

    export function FooterWithButtons({ "data-id": dataId, footer, buttons }: CardCommon.WithButtons & Pick<ColCardInterface, "data-id" | "footer">) {
        return (
            <div className="flex flex-col gap-2">
                <CardCommon.Buttons data-id={dataId} buttons={buttons} />
                {footer && <div>{footer}</div>}
            </div>
        );
    }
}
