import { StripeCardElement, StripeIbanElement } from "@stripe/stripe-js";
import { App } from "@vaultinum/vaultinum-api";
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import Stripe from "stripe";

export type PaymentMethodFormContextInterface = {
    paymentMethodType: App.PaymentMethod.CARD | App.PaymentMethod.SEPA_DEBIT;
    setPaymentMethodType: (paymentMethod: App.PaymentMethod.CARD | App.PaymentMethod.SEPA_DEBIT) => void;
    paymentMethod: Stripe.PaymentMethod | undefined;
    setPaymentMethod: (paymentMethod: Stripe.PaymentMethod | undefined) => void;
    isDisabled: boolean;
    setIsDisabled: (isDisabled: boolean) => void;
    isFormValid: boolean;
    setIsFormValid: (isFormValid: boolean) => void;
    cardName: string | undefined;
    setCardName: (name: string) => void;
    cardElement: StripeCardElement | null;
    setCardElement: (cardElement: StripeCardElement | null) => void;
    accountHolderName: string | undefined;
    setAccountHolderName: (name: string) => void;
    accountHolderEmail: string | undefined;
    setAccountHolderEmail: (email: string) => void;
    ibanElement: StripeIbanElement | null;
    setIbanElement: (ibanElement: StripeIbanElement | null) => void;
};

const Context = createContext<PaymentMethodFormContextInterface>({} as PaymentMethodFormContextInterface);

export function PaymentMethodFormProvider({ children }: { children: ReactNode }) {
    const [paymentMethodType, setPaymentMethodType] = useState<App.PaymentMethod.CARD | App.PaymentMethod.SEPA_DEBIT>(App.PaymentMethod.CARD);
    const [paymentMethod, setPaymentMethod] = useState<Stripe.PaymentMethod>();

    // Shared
    const [isDisabled, setIsDisabled] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    // Card form
    const [cardName, setCardName] = useState<string>();
    const [cardElement, setCardElement] = useState<StripeCardElement | null>(null);
    // Iban form
    const [accountHolderName, setAccountHolderName] = useState<string>();
    const [accountHolderEmail, setAccountHolderEmail] = useState<string>();
    const [ibanElement, setIbanElement] = useState<StripeIbanElement | null>(null);

    useEffect(() => {
        if (paymentMethodType !== App.PaymentMethod.CARD) {
            setCardName(undefined);
            setCardElement(null);
        }
        if (paymentMethodType !== App.PaymentMethod.SEPA_DEBIT) {
            setAccountHolderName(undefined);
            setAccountHolderEmail(undefined);
            setIbanElement(null);
        }
    }, [paymentMethod]);

    const exposed = useMemo(
        (): PaymentMethodFormContextInterface => ({
            paymentMethodType,
            setPaymentMethodType,
            isDisabled,
            setIsDisabled,
            isFormValid,
            setIsFormValid,
            cardName,
            setCardName,
            cardElement,
            setCardElement,
            accountHolderName,
            setAccountHolderName,
            accountHolderEmail,
            setAccountHolderEmail,
            ibanElement,
            setIbanElement,
            paymentMethod,
            setPaymentMethod
        }),
        [
            paymentMethodType,
            setPaymentMethodType,
            isDisabled,
            setIsDisabled,
            isFormValid,
            setIsFormValid,
            cardName,
            setCardName,
            cardElement,
            setCardElement,
            accountHolderName,
            setAccountHolderName,
            accountHolderEmail,
            setAccountHolderEmail,
            ibanElement,
            setIbanElement,
            paymentMethod,
            setPaymentMethod
        ]
    );

    return <Context.Provider value={exposed} children={children} />;
}

export function usePaymentMethodFormContext(): PaymentMethodFormContextInterface {
    return useContext(Context);
}
