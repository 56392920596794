/* eslint-disable no-param-reassign */
import { useSize } from "ahooks";
import classNames from "classnames";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";

const getNodePath = (node: TreeMapNode | null): TreeMapNode[] => {
    if (!node) {
        return [];
    }
    return node.parent ? [...getNodePath(node.parent), node] : [node];
};

export type TreeMapNode = {
    id: string;
    name: string;
    parent?: TreeMapNode;
    children?: TreeMapNode[];
    value?: number;
    tags?: string[];
};

const fileColors = ["#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c"];
const folderColors = ["#ffffe5", "#fee391", "#fec44f", "#fe9929", "#ec7014", "#cc4c02", "#993404", "#662506"];

const canvasTreemap = (
    data: TreeMapNode,
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    onClick: (item: TreeMapNode) => void,
    onHover: (item: TreeMapNode | null) => void,
    highlightNodeIds: string[],
    hoverNodeId?: string
) => {
    // - Calculate TreeMap tiles
    const root = d3.treemap<TreeMapNode>().tile(d3.treemapSquarify).size([canvas.offsetWidth, canvas.offsetHeight]).paddingTop(18).paddingLeft(2).round(true)(
        d3
            .hierarchy(data)
            .sum(d => d.value || 0)
            .sort((a, b) => (b.value || 0) - (a.value || 0))
    );

    function renderTile(node: d3.HierarchyRectangularNode<TreeMapNode>) {
        context.save(); // For clipping the text
        context.beginPath();
        context.rect(node.x0, node.y0, node.x1 - node.x0, node.y1 - node.y0);
        const isHovered = hoverNodeId === node.data.id;
        // - Define tile color
        let color: string;
        if (node.data.children) {
            color = folderColors[node.depth % folderColors.length];
        } else {
            const fileRatio = (node.value || 1) / (node.parent?.value || 1);
            color = fileColors[Math.round(fileRatio * (fileColors.length - 1))];
        }
        if (isHovered) {
            context.fillStyle = d3.color(color)?.darker().formatHex() || "";
            if (node.data.children) {
                canvas.style.cursor = node.depth === 0 ? "zoom-out" : "zoom-in";
            } else {
                canvas.style.cursor = "pointer";
            }
        } else {
            context.fillStyle = color;
        }
        // - Draw background tile
        context.fill();
        // - Draw tile border
        let lineWidth = 0.5;
        if (highlightNodeIds.includes(node.data.id)) {
            // - Draw highlight
            lineWidth = 2;
            context.setLineDash([4, 1]);
            context.strokeStyle = "red";
        } else if (isHovered) {
            context.strokeStyle = d3.color(color)?.darker().darker().formatHex() || "";
        } else {
            context.strokeStyle = d3.color(color)?.darker().formatHex() || "";
        }
        context.strokeRect(node.x0 + lineWidth / 2, node.y0 + lineWidth / 2, node.x1 - node.x0 - lineWidth, node.y1 - node.y0 - lineWidth);
        // Generate the Clip Path
        context.clip();

        // - Draw tile name
        const textData = `${node.data.children ? "🗀" : ""} ${node.data.name}`;
        context.fillStyle = d3.color(color)?.darker().darker().darker().formatHex() || "";
        context.font = node.data.children ? "bold 14px sans-serif" : "12px sans-serif";
        context.fillText(
            textData,
            node.x0 + 2, // X + paddingLeft
            node.y0 + 14 // Y + (textHeight + paddingTop)
        );
        const textMeasure = context.measureText(textData);
        if (!node.data.children) {
            // - Draw the value for leaf tiles
            context.font = "10px sans-serif";
            context.fillText(String(node.data.value || 0), node.x0 + 2, node.y0 + 26);
        }
        // - Draw the tag list as bullets
        if (node.data.tags) {
            context.fillStyle = "red";
            for (let i = 0; i < node.data.tags.length; i += 1) {
                context.beginPath();
                context.arc(node.x0 + 10 + textMeasure.width + i * 8, node.y0 + 10, 3, 0, 2 * Math.PI);
                context.fill();
            }
        }
        context.restore(); // Restore so you can continue drawing
    }

    // Render folders tiles first
    root.each(node => (node.data.children ? renderTile(node) : null));
    // Render files tiles over folder tiles
    root.leaves().forEach(renderTile);

    // - Add Mouse event handling
    const findNodeAt = (x: number, y: number) => {
        let clickedNode: d3.HierarchyRectangularNode<TreeMapNode> = root;
        for (const node of root.descendants()) {
            if (node.x0 < x && node.x1 > x && node.y0 < y && node.y1 > y) {
                if (!clickedNode || clickedNode.depth < node.depth) {
                    clickedNode = node;
                }
            }
        }
        return clickedNode;
    };
    canvas.onclick = e => onClick(findNodeAt(e.offsetX, e.offsetY).data);
    canvas.onmousemove = e => onHover(findNodeAt(e.offsetX, e.offsetY).data);
    canvas.onmouseleave = () => onHover(null);
};

export default function TreeMap({
    data,
    className,
    hoverNode: forceHoverNode,
    highlightNodeIds,
    onNodeSelected
}: {
    data: TreeMapNode;
    className?: string;
    hoverNode?: TreeMapNode;
    highlightNodeIds?: string[];
    onNodeSelected?: (node: TreeMapNode) => void;
}) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const treeMapRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const [selectedNode, setSelectedNode] = useState<TreeMapNode | null>(null);
    const [hoverNode, setHoverNode] = useState<TreeMapNode>();
    const canvasSize = useSize(canvasRef);

    function renderCanvas() {
        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext("2d");
            if (context) {
                canvasTreemap(
                    selectedNode ?? data,
                    canvas,
                    context,
                    node => {
                        if (node.id === selectedNode?.id && node.parent) {
                            setSelectedNode(node.parent);
                        } else if (node.children) {
                            setSelectedNode(node);
                        }
                        onNodeSelected?.(node);
                    },
                    node => {
                        if (node?.id !== hoverNode?.id) {
                            setHoverNode(node ?? undefined);
                        }
                    },
                    highlightNodeIds || [],
                    hoverNode?.id || forceHoverNode?.id
                );
            }
        }
    }
    useEffect(renderCanvas, [data, selectedNode, hoverNode, highlightNodeIds, forceHoverNode, onNodeSelected, canvasSize]);

    return (
        <div ref={treeMapRef} className={classNames("flex h-full flex-col", className)}>
            <div ref={headerRef}>
                {getNodePath(selectedNode ?? data).map((node, i) => (
                    <span key={node.id}>
                        {i > 0 && <>&nbsp;&gt;&nbsp;</>}
                        <span className="cursor-pointer underline hover:text-blue-600" onClick={() => setSelectedNode(node)}>
                            {node.name}
                        </span>
                    </span>
                ))}
            </div>
            <canvas
                className="w-full"
                ref={canvasRef}
                width={canvasSize?.width}
                height={(treeMapRef.current?.offsetHeight || 0) - (headerRef.current?.offsetHeight || 0)}
            />
        </div>
    );
}
