import { CommonLang, useLang } from "../../lang";
import { AppMenuItem } from "../components";
import { AppInfo, useAppContext, useWhiteLabelContext } from "../contexts";

type EnumType = Record<string, string>;

export default function useAvailableAppItems<TAppItem extends { id: string }, TAppEnum extends EnumType>(): AppMenuItem[] {
    const lang = useLang<CommonLang<TAppEnum>>();
    const { appsInfos } = useAppContext<TAppItem, TAppEnum[keyof TAppEnum]>();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const restrictedApps = whiteLabelDomain?.configuration ? Object.keys(whiteLabelDomain?.configuration) : null;
    const apps = Object.values<AppInfo<TAppEnum[keyof TAppEnum]>>(appsInfos);
    return apps
        .filter(({ appCode }) => appCode !== "account" && (!restrictedApps || restrictedApps.includes(appCode)))
        .map(appInfo => ({
            ...appInfo,
            ...lang.apps[appInfo.appCode],
            href: appInfo.baseUrl
        }));
}
