import { AccountUser, isDefined, StaffUser } from "@vaultinum/vaultinum-api";
import { Audit, FULL_AUDIT_AGREEMENTS, FULL_AUDIT_COLLECTION, queryAudit } from "@vaultinum/vaultinum-kys-api";
import {
    collection,
    collectionGroup,
    CollectionReference,
    converter,
    doc,
    DocumentReference,
    getAccount,
    getAccountUser,
    getDocs,
    getFirestore,
    getItem,
    getItems,
    query,
    Unsubscribe,
    where,
    writeBatch
} from "@vaultinum/vaultinum-sdk";

function auditCollection(): CollectionReference<Audit> {
    return collection(getFirestore(), FULL_AUDIT_COLLECTION).withConverter(converter<Audit>());
}

function auditDoc(auditId: string): DocumentReference<Audit> {
    return doc(auditCollection(), auditId);
}

export function getAudit(auditId: string): Promise<Audit | null>;
export function getAudit(auditId: string, onUpdate: (audit: Audit | null) => void): Unsubscribe;
export function getAudit(auditId: string, onUpdate?: (audit: Audit | null) => void): Promise<Audit | null> | Unsubscribe {
    if (onUpdate) {
        return getItem(auditDoc(auditId), onUpdate);
    }
    return getItem(auditDoc(auditId));
}

export function getAuditsByAccountIdAndDomain(
    accountId: string,
    whiteLabelDomainId: string | null,
    isCrossDomainOwner: boolean,
    onUpdate: (audits: Audit[]) => void
): Unsubscribe {
    // Cannot use multiple constraints as it requires an index on dynamic fields which is not possible
    // https://stackoverflow.com/questions/59008167
    const q = query(auditCollection(), where(`roles.${accountId}`, "!=", null));
    return getItems(q, audits => onUpdate(isCrossDomainOwner ? audits : audits.filter(audit => audit.whiteLabelDomainId === whiteLabelDomainId)));
}

export async function getFullAuditReviewers(fullAudit: Audit): Promise<(AccountUser | StaffUser)[]> {
    if (queryAudit(fullAudit).roleExists(Audit.Role.REVIEWER) && fullAudit.reviewerAccount?.accountUserIds?.length) {
        const reviewerAccountIds = queryAudit(fullAudit).getAccountIdsByRole(Audit.Role.REVIEWER);
        const accountUsers = (
            await Promise.all(
                reviewerAccountIds.map(async accountId => {
                    const account = await getAccount(accountId);
                    if (!account) {
                        return [];
                    }
                    const reviewers = await Promise.all((fullAudit.reviewerAccount?.accountUserIds ?? []).map(userId => getAccountUser(account, userId)));
                    return reviewers.filter(isDefined);
                })
            )
        ).flat();
        return accountUsers;
    }
    return [];
}

export async function removeFullAuditAgreements(): Promise<void> {
    const querySnapshot = await getDocs(query(collectionGroup(getFirestore(), FULL_AUDIT_AGREEMENTS)));
    const batch = writeBatch(getFirestore());
    querySnapshot.docs.forEach(docSnapshot => batch.delete(docSnapshot.ref));
    await batch.commit();
}
