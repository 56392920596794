import { merge } from "lodash";
import { esCommon } from "../../../common";
import { en } from "./en";

export const es: typeof en = merge(esCommon, {
    apps: {
        account: {
            name: "Cuenta",
            description: "Gestione su cuenta y su organización."
        },
        deposit: {
            name: "Depósito de PI",
            description: "Demuestre su propiedad"
        },
        escrow: {
            name: "Escrow agreement",
            description: "Tranquilice a sus clientes y asegure la continuidad del negocio"
        },
        "full-audit": {
            name: "Due Diligence",
            description: "Realice una due diligence de software para mitigar sus riesgos de propiedad intelectual, ciberseguridad y mantenibilidad."
        },
        timestamping: {
            name: "Timestamping",
            description: "Demuestre la fecha exacta y la integridad de los datos de sus documentos importantes." // to review
        }
    },
    register: {
        slogan: "La solución en línea para proteger y auditar sus activos digitales"
    }
});
