import classNames from "classnames";
import { ElementType } from "react";
import { CrossCircleFilledIcon } from "../Icons";

export default function BadgeCard({
    id,
    name,
    Icon,
    isDisabled,
    onClick,
    onDelete
}: {
    id: string;
    name: string;
    Icon: ElementType;
    isDisabled?: boolean;
    onClick?: (id: string) => void;
    onDelete?: (id: string) => void;
}): JSX.Element {
    return (
        <div className="flex">
            <div
                className={classNames("relative flex items-center py-0.5 px-1.5 gap-2 bg-white border border-primary rounded-lg group text-primary underline", {
                    "cursor-not-allowed opacity-50": isDisabled
                })}
            >
                <Icon />
                <span onClick={() => onClick?.(id)}>{name}</span>
                {onDelete && !isDisabled && (
                    <div className={"absolute -top-2 -right-2 w-4 h-4 bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"}>
                        <CrossCircleFilledIcon color="slate" onClick={() => onDelete(id)} />
                    </div>
                )}
            </div>
        </div>
    );
}
