import { flexRender, Table } from "@tanstack/react-table";
import classNames from "classnames";
import { FiltersSortDropdown } from "./FiltersSortDropdown";
import { Column } from "./TableTools";

export function Head<T extends object, V = unknown>({
    table,
    columns,
    storageKey,
    onFiltersLazyLoad
}: {
    table: Table<T>;
    columns: Column<T>[];
    storageKey?: string;
    onFiltersLazyLoad?: (columnKey: V) => Promise<unknown[]>;
}): JSX.Element {
    return (
        <div className="sticky top-0 z-10">
            {table.getHeaderGroups().map(headerGroup => (
                <div key={headerGroup.id} className="relative flex w-fit divide-x divide-white">
                    {headerGroup.headers.map((header, index) => {
                        const column = columns.find(col => [col.accessorKey, col.id, col.header].includes(header.column.id));
                        return (
                            <div key={header.id} className="bg-grey-extra-light text-left text-primary" style={{ width: header.getSize() }}>
                                {header.isPlaceholder ? null : (
                                    <div className="relative flex h-full items-center px-3 py-1 font-semibold text-slate-dark">
                                        <div className="truncate" style={{ width: header.getSize() }}>
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </div>
                                        <div className="absolute right-3">
                                            <FiltersSortDropdown<T, V>
                                                column={{
                                                    ...header.column,
                                                    filters: column?.filters
                                                }}
                                                storageKey={storageKey}
                                                onFiltersLazyLoad={onFiltersLazyLoad}
                                            />
                                        </div>
                                        {header.column.getCanResize() && (
                                            <div
                                                className={classNames("absolute right-0 top-0 h-full w-4 cursor-col-resize select-none opacity-0", {
                                                    "-mr-2": index < headerGroup.headers.length - 1
                                                })}
                                                onMouseDown={header.getResizeHandler()}
                                                onTouchStart={header.getResizeHandler()}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}
