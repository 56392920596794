import {
    Account,
    ACCOUNT_APP_COLLECTION,
    ACCOUNT_COLLECTION,
    ACCOUNT_INVITATION_COLLECTION,
    ACCOUNT_PARTNER_INVITATIONS_COLLECTION,
    ACCOUNT_PARTNERS_COLLECTION,
    AccountInvitation,
    AccountRights,
    App,
    InvitationStatus
} from "@vaultinum/vaultinum-api";
import {
    AppCode,
    collection,
    DataEventSource,
    doc,
    DocumentSnapshot,
    doPost,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where
} from "@vaultinum/vaultinum-sdk";
import { doDelete } from "./apiService";
import { EvidencyApp } from "@vaultinum/evidency-api";

// accounts

const convertDocToAccount = (doc: DocumentSnapshot): Account =>
    ({
        ...doc.data(),
        id: doc.id,
        creationDate: doc.data()?.creationDate?.toDate()
    }) as Account;

const accounts = new DataEventSource<Account[]>(onUpdate =>
    onSnapshot(collection(getFirestore(), ACCOUNT_COLLECTION), querySnapshot => {
        const accounts = querySnapshot.docs.map(convertDocToAccount).filter(account => !!account.creationDate);
        onUpdate(accounts);
    })
);
accounts.cacheClearDelay = 60 * 60 * 1000; // 1 hour

export function getIgnoredAccountIds(onUpdate: (accountIds: string[]) => void): () => void {
    return onSnapshot(query(collection(getFirestore(), ACCOUNT_COLLECTION), where("tags", "array-contains", Account.Tag.IGNORED)), querySnapshot =>
        onUpdate(querySnapshot.docs.map(doc => doc.id))
    );
}

export function getAllAccounts(): Promise<Account[]>;
export function getAllAccounts(onUpdate: (account: Account[]) => void): () => void;
export function getAllAccounts(onUpdate?: (account: Account[]) => void): Promise<Account[]> | (() => void) {
    if (onUpdate) {
        return accounts.addListener(onUpdate);
    }
    return getDocs(collection(getFirestore(), ACCOUNT_COLLECTION)).then(queryDocs => queryDocs.docs.map(convertDocToAccount));
}

export function setAccountTags(account: Account, tags: Account.Tag[]): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION), account.id), { tags });
}

export function deleteAccount(accountId: string) {
    return doDelete<void>(`account/${accountId}`);
}

// accounts > apps

export function setAppSetting(
    account: Account,
    appCode: App.Code,
    key: string,
    value:
        | number
        | App.PaymentMethod
        | App.PaymentStatus
        | EvidencyApp.Timestamping.Plan
        | App.Deposit.Plan
        | EvidencyApp.Sealing.Plan
        | EvidencyApp.Archiving.Plan
) {
    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, account.id, ACCOUNT_APP_COLLECTION), appCode), { [key]: value });
}

export async function addDepositAppToAccount(account: Account, depositPlan: App.Deposit.Plan): Promise<void> {
    const depositApp: Omit<Account.AppSettings.Deposit, "code"> = {
        plan: depositPlan
    };
    return setDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, account.id, ACCOUNT_APP_COLLECTION), AppCode.DEPOSIT), depositApp);
}

export function getDepositAccountAppSettings(accountId: string): Promise<Account.AppSettings>;
export function getDepositAccountAppSettings(accountId: string, onUpdate: (depositApp: Account.AppSettings) => void): () => void;

export function getDepositAccountAppSettings(
    accountId: string,
    onUpdate?: (depositApp: Account.AppSettings) => void
): Promise<Account.AppSettings> | (() => void) {
    if (onUpdate) {
        return onSnapshot(doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_APP_COLLECTION), AppCode.DEPOSIT), doc =>
            onUpdate(doc.data() as Account.AppSettings)
        );
    }
    return getDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_APP_COLLECTION), AppCode.DEPOSIT)).then(
        doc => doc.data() as Account.AppSettings
    );
}

// accounts > invitations

const convertDocToAccountInvitation = (doc: DocumentSnapshot): AccountInvitation => ({
    ...(doc.data() as Omit<AccountInvitation, "id" | "sentDate">),
    id: doc.id,
    sentDate: doc.data()?.sentDate.toDate()
});

export function getAccountInvitations(accountId: string, onUpdate: (accountInvitations: AccountInvitation[]) => void): () => void {
    return onSnapshot(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_INVITATION_COLLECTION), querySnapshot =>
        onUpdate(querySnapshot.docs.map(convertDocToAccountInvitation))
    );
}

export function getAccountInvitationsByStatuses(
    accountId: string,
    statuses: InvitationStatus[],
    onUpdate: (accountInvitations: AccountInvitation[]) => void
): () => void {
    return onSnapshot(
        query(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_INVITATION_COLLECTION), where("status", "in", statuses)),
        querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToAccountInvitation))
    );
}

export function sendAccountInvitation(accountId: string, email: string, rights: AccountRights[]): Promise<void> {
    return doPost("account/invite", { email, accountId, rights }, process.env.REACT_APP_API_HOST);
}

// accounts > partners

const convertDocToPartner = (doc: DocumentSnapshot): Account.Partner => {
    const data = doc.data() as Omit<Account.Partner, "creationDate">;
    return {
        ...data,
        creationDate: doc.data()?.creationDate?.toDate()
    };
};

export function getPartners(accountId: string | undefined, onUpdate: (partners: Account.Partner[] | undefined) => void): () => void {
    if (!accountId) {
        onUpdate(undefined);
        return () => {};
    }
    return onSnapshot(query(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_PARTNERS_COLLECTION)), querySnapshot => {
        const partners = querySnapshot.docs.map(convertDocToPartner);
        onUpdate(partners);
    });
}

// accounts > partnerInvitations

const convertDocToPartnerInvitation = (doc: DocumentSnapshot): Account.PartnerInvitation => {
    const data = doc.data() as Omit<Account.PartnerInvitation, "sentDate">;
    return {
        ...data,
        sentDate: doc.data()?.sentDate.toDate()
    };
};

export function getPartnerInvitations(
    accountId: string | undefined,
    onUpdate: (partnerInvications: Account.PartnerInvitation[] | undefined) => void
): () => void {
    if (!accountId) {
        onUpdate(undefined);
        return () => {};
    }
    return onSnapshot(
        query(
            collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_PARTNER_INVITATIONS_COLLECTION),
            where("status", "!=", InvitationStatus.ACCEPTED)
        ),
        querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToPartnerInvitation))
    );
}
