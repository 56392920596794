import { Note } from "@vaultinum/vaultinum-api";
import { ReactNode, useState } from "react";
import { Drawer, Form, SurveyIcon, openNotificationWithIcon, useForm, useLang, useRequiredString, yup } from "../../../../common";
import NoteText from "./NoteText";

export default function NoteDrawer({
    onClose,
    onDelete,
    onSave,
    isVisible,
    notes,
    isReviewer,
    header,
    accountName
}: {
    onClose: () => void;
    onDelete: (noteId: string) => Promise<void>;
    onSave: (text: string, visibility: Note.Visibility, noteId?: string) => void | Promise<void>;
    isVisible: boolean;
    notes?: (Note & { userName: string })[];
    isReviewer?: boolean;
    header?: ReactNode;
    accountName?: string;
}): JSX.Element {
    const clientNote = notes?.find(note => note.visibility === Note.Visibility.PUBLIC);
    const staffNote = notes?.find(note => note.visibility === Note.Visibility.REVIEWERS);
    const editableNote = isReviewer ? staffNote : clientNote;
    const readOnlyNote = isReviewer ? clientNote : staffNote;
    const [working, setWorking] = useState(false);
    const noteVisibility = isReviewer ? editableNote?.visibility || Note.Visibility.REVIEWERS : Note.Visibility.PUBLIC;
    const lang = useLang();
    const schema = yup.object({
        noteText: useRequiredString()
    });
    const form = useForm<{
        noteText: string;
    }>({
        schema,
        defaultValues: {
            noteText: editableNote?.text || ""
        }
    });

    const onSubmit = async ({ noteText }: { noteText: string }) => {
        try {
            setWorking(true);
            await onSave(noteText, noteVisibility, editableNote?.id);
            openNotificationWithIcon({ type: "success", description: lang.shared.saveSuccessMessage });
        } catch {
            openNotificationWithIcon({ type: "error", description: lang.shared.saveErrorMessage });
        } finally {
            setWorking(false);
        }
    };

    const handleDelete = async () => {
        if (editableNote?.id) {
            try {
                setWorking(true);
                await onDelete(editableNote.id);
                openNotificationWithIcon({ type: "success", description: lang.shared.saveSuccessMessage });
                form.setValue("noteText", "");
            } catch {
                openNotificationWithIcon({ type: "error", description: lang.shared.saveErrorMessage });
            } finally {
                setWorking(false);
            }
        }
    };

    return (
        <Drawer
            header={{
                title: lang.accountInformation.notes.title,
                Icon: SurveyIcon
            }}
            children={
                <div className="w-full">
                    <Form onSubmit={form.handleSubmit(onSubmit)}>
                        {readOnlyNote && (
                            <NoteText
                                working={working}
                                note={readOnlyNote}
                                header={header}
                                visibleBy={[
                                    ...(isReviewer ? [lang.accountInformation.notes.reviewers] : []),
                                    ...(accountName ? [lang.accountInformation.notes.membersOf(accountName)] : [])
                                ].join(" & ")}
                            />
                        )}
                        <NoteText
                            note={editableNote}
                            header={!readOnlyNote ? header : undefined}
                            editable
                            visibleBy={isReviewer ? lang.accountInformation.notes.reviewers : accountName}
                            working={working}
                            form={form}
                            onSubmit={() => onSubmit({ noteText: form.watch().noteText })}
                            handleDelete={handleDelete}
                        />
                    </Form>
                </div>
            }
            isVisible={isVisible}
            onClose={onClose}
            size="lg"
        />
    );
}
