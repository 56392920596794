import { Stripe, StripeCardElement, StripeIbanElement } from "@stripe/stripe-js";
import { App } from "@vaultinum/vaultinum-api";
import { Stripe as StripeApi } from "stripe";
import { doDelete, doGet } from "../../../common";
import { createSetupIntent } from "./stripeService";

export enum CardBrandEnum {
    VISA = "visa",
    MASTERCARD = "mastercard"
}

export type PaymentService = {
    listPaymentMethods(accountId: string): Promise<StripeApi.PaymentMethod[]>;
    detachPaymentMethod(accountId: string, paymentMethodId: string): Promise<void>;
    addPaymentMethod(
        accountId: string,
        stripe: Stripe | null,
        paymentMethodType: App.PaymentMethod.CARD | App.PaymentMethod.SEPA_DEBIT,
        cardElement: StripeCardElement | null,
        ibanElement: StripeIbanElement | null,
        cardName?: string,
        accountHolderName?: string,
        accountHolderEmail?: string
    ): Promise<void>;
};

export class StripePaymentService implements PaymentService {
    listPaymentMethods(accountId: string): Promise<StripeApi.PaymentMethod[]> {
        return doGet(`account/${accountId}/paymentMethods`);
    }

    detachPaymentMethod(accountId: string, paymentMethodId: string): Promise<void> {
        return doDelete(`account/${accountId}/paymentMethods/${paymentMethodId}`);
    }

    async addPaymentMethod(
        accountId: string,
        stripe: Stripe | null,
        paymentMethodType: App.PaymentMethod.CARD | App.PaymentMethod.SEPA_DEBIT,
        cardElement: StripeCardElement | null,
        ibanElement: StripeIbanElement | null,
        cardName?: string | undefined,
        accountHolderName?: string | undefined,
        accountHolderEmail?: string | undefined
    ): Promise<void> {
        if (!stripe) {
            throw new Error("Missing stripe instance");
        }
        switch (paymentMethodType) {
            case App.PaymentMethod.CARD: {
                const { client_secret: clientSecretCard } = await createSetupIntent(accountId, paymentMethodType);
                if (clientSecretCard && cardElement) {
                    const { error } = await stripe.confirmCardSetup(clientSecretCard, {
                        payment_method: {
                            card: cardElement,
                            billing_details: {
                                name: cardName
                            }
                        }
                    });
                    if (error) {
                        throw new Error(error.message);
                    }
                }
                break;
            }
            case App.PaymentMethod.SEPA_DEBIT: {
                const { client_secret: clientSecretSepaDebit } = await createSetupIntent(accountId, paymentMethodType);
                if (clientSecretSepaDebit && ibanElement && accountHolderName && accountHolderEmail) {
                    const { error } = await stripe.confirmSepaDebitSetup(clientSecretSepaDebit, {
                        payment_method: {
                            sepa_debit: ibanElement,
                            billing_details: {
                                name: accountHolderName,
                                email: accountHolderEmail
                            }
                        }
                    });
                    if (error) {
                        throw new Error(error.message);
                    }
                }
                break;
            }
            default: {
                throw new Error("No valid payment method selected");
            }
        }
    }
}
