import { Row, Table as TanstackTable } from "@tanstack/react-table";
import { ChangeEvent } from "react";
import { Input } from "../Input";
import { TableRow } from "./TableTools";

function selectChildren<T>(isChecked: boolean, row: Row<TableRow<T>>, currentSelection: Record<string, boolean>): Record<string, boolean> {
    let newSelection = { ...currentSelection };
    newSelection[row.id] = isChecked;
    row.subRows.forEach(subRow => {
        if (subRow.original.disabled) {
            return;
        }
        newSelection = selectChildren(isChecked, subRow, newSelection);
    });
    return newSelection;
}

function refreshParentSelection<T>(
    row: Row<TableRow<T>>,
    table: TanstackTable<TableRow<T>>,
    currentSelection: Record<string, boolean>
): Record<string, boolean> {
    if (!row.parentId) {
        return currentSelection;
    }
    const parentRow = table.getRow(row.parentId);
    if (!parentRow) {
        return currentSelection;
    }
    const parentSelected = parentRow.subRows.every(subRow => currentSelection[subRow.id]);
    return { [parentRow.id]: parentSelected, ...refreshParentSelection(parentRow, table, currentSelection) };
}

export function SelectRowCheckbox<T>({ row, table }: { row: Row<TableRow<T>>; table: TanstackTable<TableRow<T>> }): JSX.Element {
    const meta: { isTree?: boolean } | undefined = table.options.meta;
    const disabled = row.original.disabled || !!(row.parentId && table.getRow(row.parentId).original.disabled);

    function onCheck(event: ChangeEvent<HTMLInputElement>): void {
        if (disabled) {
            return;
        }
        const isChecked = event.target.checked;
        const currentSelection = table.getState().rowSelection;
        let newSelection = selectChildren(isChecked, row, currentSelection);
        if (meta?.isTree) {
            newSelection = refreshParentSelection(row, table, newSelection);
        }
        table.setRowSelection(() => newSelection);
    }

    return (
        <div className="flex h-full items-center justify-center">
            <Input.Checkbox
                isIndeterminate={row.getIsSomeSelected() && !row.getIsAllSubRowsSelected()}
                onChange={onCheck}
                checked={row.getIsSelected() || row.getIsAllSubRowsSelected()}
                disabled={disabled}
            />
        </div>
    );
}

export function SelectAllHeader<T>({ table }: { table: TanstackTable<TableRow<T>> }): JSX.Element {
    const isAllRowsSelected = table.getRowModel().rows.every(row => row.getIsSelected() || row.original.disabled);

    function selectAll(event: ChangeEvent<HTMLInputElement>): void {
        const isChecked = event.target.checked;

        const newSelection = table.getRowModel().rows.reduce((acc, row) => {
            if (!row.original.disabled) {
                return { ...acc, ...selectChildren(isChecked, row, acc) };
            }
            return acc;
        }, {});

        table.setRowSelection(() => newSelection);
    }

    return (
        <div className="flex w-full justify-center">
            <Input.Checkbox isIndeterminate={!isAllRowsSelected && table.getIsSomeRowsSelected()} onChange={selectAll} checked={isAllRowsSelected} />
        </div>
    );
}
