import { Report } from "@vaultinum/vaultinum-legal-proof-api";
import { doGet, doPost, getAuth } from "@vaultinum/vaultinum-sdk";
import fileDownload from "js-file-download";
import { doGetFile } from "./apiService";

export type LPRMetadata = Report.Metadata & { userId: string; email: string };
function getLPRAdminAccessKey(): string {
    if (!process.env.REACT_APP_LPR_ADMIN_ACCESS_KEY) {
        throw new Error("REACT_APP_LPR_ADMIN_ACCESS_KEY is not set");
    }
    return process.env.REACT_APP_LPR_ADMIN_ACCESS_KEY;
}

function getRequesterEmail(): string {
    const email = getAuth().currentUser?.email;
    if (!email) {
        throw new Error("User is not authenticated");
    }
    return email;
}

export async function getAllReports(onUpdate: (report: LPRMetadata[]) => void): Promise<void> {
    const reports = await doGet<LPRMetadata[]>("admin/report", undefined, process.env.REACT_APP_LPR_URL, {
        headers: {
            "Content-Type": "application/json",
            "Access-Key": getLPRAdminAccessKey(),
            "Requester-Email": getRequesterEmail()
        }
    });
    onUpdate(reports);
}

export async function downloadReport(report: LPRMetadata): Promise<void> {
    const blob = await doGetFile(`admin/report/${report.userId}/${report.id}`, "application/zip", process.env.REACT_APP_LPR_URL, {
        "Access-Key": getLPRAdminAccessKey(),
        "Requester-Email": getRequesterEmail()
    });
    fileDownload(blob, `${report.reportTitle}.zip`);
}

export async function addLPRUser(email: string): Promise<string> {
    const response = await doPost<{ accessKey: string }>("auth/register", { email }, process.env.REACT_APP_LPR_URL, {
        headers: {
            "Content-Type": "application/json",
            "Access-Key": getLPRAdminAccessKey(),
            "Requester-Email": getRequesterEmail()
        }
    });
    return response.accessKey;
}

export async function resetUserAccessKey(email: string): Promise<string> {
    const response = await doPost<{ newAccessKey: string }>(`auth/reset/${email}`, undefined, process.env.REACT_APP_LPR_URL, {
        headers: {
            "Content-Type": "application/json",
            "Access-Key": getLPRAdminAccessKey(),
            "Requester-Email": getRequesterEmail()
        }
    });
    return response.newAccessKey;
}
