import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { CrossIcon } from "../Icons";

export type AlertProps = {
    icon: JSX.Element;
    className?: string;
    "data-id"?: string;
    title?: string;
    message?: React.ReactNode;
    isClosable?: boolean;
};

export function BaseAlert({ icon, "data-id": dataId, className, title, message, isClosable }: AlertProps): JSX.Element {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    const variants = {
        visible: { opacity: 1, display: "flex" },
        hidden: { opacity: 0, transition: { duration: 0.3 }, transitionEnd: { display: "none" } }
    };

    return (
        <AnimatePresence>
            <motion.div
                data-id={dataId}
                className={classNames("items-center gap-4 rounded border p-4", className)}
                variants={variants}
                initial="visible"
                animate={isVisible ? "visible" : "hidden"}
            >
                {icon}
                <div className="flex-1 space-x-1 font-bold">
                    {title && <span>{title}</span>}
                    {message && <span>{message}</span>}
                </div>
                {isClosable && (
                    <div data-id={`${dataId}-close`} className="cursor-pointer" onClick={() => setIsVisible(false)}>
                        <CrossIcon />
                    </div>
                )}
            </motion.div>
        </AnimatePresence>
    );
}
