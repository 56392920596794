import { addQueryParamsToUrl, SHARED_URL } from "../../services";

export default function HardRedirect({ path }: { path: string }) {
    const queryParams: Record<string, string> = {};
    for (const [key, value] of new URLSearchParams(window.location.search).entries()) {
        queryParams[key] = value;
    }
    const redirectTo = addQueryParamsToUrl(window.location.pathname, queryParams);
    window.location.assign(path === SHARED_URL.app.login ? addQueryParamsToUrl(path, { redirectTo }) : path);
    return null;
}
