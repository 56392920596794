import classNames from "classnames";
import { ReactNode } from "react";
import { Size } from "../../referentials";
import { Tooltip } from "../Tooltip/Tooltip";

export type KeyFactBaseProps = {
    title?: string;
    value?: null | ReactNode;
    prefix?: ReactNode;
    tooltipText?: string;
    grow?: boolean;
    size?: Extract<Size, "xs" | "sm" | "md">;
    onClick?: () => void;
};

export default function KeyFact({ title, value, prefix, tooltipText, grow, size, onClick }: KeyFactBaseProps): JSX.Element {
    const titleClasses = classNames("font-light text-slate-dark leading-5 truncate", { underline: !!onClick });
    const content = (
        <div className={classNames("flex items-center gap-2", { "flex-1": grow })}>
            {prefix}
            <div>
                {title && (
                    <div className="flex items-center gap-1">
                        {onClick ? <button className={titleClasses} children={title} onClick={onClick} /> : <div className={titleClasses} children={title} />}
                    </div>
                )}
                {value !== null && (
                    <div
                        className={classNames("font-bold text-slate-primary text-left", { "text-lg leading-5": size === "sm", "text-3xl": size === "md" })}
                        children={value ?? "--"}
                    />
                )}
            </div>
        </div>
    );
    if (tooltipText) {
        return <Tooltip children={content} title={tooltipText} />;
    }
    return content;
}
