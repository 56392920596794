import trackingService, { BaseTrackingEvents } from "../analytics/trackingService";
import { doPost } from "./apiService";
import { browserLocalPersistence, browserSessionPersistence, getAuth, Persistence, signInWithEmailAndPassword, UserCredential } from "./firebaseService";

const BASE_PATH = "auth";

export function register(email: string, password: string, domainId?: string): Promise<string> {
    return doPost<string>(`${BASE_PATH}/register`, { email, password, ...(domainId ? { domainId } : {}) });
}

export async function login(email: string, password: string, rememberMe = false): Promise<UserCredential> {
    const persistenceStrategy: Persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
    await getAuth().setPersistence(persistenceStrategy);
    let userCredential;
    try {
        userCredential = await signInWithEmailAndPassword(getAuth(), email, password);
    } catch {
        throw new Error("Incorrect email or password.");
    }
    trackingService.startTrackingUser(userCredential.user?.uid);
    trackingService.sendEvent(BaseTrackingEvents.UserLogin, { "remember me": rememberMe });
    return userCredential;
}

export async function logout(): Promise<void> {
    await getAuth().signOut();
    localStorage.clear();
    trackingService.stopTrackingUser();
}

export function forgotPassword(email: string, domainId?: string): Promise<void> {
    return doPost<void>(`${BASE_PATH}/forgot-password`, { email, ...(domainId ? { domainId } : {}) });
}

export function resetPassword(email: string, newPassword: string, oobCode: string): Promise<void> {
    return doPost<void>(`${BASE_PATH}/reset-password`, {
        email,
        newPassword,
        oobCode
    });
}

export function verifyEmail(email: string, domainId?: string): Promise<void> {
    return doPost<void>(`${BASE_PATH}/verify-email`, { email, ...(domainId ? { domainId } : {}) });
}
