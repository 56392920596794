import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import {
    BaseLang,
    Button,
    Buttons,
    Controller,
    CopyIcon,
    Form,
    Input,
    message,
    Modal,
    useForm,
    useLang,
    useRequiredString,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { addLPRUser, resetUserAccessKey } from "../../../services";

export default function LPRUserModal({ reset, isOpen, doClose }: { reset: boolean; isOpen: boolean; doClose: () => void }): JSX.Element {
    const lang = useLang<BaseLang>();
    const [working, setWorking] = useState(false);
    const [accessKey, setAccessKey] = useState<string>("");

    const schema = yup.object({
        email: useRequiredString().trim().matches(EMAIL_REGEX, "Invalid email address")
    });

    const {
        control,
        watch,
        formState,
        reset: resetFields
    } = useForm<{ email: string }>({
        schema,
        mode: "onChange"
    });

    async function doAddUser() {
        const { email } = watch();
        setWorking(true);
        try {
            setAccessKey(reset ? await resetUserAccessKey(email) : await addLPRUser(email));
        } catch (err) {
            await message.error(`Failed to ${reset ? "reset" : "add"} user`);
        } finally {
            setWorking(false);
        }
    }

    function onClose() {
        doClose();
        setAccessKey("");
        resetFields();
    }

    async function onCopy() {
        await navigator.clipboard.writeText(accessKey);
        await message.success("Access key copied to clipboard");
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={`${reset ? "Reset" : "Add"} user`} okText="Close" isDisabled={working} lang={lang}>
            <Form>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            label="Email"
                            placeholder="Email..."
                            disabled={working || !!accessKey}
                            errorMessage={formState.errors.email?.message}
                            required
                        />
                    )}
                />
                <div className="flex items-center gap-2 w-full place-content-end">
                    {accessKey && (
                        <>
                            <span>{accessKey}</span>
                            <Buttons.Icon icon={CopyIcon} onClick={onCopy} isLoading={false} type="default" />
                        </>
                    )}
                    {!accessKey && (
                        <Button
                            children={reset ? "Reset Access Key" : "Generate Access Key"}
                            onClick={doAddUser}
                            isLoading={working}
                            isDisabled={!formState.isValid}
                        />
                    )}
                </div>
            </Form>
        </Modal>
    );
}
