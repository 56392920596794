import { Account, Cart } from "@vaultinum/vaultinum-api";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from "react";
import { PaymentService, PriceInfo, StripePaymentService } from "../services";
import { PaymentMethodFormContextInterface, PaymentMethodFormProvider, usePaymentMethodFormContext } from "./PaymentMethodFormContext";

type PaymentContextPropsInterface = {
    account: Account;
    cart: Cart;
    setCart: Dispatch<SetStateAction<Cart>>;
    priceInfo?: PriceInfo;
    paymentService?: PaymentService;
};

type PaymentContextInterface = {
    isProceeding: boolean;
    setIsProceeding: Dispatch<SetStateAction<boolean>>;
    isBillingInformationComplete: boolean;
    setIsBillingInformationComplete: Dispatch<SetStateAction<boolean>>;
    paymentService: PaymentService;
} & PaymentContextPropsInterface &
    PaymentMethodFormContextInterface;

const Context = createContext<PaymentContextInterface>({} as PaymentContextInterface);

export function PaymentProvider(props: { children: ReactNode; value: PaymentContextPropsInterface }) {
    return (
        <PaymentMethodFormProvider>
            <Content {...props} />
        </PaymentMethodFormProvider>
    );
}

function Content({ children, value }: { children: ReactNode; value: PaymentContextPropsInterface }) {
    const paymentMethodFormProps = usePaymentMethodFormContext();
    const [isProceeding, setIsProceeding] = useState(false);
    const [isBillingInformationComplete, setIsBillingInformationComplete] = useState(false);

    const exposed: PaymentContextInterface = useMemo(
        () => ({
            ...paymentMethodFormProps,
            isProceeding,
            setIsProceeding,
            isBillingInformationComplete,
            setIsBillingInformationComplete,
            paymentService: value.paymentService ?? new StripePaymentService(),
            ...value
        }),
        [paymentMethodFormProps, isProceeding, setIsProceeding, isBillingInformationComplete, setIsBillingInformationComplete, value]
    );

    return <Context.Provider value={exposed} children={children} />;
}

export function usePaymentContext(): PaymentContextInterface {
    return useContext(Context);
}
