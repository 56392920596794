import { DOMAIN_COLLECTION, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { DocumentData, DocumentSnapshot, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { UploadResult, getApp, getDownloadURLFromStorage, getStorage, ref, uploadBytes } from "./firebaseService";

export function convertDocWhiteLabelDomain(document: DocumentSnapshot<DocumentData>): WhiteLabelDomain {
    return {
        ...(document.data() as Omit<WhiteLabelDomain, "id" | "creationDate">),
        id: document.id,
        creationDate: document.data()?.creationDate.toDate()
    };
}

export function removeWhiteLabelDomain(domainId: string): Promise<void> {
    return deleteDoc(doc(collection(getFirestore(), DOMAIN_COLLECTION), domainId));
}

export async function getWhiteLabelDomain(domainId: string): Promise<WhiteLabelDomain | null> {
    const domain = await getDoc(doc(collection(getFirestore(), DOMAIN_COLLECTION), domainId));
    if (domain.exists()) {
        return convertDocWhiteLabelDomain(domain);
    }
    return null;
}

export async function getWhiteLabelDomainByFQDN(fqdn: string, includeInactives = false): Promise<WhiteLabelDomain | null> {
    const constraints = [where("fqdn", "==", fqdn), ...(includeInactives ? [] : [where("isActive", "==", true)])];
    const domains = await getDocs(query(collection(getFirestore(), DOMAIN_COLLECTION), ...constraints));
    if (domains.size !== 1) {
        return null;
    }
    return convertDocWhiteLabelDomain(domains.docs[0]);
}

function getDomainLogoPath(domainId: string): string {
    return `domains/${domainId}/logo`;
}

export async function getLogoByDomainId(domainId: string): Promise<string> {
    return getDownloadURLFromStorage(getDomainLogoPath(domainId));
}

export function uploadDomainLogo(domainId: string, file: File): Promise<UploadResult> {
    const storage = getStorage(getApp());
    const logoRef = ref(storage, getDomainLogoPath(domainId));
    return uploadBytes(logoRef, file);
}
