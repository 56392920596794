import { ArchiveIcon, BarCircleIcon, CheckCircleIcon, ClockCircleIcon, CrossCircleIcon, InfoIcon, Tag, TagInterface, WarningIcon } from "../..";

type IconTagInterface = Omit<TagInterface, "icon" | "type">;

export namespace IconTag {
    export function Info(props: IconTagInterface) {
        return <Tag type="info" icon={InfoIcon} {...props} />;
    }

    export function Success(props: IconTagInterface) {
        return <Tag type="success" icon={CheckCircleIcon} {...props} />;
    }

    export function Warning(props: IconTagInterface) {
        return <Tag type="warning" icon={WarningIcon} {...props} />;
    }

    export function Danger(props: IconTagInterface) {
        return <Tag type="danger" icon={CrossCircleIcon} {...props} />;
    }

    // Variants

    export function Pending(props: IconTagInterface) {
        return <Tag type="warning" icon={ClockCircleIcon} {...props} />;
    }

    export function Cancel(props: IconTagInterface) {
        return <Tag type="danger" icon={BarCircleIcon} {...props} />;
    }

    export function Archived(props: IconTagInterface) {
        return <Tag type="danger" icon={ArchiveIcon} {...props} />;
    }
}
