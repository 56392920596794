import { AxiosResponse } from "axios";
import { calculateChecksum } from "../../helpers";
import { DEFAULT_UPLOADER_CHUNK_SIZE } from "../helpers";

export async function uploadFile(
    file: File,
    buildFormData: (file: File, counter: number, chunk: Blob, checksum: string, totalCount: number) => FormData,
    uploadChunkFile: (formData: FormData) => Promise<AxiosResponse>,
    onUploadData: (fileName: string, progress: number) => void,
    onError: (fileName: string, errorMessage: string) => void
): Promise<string | void> {
    if (!file.size) {
        throw new Error("File is empty");
    }
    const totalCount =
        file.size % DEFAULT_UPLOADER_CHUNK_SIZE === 0 ? file.size / DEFAULT_UPLOADER_CHUNK_SIZE : Math.floor(file.size / DEFAULT_UPLOADER_CHUNK_SIZE) + 1;
    const checksum = await calculateChecksum(file);
    let startOfChunk = 0;
    let endOfChunk = DEFAULT_UPLOADER_CHUNK_SIZE;
    try {
        for (let i = 0; i <= totalCount - 1; i += 1) {
            const chunk = file.slice(startOfChunk, endOfChunk);
            const formData = buildFormData(file, i, chunk, checksum, totalCount);
            const response = await uploadChunkFile(formData);
            if (response.status !== 200) {
                throw new Error("error while uploading");
            }
            const progress = ((i + 1) / totalCount) * 100;
            onUploadData(file.name, progress);
            startOfChunk = endOfChunk;
            endOfChunk += DEFAULT_UPLOADER_CHUNK_SIZE;
            if (response.data.fileId) {
                return response.data.fileId;
            }
        }
    } catch (error) {
        onError(file.name, error.message);
        throw new Error(error);
    }
    return Promise.resolve();
}
