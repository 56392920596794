import { Survey } from "@vaultinum/vaultinum-api";
import { DataRoom } from "@vaultinum/vaultinum-kys-api";
import { BaseLang, Controller, Form, Input, message, Modal, Select, useForm, useLang, useRequiredString, yup } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { ScopeSelector } from "../../../components";
import { getDataRoomFolders, newSurvey, updateSurvey } from "../../../services";

type SurveyForm = Pick<Survey, "key" | "name" | "scope" | "defaultFolderPath">;

export default function AddOrEditSurveyModal({ isOpen, onClose, survey }: { isOpen: boolean; onClose: () => void; survey: Survey | null }): JSX.Element {
    const lang = useLang<BaseLang>();
    const schema = yup.object({
        key: useRequiredString().min(2).max(8),
        name: useRequiredString().trim()
    });

    const [dataRoomFolders, setDataRoomFolders] = useState<Omit<DataRoom.FolderMetaInfo, "id">[] | null>();
    const [working, setWorking] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid },
        setValue
    } = useForm<Pick<Survey, "key" | "name" | "scope" | "defaultFolderPath">>({
        schema
    });

    useEffect(() => getDataRoomFolders(setDataRoomFolders), []);

    useEffect(() => {
        if (isOpen) {
            reset(
                survey
                    ? {
                          key: survey.key,
                          name: survey.name,
                          scope: survey.scope,
                          defaultFolderPath: survey.defaultFolderPath
                      }
                    : {
                          key: "",
                          name: "",
                          scope: undefined,
                          defaultFolderPath: ""
                      }
            );
        }
    }, [isOpen, survey, reset]);

    async function createNewSurvey(data: SurveyForm): Promise<void> {
        setWorking(true);
        try {
            const { key, ...props } = data;
            if (survey) {
                await updateSurvey(survey.key, props);
            } else {
                await newSurvey(key.toUpperCase(), props);
            }
            onClose();
        } catch (e) {
            await message.error(e.message);
        } finally {
            setWorking(false);
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={handleSubmit(createNewSurvey)}
            title={survey ? "Edit Survey" : "New Survey"}
            okText={survey ? "Update" : "Create"}
            isLoading={working}
            isDisabled={!isValid}
            lang={lang}
        >
            <Form>
                <Controller
                    name="key"
                    control={control}
                    render={({ field }) => (
                        <Input.Text {...field} label="Survey Key" placeholder="Survey key..." disabled={!!survey} errorMessage={errors.key?.message} required />
                    )}
                />
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <Input.Text
                            {...field}
                            label="Name used internally for the survey"
                            placeholder="Survey name..."
                            errorMessage={errors.name?.message}
                            required
                        />
                    )}
                />
                <Controller name="scope" control={control} render={({ field }) => <ScopeSelector {...field} />} />
                <Controller
                    name="defaultFolderPath"
                    control={control}
                    render={({ field }) => (
                        <Select.Basic
                            {...field}
                            label="Default Folder Path"
                            options={(dataRoomFolders ?? []).map(({ folderPath, lang }) => ({ value: folderPath, label: lang.en }))}
                            isClearable
                            onChange={value => setValue("defaultFolderPath", value ?? "")}
                        />
                    )}
                />
            </Form>
        </Modal>
    );
}
