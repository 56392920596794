import { AnalysedCodeRepository } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import { createContext } from "react";

type CodeAuditContextType = {
    report: CodeAnalysisReport | null | undefined;
    codeRepositories: AnalysedCodeRepository[];
};

export const CodeAuditContext = createContext<CodeAuditContextType>({} as CodeAuditContextType);
