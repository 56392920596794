import { LANG_EN, Promotion, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import {
    AppCode,
    BaseLang,
    Breadcrumb,
    BreadcrumbItems,
    Button,
    Column,
    RowCard,
    Spin,
    Table,
    formatDateTime,
    getWhiteLabelDomain,
    openNotificationWithIcon,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { pick } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { getPromotion } from "../../../services/promotionService";
import { ESCROW_DETAIL_PAGE, PROMOTIONS_PAGE } from "../../../services/routing.service";
import { formatDates, formatJoinLink, formatOffer } from "./PromotionTool";

type ItemIds = { [key in AppCode]?: string };

type PromotionDetailArray = {
    email: string;
    joinDate?: string;
    companyName?: string;
    itemsIds?: ItemIds;
};

function redirect(appCode: AppCode, navigate: NavigateFunction, id: string): void | null {
    if (appCode === AppCode.ESCROW) {
        return navigate(ESCROW_DETAIL_PAGE.replace(":escrowId", id));
    }
    return null;
}

function Actions({ itemsIds }: { itemsIds: ItemIds }): JSX.Element {
    const lang = useLang<BaseLang>();
    const navigate = useNavigate();
    const targetIds = pick(itemsIds, [AppCode.FULL_AUDIT, AppCode.DEPOSIT, AppCode.ESCROW]);
    return (
        <div className="flex flex-col gap-2">
            {Object.entries(targetIds).map(([appCode, id]) => {
                if (id) {
                    const typedAppCode = appCode as AppCode;
                    return (
                        <Button
                            key={id}
                            isLoading={false}
                            onClick={() => redirect(typedAppCode, navigate, id)}
                            children={`Go to ${lang.apps[typedAppCode].name}`}
                        />
                    );
                }
                return <></>;
            })}
        </div>
    );
}

function getColumns(): Column<PromotionDetailArray>[] {
    return [
        {
            header: "Email",
            accessorKey: "email"
        },
        {
            header: "Company Name",
            accessorKey: "companyName",
            cell: cell => cell.getValue<string>()
        },
        {
            header: "Join Date",
            accessorKey: "joinDate"
        },
        {
            header: "Actions",
            accessorKey: "itemsIds",
            cell: cell => {
                return <Actions itemsIds={cell.getValue<ItemIds>()} />;
            }
        }
    ];
}

function InformationRow({ name, value }: { name: string; value: ReactNode }): JSX.Element {
    return (
        <div className="flex gap-2">
            <div>{name}:</div>
            <div>{value}</div>
        </div>
    );
}

export default function PromotionPage(): JSX.Element {
    const [promotion, setPromotion] = useState<Promotion>();
    const [isWorking, setIsWorking] = useState(false);
    const [domain, setDomain] = useState<WhiteLabelDomain | null>();
    const { promotionId } = useParams<{
        promotionId: string;
    }>();

    useEffect(() => {
        void (async () => {
            if (promotionId) {
                setIsWorking(true);
                try {
                    const promotionData = await getPromotion(promotionId);
                    if (promotionData.whiteLabelDomainId) {
                        setDomain(await getWhiteLabelDomain(promotionData.whiteLabelDomainId));
                    }
                    setPromotion(promotionData);
                } catch (e) {
                    openNotificationWithIcon({ type: "error", message: "An error occured while loading promotion details" });
                } finally {
                    setIsWorking(false);
                }
            }
        })();
    }, [promotionId]);

    if (isWorking) {
        return <Spin />;
    }

    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "Promotions",
            href: PROMOTIONS_PAGE
        },
        {
            label: promotion?.name || "Promotion details"
        }
    ];

    return (
        <div className="flex flex-col gap-5">
            <Breadcrumb items={breadcrumbItems} />
            {promotion && (
                <>
                    <RowCard
                        title={{ text: promotion.name }}
                        children={
                            <div className="flex flex-col gap-2">
                                <InformationRow name="Status" value={formatDates(promotion)} />
                                <InformationRow name="Associated emails" value={Object.keys(promotion.emails).length} />
                                <InformationRow name="Offer" value={formatOffer(promotion.offer)} />
                                <InformationRow name="Link to join promotion" value={formatJoinLink(promotion, domain)} />
                            </div>
                        }
                    />
                    <Table
                        data={Object.keys(promotion.emails)
                            .sort((a, b) => a.localeCompare(b))
                            .map(hash => {
                                const email = promotion?.emails[hash];
                                return {
                                    email: hash,
                                    joinDate: email.joinDate ? formatDateTime(email.joinDate, LANG_EN) : "-",
                                    itemsIds: email.itemsIds,
                                    companyName: email.companyName || "-"
                                };
                            })}
                        columns={getColumns()}
                    />
                </>
            )}
        </div>
    );
}
