import { Grade, GradeColors } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { ElementType } from "react";
import { Color, ColorShade, Size } from "../../../referentials";
import KeyFact, { KeyFactBaseProps } from "../KeyFact";

type CommonBaseProps = Omit<KeyFactBaseProps, "indicator">;
export type KeyFactIconProps = CommonBaseProps & { icon: ElementType; iconColorShade?: ColorShade; iconColor?: Color; color?: Color; colorShade?: ColorShade };
export type KeyFactGradeProps = CommonBaseProps & { grade?: Grade };

const BASE_CLASS_NAME = "flex items-center justify-center rounded-lg";

function getIconSize(color?: Color, size?: Extract<Size, "xs" | "sm" | "md">): Extract<Size, "xs" | "sm" | "lg" | "xl"> {
    if (size === "xs") {
        return color ? "xs" : "sm";
    }
    if (size === "sm") {
        return color ? "sm" : "lg";
    }
    return "xl";
}

export namespace KeyFacts {
    export function WithIcon({ icon: Icon, color, colorShade, iconColor, iconColorShade, size, ...props }: KeyFactIconProps) {
        return (
            <KeyFact
                prefix={
                    <div
                        className={classNames(BASE_CLASS_NAME, {
                            [`bg-${color}-primary`]: !!color && !colorShade,
                            [`bg-${color}-${colorShade === "extraLight" ? "extra-light" : colorShade}`]: !!color && !!colorShade,
                            "h-20 w-20": size === "md",
                            "h-8 w-8": size === "sm",
                            "h-4 w-4": size === "xs"
                        })}
                    >
                        <Icon size={getIconSize(color, size)} color={iconColor ?? "slate"} shade={iconColorShade ?? "light"} />
                    </div>
                }
                size={size}
                {...props}
            />
        );
    }
    export function WithGrade({ grade, size, ...props }: KeyFactGradeProps) {
        return (
            <KeyFact
                prefix={
                    <div
                        className={classNames(BASE_CLASS_NAME, {
                            "bg-grey-light": !grade,
                            "h-20 w-20": size === "md",
                            "h-10 w-10": size === "sm",
                            "h-6 w-6": size === "xs"
                        })}
                        {...(grade && { style: { backgroundColor: GradeColors[grade] } })}
                    >
                        <h1
                            className={classNames("font-bold text-white font-arial", {
                                "text-5xl": size === "md",
                                "text-2xl": size === "sm",
                                "text-lg": size === "xs"
                            })}
                        >
                            {grade || "--"}
                        </h1>
                    </div>
                }
                size={size}
                {...props}
            />
        );
    }
}
