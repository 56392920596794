import classNames from "classnames";
import { motion } from "framer-motion";
import { ElementType, ReactNode, useEffect, useMemo, useRef } from "react";
import { AdaptiveLink, ChevronRightIcon, IconProps, ROTATE_OPEN_VARIANT, Separator, Tooltip } from "../../../design-system";
import { useBrandContext, useWhiteLabelContext } from "../../contexts";
import { useToggle, useWindowDimensions } from "../../hooks";
import DropdownMenu, { DropdownItem } from "./DropdownMenu";

type NavigationItemProps = {
    key: string;
    render: () => JSX.Element;
    className?: string;
};

export type MenuItem = {
    key: string;
    icon: ElementType<IconProps>;
    text: string;
    href?: string;
    onClick?: () => void;
};

function NavbarItem({
    children,
    isExpanded,
    isActive = false,
    onClick,
    isCenter,
    className
}: {
    children: ReactNode;
    isExpanded: boolean;
    isActive?: boolean;
    onClick?: () => void;
    isCenter?: boolean;
    className?: string;
}): JSX.Element {
    return (
        <div
            className={classNames("flex h-[4.5rem] cursor-pointer items-center space-x-4 text-white", className, {
                "bg-slate-light": isActive,
                " hover:bg-slate-dark": !isActive,
                "px-auto": !isExpanded,
                "justify-center": isCenter
            })}
            onClick={onClick}
            children={children}
        />
    );
}

const MIN_NAVBAR_SIZE = "72px";
const MAX_NAVBAR_SIZE = "225px";
const XL_BREAKPOINT = 1280;

function LeftNavbarItem({ icon: Icon, menuItem }: { icon: ElementType<IconProps>; menuItem?: MenuItem }): JSX.Element {
    return (
        <>
            <Icon size="md" color="white" />
            {menuItem && (
                <div className="flex flex-col truncate">
                    <span className="truncate text-sm font-bold">{menuItem.text}</span>
                </div>
            )}
        </>
    );
}

export default function LeftNavbar({
    dropdownItems,
    homeHref,
    onMenuItemClick,
    menuItems,
    isHomeLinkRouted,
    activeMenuItemKey,
    hideExpandButton,
    isMenuDefaultExpanded
}: {
    dropdownItems: DropdownItem[];
    homeHref: string;
    onMenuItemClick: (menuItem: MenuItem) => void;
    menuItems?: MenuItem[][];
    isHomeLinkRouted?: boolean;
    activeMenuItemKey?: string;
    hideExpandButton?: boolean;
    isMenuDefaultExpanded?: boolean;
}): JSX.Element {
    const { logoWhitePath } = useBrandContext();
    const { domainLogo } = useWhiteLabelContext();
    const [isExpanded, toggleExpanded, setIsExpanded] = useToggle(isMenuDefaultExpanded ?? localStorage.getItem("isExpanded") === "true");
    const { width } = useWindowDimensions();
    const widthRef = useRef(width);

    useEffect(() => {
        if (widthRef.current !== width && width < XL_BREAKPOINT) {
            setIsExpanded(false);
        }
    }, [width]);

    useEffect(() => {
        localStorage.setItem("isExpanded", String(isExpanded));
    }, [isExpanded]);

    const menuVariant = {
        open: { width: MAX_NAVBAR_SIZE },
        closed: { width: MIN_NAVBAR_SIZE }
    };

    const navigationItems: NavigationItemProps[] = useMemo(() => {
        return [
            {
                key: "logo",
                render: (): JSX.Element => (
                    <AdaptiveLink href={homeHref} routed={isHomeLinkRouted}>
                        <img src={domainLogo ?? logoWhitePath} alt="Brand logo" className="w-16" />
                    </AdaptiveLink>
                ),
                ...(domainLogo && { className: "bg-white hover:!bg-grey-extra-light border-r" })
            },
            ...(dropdownItems.length
                ? [
                      {
                          key: "dropdownItems",
                          render: (): JSX.Element => <DropdownMenu items={dropdownItems} isExpanded={isExpanded} />
                      }
                  ]
                : [])
        ];
    }, [dropdownItems, domainLogo, homeHref, isHomeLinkRouted, isExpanded]);

    return (
        <motion.div
            className="relative h-full bg-slate-primary text-white"
            initial={isExpanded ? "open" : "closed"}
            animate={isExpanded ? "open" : "closed"}
            variants={menuVariant}
        >
            {navigationItems.map(element => (
                <div key={element.key}>
                    <NavbarItem
                        isExpanded={isExpanded}
                        isCenter={element.key !== "dropdownItems" || !isExpanded}
                        className={classNames("px-2", element.className)}
                    >
                        {element.render()}
                    </NavbarItem>
                    <Separator isDark />
                </div>
            ))}
            {menuItems?.map((category, i) => {
                return (
                    <div key={i}>
                        <div className="flex flex-col overflow-hidden">
                            {category.map(menuItem => {
                                const Icon = menuItem.icon;
                                return (
                                    <NavbarItem
                                        key={menuItem.key}
                                        onClick={() => onMenuItemClick(menuItem)}
                                        isActive={menuItem.key === activeMenuItemKey}
                                        isExpanded={isExpanded}
                                        className="truncate px-6"
                                    >
                                        {!isExpanded && (
                                            <Tooltip title={menuItem.text} placement="right" color="slate">
                                                <LeftNavbarItem icon={Icon} />
                                            </Tooltip>
                                        )}
                                        {isExpanded && <LeftNavbarItem icon={Icon} menuItem={menuItem} />}
                                    </NavbarItem>
                                );
                            })}
                        </div>
                        <Separator isDark />
                    </div>
                );
            })}
            {!hideExpandButton && (
                <div
                    onClick={toggleExpanded}
                    className="absolute bottom-3 -right-3.5 mt-auto mb-10 flex h-7 w-7 cursor-pointer items-center justify-center rounded bg-blue-extra-light"
                >
                    <motion.span initial={isExpanded ? "open" : "closed"} animate={isExpanded ? "open" : "closed"} variants={ROTATE_OPEN_VARIANT}>
                        <ChevronRightIcon color="slate" />
                    </motion.span>
                </div>
            )}
        </motion.div>
    );
}
