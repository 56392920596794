import { useStripe } from "@stripe/react-stripe-js";
import { App, UserProfile } from "@vaultinum/vaultinum-api";
import { omit } from "lodash";
import { ElementType, useCallback, useEffect, useState } from "react";
import Stripe from "stripe";
import { IconProps, openNotificationWithIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { usePaymentContext } from "../../contexts";
import { getDiscountFromPromotionCode, getPromoCode } from "../../services";
import BillingInformation from "./BillingInformation";
import PaymentButtons from "./PaymentButtons";
import PaymentForm from "./PaymentForm";
import { PaymentInformations, PricingColCard } from "./pricing-col-card";

export type ColumnOptions = {
    hideColumn?: boolean;
    showActionButton?: boolean;
    onButtonClick?: () => void | Promise<void>;
};

export function Payment({
    userProfile,
    doPayment,
    onPaymentCallback,
    onPrevious,
    columnOptions,
    icon
}: {
    userProfile: UserProfile;
    doPayment: (accountId: string, paymentMethodId: string, plans: App.PricingPlans[], promoCode?: string) => Promise<Stripe.Invoice | Stripe.Subscription>;
    onPaymentCallback?: (paymentId?: string) => Promise<void> | void;
    onPrevious?: () => void;
    columnOptions?: ColumnOptions;
    icon?: ElementType<IconProps>;
}) {
    const {
        account,
        cart,
        setCart,
        isProceeding,
        priceInfo,
        setIsProceeding,
        isBillingInformationComplete,
        paymentService,
        paymentMethodType,
        setPaymentMethod
    } = usePaymentContext();
    const stripe = useStripe();
    const lang = useLang<CommonLang>();

    async function applyPromoCode(code: string | null) {
        try {
            if (code) {
                const discount = await getPromoCode(code);
                setCart(previousCart => ({ ...previousCart, discount: getDiscountFromPromotionCode(discount) }));
            } else {
                setCart(previousCart => omit(previousCart, ["discount"]));
            }
        } catch {
            openNotificationWithIcon({ type: "error", message: lang.payment.payment.promoCodeError });
        }
    }

    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>();
    const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const listPaymentMethods = useCallback(async () => {
        setLoading(true);
        try {
            const data = await paymentService.listPaymentMethods(account.id);
            setPaymentMethods(data);
            if (data?.[0]) {
                setSelectedPaymentMethodId(data[0].id);
            }
        } catch {
            openNotificationWithIcon({ type: "error", description: lang.payment.method.errorListingPaymentMethods });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        void listPaymentMethods();
    }, []);

    async function proceedToPayment() {
        if (!stripe || !isBillingInformationComplete || !selectedPaymentMethodId) {
            return;
        }
        setIsProceeding(true);
        try {
            const payment = await doPayment(
                account.id,
                selectedPaymentMethodId,
                cart.products.map(product => product.name),
                cart.discount?.code
            );
            if (paymentMethodType === App.PaymentMethod.CARD && payment) {
                if ("latest_invoice" in payment) {
                    const invoice = payment.latest_invoice;
                    if (typeof invoice !== "string" && invoice !== null) {
                        const paymentIntent = invoice.payment_intent;
                        if (
                            typeof paymentIntent !== "string" &&
                            paymentIntent !== null &&
                            paymentIntent.status === "requires_action" &&
                            typeof paymentIntent.client_secret === "string"
                        ) {
                            const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret);
                            if (error) {
                                throw new Error(lang.payment.failed.unableToConfirmCard);
                            }
                        }
                    }
                }
            }
            try {
                setPaymentMethod(paymentMethods?.find(element => element.id === selectedPaymentMethodId));
                await onPaymentCallback?.(payment.id);
            } catch {
                throw new Error(lang.payment.failed.callbackError);
            }
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: error.message || lang.shared.failMessage });
        } finally {
            setIsProceeding(false);
        }
    }

    return (
        <div className="space-y-4">
            <div className="block space-y-10 sm:flex sm:space-x-10 sm:space-y-0" data-id="payment-form">
                {!columnOptions?.hideColumn && priceInfo && (
                    <PricingColCard
                        priceInfo={priceInfo}
                        icon={icon}
                        onClick={columnOptions?.onButtonClick}
                        showActionButton={columnOptions?.showActionButton}
                        extraFooter={<PaymentInformations cart={cart} applyPromoCode={applyPromoCode} disabledPromoCode={isProceeding} />}
                    />
                )}
                <div className="w-full space-y-4">
                    <BillingInformation userProfile={userProfile} />
                    <div className="border-t border-gray-200 space-y-2" />
                    <PaymentForm
                        paymentMethods={paymentMethods}
                        selectedPaymentMethodId={selectedPaymentMethodId}
                        setSelectedPaymentMethodId={setSelectedPaymentMethodId}
                        listPaymentMethods={listPaymentMethods}
                        loading={loading}
                    />
                </div>
            </div>
            <PaymentButtons
                isProceeding={isProceeding}
                canProceed={isBillingInformationComplete && !!selectedPaymentMethodId}
                proceedToPayment={proceedToPayment}
                onPrevious={onPrevious}
            />
        </div>
    );
}
