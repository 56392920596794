import { merge } from "lodash";
import { deCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const de: BaseLang = merge(deCommon, {
    apps: {
        account: {
            name: "Konto",
            description: "Verwalten Sie Ihr Konto und Ihre Organisation."
        },
        deposit: {
            name: "IP Hinterlegung",
            description: "Nachweis Ihres Eigentums"
        },
        escrow: {
            name: "Escrow-Vereinbarung",
            description: "Kontinuität Ihres Geschäftsbetriebes sichern"
        },
        "full-audit": {
            name: "Due Diligence",
            description: "Software-Risikominimierung und Due Diligence"
        },
        timestamping: {
            name: "Timestamping",
            description: "Beweisen Sie das genaue Datum und die Datenintegrität Ihrer wichtigen Dokumente." // to review
        }
    },
    register: {
        slogan: "Die Online-Lösung zum Schutz und zur Überprüfung Ihrer digitalen Vermögenswerte"
    }
});
