/* eslint-disable @typescript-eslint/unbound-method */
import { MessagingQueue } from "@vaultinum/vaultinum-api";
import { getAuth } from "@vaultinum/vaultinum-sdk";
import axios, { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import { merge } from "lodash";
import { doLogout } from "./loginService";

const TOKEN_REVOKED_REASONS = ["auth/user-disabled", "auth/id-token-revoked", "auth/user-not-found"];

axios.interceptors.response.use(undefined, async error => {
    if (error?.response?.status === 401) {
        let errorKey;
        if (error?.request?.responseType === "arraybuffer") {
            errorKey = new TextDecoder().decode(Buffer.from(error?.response?.data));
        } else {
            errorKey = error?.response?.data;
        }
        if (TOKEN_REVOKED_REASONS.includes(errorKey)) {
            await doLogout();
        }
        return Promise.reject({ message: "Unauthorized" });
    }
    return Promise.reject(error);
});

export const kysAnalyser = {
    doGet: async <T>(url: string): Promise<T> => {
        const result = await doRequest<T>(url, "GET");
        return result.data;
    },
    doPost: async <T>(url: string, data?: unknown): Promise<T> => {
        const result = await doRequest<T>(url, "POST", data);
        return result.data;
    },
    doDelete: async <T>(url: string): Promise<T> => {
        const result = await doRequest<T>(url, "DELETE");
        return result.data;
    }
};

async function doRequest<T>(url: string, method: "GET" | "POST" | "DELETE", data?: unknown): Promise<{ data: T; headers: AxiosResponseHeaders }> {
    const config = await getAuthConfig();
    if (process.env.REACT_APP_USE_QUEUE_MESSAGING === "true") {
        return sendMessage(url, method, MessagingQueue.KYS_ANALYSER, config, data);
    }
    const uri = `${process.env.REACT_APP_KYS_ANALYSER_URL}/${url}`;
    switch (method) {
        case "POST":
            return axios.post(uri, data, config);
        case "DELETE":
            return axios.delete(uri, config);
        case "GET":
        default:
            return axios.get(uri, config);
    }
}

async function mergeAuthConfig(config?: AxiosRequestConfig) {
    const authConfig = await getAuthConfig();
    const configuration = { ...authConfig, ...config, headers: { ...authConfig.headers, ...config?.headers } };
    return configuration;
}

export async function doGetFile(url: string, mimeType: string, apiHost?: string, additionalHeaders?: Record<string, string>): Promise<BlobPart> {
    const config: AxiosRequestConfig = merge(
        {
            responseType: "arraybuffer",
            headers: { Accept: mimeType, ...additionalHeaders }
        },
        await getAuthConfig()
    );
    const result = await axios.get(`${apiHost || process.env.REACT_APP_API_HOST}/${url}`, config);
    return result.data;
}

function downloadBlob(fileName: string, blob: Blob) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    link.remove();
}

function downloadFile(blobPart: BlobPart, fileName: string, type = "application/pdf") {
    const blob = new Blob([blobPart], { type });
    downloadBlob(fileName, blob);
}

export async function doGet<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const configuration = await mergeAuthConfig(config);
    const result = await axios.get(`${process.env.REACT_APP_TRUST_API_URL}/${url}`, configuration);
    if (config?.responseType === "blob") {
        return result.data;
    }
    return result.data.response;
}

export async function doPost<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
    const configuration = await mergeAuthConfig(config);
    const result = await axios.post(`${process.env.REACT_APP_TRUST_API_URL}/${url}`, data, configuration);
    return result.data.response;
}

export async function doPut<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const configuration = await mergeAuthConfig(config);
    const result = await axios.put(`${process.env.REACT_APP_TRUST_API_URL}/${url}`, undefined, configuration);
    return result.data.response;
}

export async function downloadPDF(url: string, fileName: string, apiHost?: string): Promise<void> {
    downloadFile(await doGetFile(url, "application/pdf", apiHost), fileName);
}

export async function doDelete<T>(url: string, apiHost?: string): Promise<T> {
    const result = await axios.delete(`${apiHost || process.env.REACT_APP_API_HOST}/${url}`, await getAuthConfig());
    return result.data;
}

export async function doApiPost<T>(url: string, data?: unknown, apiHost?: string): Promise<T> {
    const result = await axios.post(`${apiHost || process.env.REACT_APP_API_HOST}/${url}`, data, await getAuthConfig());
    return result.data;
}

export async function getAuthConfig(): Promise<AxiosRequestConfig> {
    const currentUser = getAuth().currentUser;
    const token = await currentUser?.getIdToken();
    return { headers: { Authorization: `Bearer ${token}` } };
}

export async function sendMessage<T>(
    path: string,
    method: "GET" | "POST" | "DELETE",
    queue: MessagingQueue,
    config: AxiosRequestConfig,
    body?: unknown
): Promise<{ data: T; headers: AxiosResponseHeaders }> {
    return axios.post(`${process.env.REACT_APP_API_HOST}/message`, { path, queue, method, body }, config);
}
