import { Question, Section, Survey } from "@vaultinum/vaultinum-api";
import { flatten } from "lodash";

const MAX_ROOT_SECTION_LENGTH = 35;
const MAX_VALUE_LENGTH = 1500;
const MAX_TOTAL_RECO_LENGTH = 2500;

export interface NodeWithIssues {
    target: Survey.NodeTarget;
    issue: string;
    missingText?: boolean;
}

const getCommonTextIssues = (target: Survey.NodeTarget, text?: string, emptyTextRequired?: boolean): NodeWithIssues[] => {
    const result: NodeWithIssues[] = [];
    if (text && /<\/[a-z]+>/.test(text)) {
        result.push({ target, issue: "HTML tag detected in the text" });
    }
    if (!emptyTextRequired && (!text || !text.trim())) {
        result.push({ target, issue: "Missing text.", missingText: true });
    }
    return result;
};

export function getOptionIssues(option: Question.Option, surveyLang: Survey.Lang, isIncludedInPreambleSection: boolean): NodeWithIssues[] {
    let result: NodeWithIssues[] = flatten(option.questions?.map(question => getQuestionIssues(question, surveyLang, isIncludedInPreambleSection)));
    const target: Survey.NodeTarget = { nodeId: option.id, type: Survey.NodeType.OPTION, property: Survey.NodeTargetProperty.TEXT };
    const { text, why, fixes } = surveyLang.options[option.id] ? surveyLang.options[option.id] : { text: "", why: "", fixes: {} };
    const hasEmptyOptionModifier =
        option.modifiers?.textInput || option.modifiers?.countryList || option.modifiers?.trademarkClassList || option.modifiers?.extensionList;
    result = [...result, ...getCommonTextIssues(target, text, hasEmptyOptionModifier)];

    if (hasEmptyOptionModifier && text && !text.trim()) {
        result.push({ target, issue: "The option modifiers require the text to be empty" });
    }
    if (text && text.endsWith("?")) {
        result.push({ target, issue: "'?' found at the end of text. The option might have been used as a question" });
    }
    if (!option.evaluations.filter(evalTag => evalTag.tag).length && !hasEmptyOptionModifier && !option.modifiers?.textInput) {
        if (!isIncludedInPreambleSection) {
            result.push({ target, issue: "Option needs at least one evaluation." });
        }
    }
    if (text && text.includes("please upload") && !option.modifiers?.fileUpload) {
        result.push({ target, issue: "Missing upload modifier." });
    }
    if (
        option.evaluations.filter(tagEval => tagEval.tag).some(tagEval => [Question.Evaluation.Critical, Question.Evaluation.High].includes(tagEval.evaluation))
    ) {
        if (!why) {
            result.push({ target, issue: "Missing 'why' context to very negative evaluation" });
        }
        if (!fixes || !Object.keys(fixes).length || Object.values(fixes).some(fix => !fix)) {
            result.push({ target, issue: "Missing 'fix' proposal for very negative evaluation" });
        }
    }
    const whyTarget: Survey.NodeTarget = { ...target, property: Survey.NodeTargetProperty.WHY };
    if (why) {
        if (why.startsWith("You have answered that ")) {
            result.push({
                target: whyTarget,
                issue: "You seem to be repeating the question in the 'why' context. This is not needed as will be automatically added by the report"
            });
        }
        if (why.length > MAX_VALUE_LENGTH) {
            result.push({
                target: whyTarget,
                issue: `The 'why' context is ${why.length} characters. It should be under ${MAX_VALUE_LENGTH} characters`
            });
        }
    }
    if (fixes) {
        Object.entries(fixes).forEach(([key, value]) => {
            const fixTarget: Survey.NodeTarget = { ...target, property: Survey.NodeTargetProperty.FIXES, subNodeId: key };
            const invalidWords = ["quick fix", "long fix", "reco : "].filter(invalidWord => value.toLowerCase().includes(invalidWord));
            invalidWords.forEach(invalidWord =>
                result.push({ target: fixTarget, issue: `You are including the word '${invalidWord}' that is no longer needed` })
            );
            if (invalidWords.length > 1) {
                result.push({ target: fixTarget, issue: `You are providing ${invalidWords.length} fixes at the same time, they need to be split` });
            }
            if (value.length > MAX_VALUE_LENGTH) {
                result.push({
                    target: fixTarget,
                    issue: `The 'fix' is ${value.length} characters. It should be under ${MAX_VALUE_LENGTH} characters`
                });
            }
        });
    }
    const totalWhyFixesSize = (why?.length || 0) + (Object.values(fixes || {}).reduce((acc, fix) => acc + (fix?.length || 0), 0) || 0);
    if (totalWhyFixesSize > MAX_TOTAL_RECO_LENGTH) {
        result.push({
            target,
            issue: `The total size of the 'why' and 'fixes' is ${totalWhyFixesSize} characters, it should be under ${MAX_TOTAL_RECO_LENGTH}`
        });
    }
    return result;
}

export function getQuestionIssues(question: Question, surveyLang: Survey.Lang, isIncludedInPreambleSection: boolean): NodeWithIssues[] {
    const text = surveyLang.questions[question.id]?.text;
    const description = surveyLang.questions[question.id]?.description;
    const target: Survey.NodeTarget = { nodeId: question.id, type: Survey.NodeType.QUESTION, property: Survey.NodeTargetProperty.TEXT };
    const result = getCommonTextIssues(target, text);
    const optionsCount = question.options.length;
    if (text && !text.includes("?")) {
        result.push({ target, issue: "Question is missing a '?'" });
    }
    if (description === "") {
        result.push({ target, issue: "Description is empty" });
    }
    if (question.optionsType === Question.OptionType.Checkbox && question.options.some(option => option.questions && option.questions.length > 0)) {
        result.push({ target, issue: "Options for a multi-option select cant have sub questions." });
    }
    if (text && text.includes("(check all that apply)") && question.optionsType === Question.OptionType.Radio) {
        result.push({ target, issue: "Question ask to check all that apply' but option type is radio." });
    }
    if (!optionsCount && question.optionsType !== Question.OptionType.Context) {
        result.push({ target, issue: "Missing options." });
    }
    if (optionsCount !== 1 && question.optionsType === Question.OptionType.Context) {
        result.push({ target, issue: "Data gathering question should have only one option." });
    }
    if (optionsCount === 1 && question.optionsType === Question.OptionType.Context) {
        const option = question.options[0];
        if (!option.modifiers?.textInput || !option.modifiers?.fileUpload) {
            result.push({ target, issue: "Data gathering question should have a text input/file upload option." });
        }
    }
    if (optionsCount === 1 && question.optionsType !== Question.OptionType.Context) {
        const option = question.options[0];
        if (!option.modifiers?.textInput && !option.modifiers?.trademarkClassList && !option.modifiers?.countryList && !option.modifiers?.extensionList) {
            result.push({ target, issue: "Only one option. A question needs at least 2." });
        }
    }
    if (optionsCount > 1 && question.optionsType === Question.OptionType.Checkbox) {
        const listsCount = question.options.filter(
            option => option.modifiers?.trademarkClassList || option.modifiers?.countryList || option.modifiers?.extensionList
        ).length;
        if (listsCount > 1) {
            result.push({ target, issue: "Question with a list type selector can only have one option." });
        }
    }
    return [...result, ...flatten(question.options.map(option => getOptionIssues(option, surveyLang, isIncludedInPreambleSection)))];
}

export function getSectionIssues(
    section: Section,
    surveyLang: Survey.Lang,
    isIncludedInPreambleSection: boolean,
    filterByMissingText?: boolean
): NodeWithIssues[] {
    let result = section.sections
        ? section.sections.flatMap(section => getSectionIssues(section, surveyLang, isIncludedInPreambleSection, filterByMissingText))
        : [];
    const target: Survey.NodeTarget = { nodeId: section.id, type: Survey.NodeType.SECTION, property: Survey.NodeTargetProperty.TITLE };
    const title = surveyLang.sections[section.id]?.title;
    if (filterByMissingText) {
        return getCommonTextIssues(target, title).filter(issue => !!issue.missingText);
    }
    result = [...result, ...getCommonTextIssues(target, title)];
    if (section.questions) {
        result = [...result, ...section.questions.flatMap(question => getQuestionIssues(question, surveyLang, isIncludedInPreambleSection))];
    } else if (!section.sections?.length) {
        result.push({ target, issue: "Empty section (no questions and no sections)." });
    }
    return result;
}

export function getSurveyVersionIssues(surveyVersion: Survey.Version, surveyLang: Survey.Lang): NodeWithIssues[] {
    let result: NodeWithIssues[] = [];
    for (const section of surveyVersion.sections) {
        const target: Survey.NodeTarget = { nodeId: section.id, type: Survey.NodeType.SECTION, property: Survey.NodeTargetProperty.TITLE };
        const title = surveyLang.sections[section.id]?.title;
        if (!surveyLang.sections[section.id]?.description) {
            result.push({ target, issue: "Missing root section description" });
        }
        if (title && title.length > MAX_ROOT_SECTION_LENGTH) {
            result.push({ target, issue: `Section name is too long (${title.length}). Keep it under 35 characters.` });
        }
    }
    result = [...result, ...surveyVersion.sections.flatMap(section => getSectionIssues(section, surveyLang, section.id === surveyVersion.preambleSectionId))];
    return result;
}

export function getSurveyMissingTextIssues(surveyVersion: Survey.Version, surveyLang: Survey.Lang): NodeWithIssues[] {
    return surveyVersion.sections.flatMap(section => getSectionIssues(section, surveyLang, section.id === surveyVersion.preambleSectionId, true));
}

function getTargetValue(surveyLang: Survey.Lang, target: Survey.NodeTarget): string | undefined {
    if (target.type === Survey.NodeType.SECTION) {
        if (target.property === "title") {
            return surveyLang.sections[target.nodeId]?.title;
        }
        if (target.property === "description") {
            return surveyLang.sections[target.nodeId]?.description;
        }
    }
    if (target.type === Survey.NodeType.QUESTION) {
        if (target.property === "text") {
            return surveyLang.questions[target.nodeId]?.text;
        }
    }
    if (target.type === Survey.NodeType.OPTION) {
        if (target.property === "text") {
            return surveyLang.options[target.nodeId]?.text;
        }
        if (target.property === "why") {
            return surveyLang.options[target.nodeId]?.why;
        }
        if (target.property === "fixes" && target.subNodeId) {
            return surveyLang.options[target.nodeId]?.fixes?.[target.subNodeId];
        }
    }
    return undefined;
}

export function getSurveyCharacterCount(surveyLang: Survey.Lang): number {
    let total = 0;
    const countValue = (target: Survey.NodeTarget): void => {
        const value = getTargetValue(surveyLang, target);
        if (!value) {
            return;
        }
        total += value.length;
    };
    // Sections
    for (const sectionId of Object.keys(surveyLang.sections)) {
        countValue({
            nodeId: sectionId,
            property: Survey.NodeTargetProperty.TITLE,
            type: Survey.NodeType.SECTION
        });
        countValue({
            nodeId: sectionId,
            property: Survey.NodeTargetProperty.DESCRIPTION,
            type: Survey.NodeType.SECTION
        });
    }
    // Questions
    for (const questionId of Object.keys(surveyLang.questions)) {
        countValue({
            nodeId: questionId,
            property: Survey.NodeTargetProperty.TEXT,
            type: Survey.NodeType.QUESTION
        });
        countValue({
            nodeId: questionId,
            property: Survey.NodeTargetProperty.DESCRIPTION,
            type: Survey.NodeType.QUESTION
        });
    }
    // Options
    for (const optionId of Object.keys(surveyLang.options)) {
        const option = surveyLang.options[optionId];
        countValue({
            nodeId: optionId,
            property: Survey.NodeTargetProperty.WHY,
            type: Survey.NodeType.OPTION
        });
        countValue({
            nodeId: optionId,
            property: Survey.NodeTargetProperty.TEXT,
            type: Survey.NodeType.OPTION
        });
        // Option > Fixes
        if (option.fixes) {
            for (const fixId of Object.keys(option.fixes)) {
                countValue({
                    nodeId: optionId,
                    property: Survey.NodeTargetProperty.FIXES,
                    type: Survey.NodeType.OPTION,
                    subNodeId: fixId
                });
            }
        }
    }
    return total;
}
