import fileSize from "filesize";
import { Accept, FileRejection } from "react-dropzone";
import { plural } from "../../lang";
import { BaseLang } from "../../lang/CommonLang";

interface FileSizeOptions {
    maxTotalFiles?: number;
    maxFileSize?: number;
    maxTotalSize?: number;
}

interface FileOptions extends FileSizeOptions {
    fileTypes?: string[];
    accept?: Accept;
    uploadLimitationsText?: string;
}

export interface UploaderProps {
    setIsWorking: (value: boolean) => void;
    uploadFile: (
        file: File,
        onProgress: (fileName: string, progress: number) => void,
        onError: (fileName: string, errorMessage: string) => void | Promise<void>,
        abortController: AbortController | undefined
    ) => Promise<string | void>;
    options: {
        file: FileOptions;
        uploader?: {
            isDisabled?: boolean;
            isUploadOnDropEnabled?: boolean;
        };
    };
    files?: EnrichedUploadFiles[];
    onFilesChanged?: (files: EnrichedUploadFiles[]) => void;
    deleteFile?: (fileId: string) => Promise<void>;
    isFileValid?: (file: File) => boolean;
    onUploadEnd?: () => void;
    onFilesAdded?: (files: File[]) => void;
}

export interface EnrichedUploadFiles {
    file: File;
    size: number;
    id: string | undefined;
    progress?: number;
    abortController?: AbortController;
}

// Max number of files allowed for upload
export const DEFAULT_UPLOADER_MAX_TOTAL_FILES = 100;

// One file cannot exceed 2Gb
export const DEFAULT_UPLOADER_MAX_FILE_SIZE = 2 * 8 ** 10;

// All the files cannot exceed 10Gb
export const DEFAULT_UPLOADER_MAX_TOTAL_SIZE = 10 * 8 ** 10;

export const DEFAULT_UPLOADER_CHUNK_SIZE = 1048576 * 3; // Chunks of 3 Mb
export const UPLOADER_CANCEL_MESSAGE = "canceled"; // Canceled message from the abort controller

export function getFileRejectionMessage(lang: BaseLang, fileRejections: FileRejection[], options: FileOptions): string | undefined {
    if (!fileRejections.length) {
        return undefined;
    }

    for (const fileRejection of fileRejections) {
        for (const fileRejectionError of fileRejection.errors) {
            switch (fileRejectionError.code) {
                case "file-too-large":
                    return lang.uploadFile.maxFileSizeError(fileRejection.file.name, options?.maxFileSize ?? DEFAULT_UPLOADER_MAX_FILE_SIZE);
                case "too-many-files":
                    return lang.uploadFile.maxNumberOfFilesError(options?.maxTotalFiles ?? DEFAULT_UPLOADER_MAX_TOTAL_FILES);
                case "file-invalid-type":
                    return lang.uploadFile.acceptedFileTypes(options.fileTypes?.join(", ") ?? "");
                default:
                    return lang.uploadFile.errorWhileUploading;
            }
        }
    }

    return undefined;
}

export function getUploadErrorMessage({
    acceptedFiles,
    fileRejections,
    files,
    totalSize,
    acceptedFilesTotalSize,
    lang,
    options
}: {
    acceptedFiles: File[];
    fileRejections: FileRejection[];
    files: File[];
    totalSize: number;
    acceptedFilesTotalSize: number;
    lang: BaseLang;
    options: FileOptions;
}): string | undefined {
    // Check if there are any file rejections
    const rejectionError = getFileRejectionMessage(lang, fileRejections, options);
    if (rejectionError) {
        return rejectionError;
    }

    // Check number of files
    const maxTotalFiles = options?.maxTotalFiles ?? DEFAULT_UPLOADER_MAX_TOTAL_FILES;
    if (files.length + acceptedFiles.length > maxTotalFiles) {
        return lang.uploadFile.maxNumberOfFilesError(maxTotalFiles);
    }

    // Check if files already exist
    const acceptedFileNames = acceptedFiles.map(file => file.name);
    const intersectionFiles = files.filter(file => acceptedFileNames.includes(file.name));
    if (intersectionFiles.length) {
        return `${intersectionFiles.map(file => file.name).join(", ")} ${plural(lang.uploadFile.alreadyExists, intersectionFiles.length)}`;
    }

    // Check total size of files
    const maxTotalSize = options.maxTotalSize ?? DEFAULT_UPLOADER_MAX_TOTAL_SIZE;
    if (totalSize + acceptedFilesTotalSize > maxTotalSize) {
        return lang.uploadFile.maxTotalFileSizeError(fileSize(maxTotalSize));
    }

    const fileNames = acceptedFiles.map(file => file.name);
    const invalidFileNames = fileNames.filter(fileName => fileName.length > 175);
    if (invalidFileNames.length) {
        return `${invalidFileNames.join(", ")} ${plural(lang.uploadFile.fileNameTooLong, invalidFileNames.length)}`;
    }

    return undefined;
}
