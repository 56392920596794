import { Survey } from "@vaultinum/vaultinum-api";
import { Controller, Form, Input, RowCard, useForm, User, yup, yupResolver } from "@vaultinum/vaultinum-sdk";
import { DebouncedInput } from "../../../../../components";
import { updateLang } from "../../../../../services/surveyLangService";
import SurveyEvaluationTagView from "./SurveyEvaluationTagView";
import SurveyVariantView from "./SurveyVariantView";

export default function SurveyGeneralEditView({
    surveyVersion,
    surveyLang,
    setSurveyVersion,
    user
}: {
    surveyVersion: Survey.Version;
    surveyLang: Survey.Lang;
    setSurveyVersion: (surveyVersion: Survey.Version) => void;
    user: User;
}) {
    const kebabCaseValidator = (value?: string): boolean => {
        if (value && !/^([a-z0-9]*)(-[a-z0-9]+)*$/.test(value)) {
            return false;
        }
        return true;
    };

    const schema = yup.object({
        "about-page-uid": yup.string().test("kebab-case", "The value must be a valid uid (ex: aa-bb-cc)", kebabCaseValidator)
    });

    const { control } = useForm({
        resolver: yupResolver(schema)
    });

    return (
        <div className="space-y-2">
            <RowCard title={{ text: "General" }}>
                <div className="flex w-full flex-col gap-2">
                    <Form>
                        <Controller
                            name="key"
                            control={control}
                            render={({ field }) => <Input.Text {...field} label="Key" disabled placeholder="Key..." value={surveyVersion.surveyKey} />}
                        />
                        <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                                <DebouncedInput
                                    {...field}
                                    placeholder="Title..."
                                    value={surveyLang.survey.title}
                                    onChange={value => updateLang(surveyLang, user.uid).survey.title(value)}
                                />
                            )}
                        />
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <DebouncedInput
                                    {...field}
                                    placeholder="Description..."
                                    value={surveyLang.survey.description}
                                    onChange={value => updateLang(surveyLang, user.uid).survey.description(value)}
                                />
                            )}
                        />
                        <Controller
                            name="about-page-uid"
                            control={control}
                            render={({ field }) => (
                                <DebouncedInput
                                    {...field}
                                    name="about-page-uid"
                                    placeholder="About page uid..."
                                    value={surveyLang.survey.aboutPageUID || ""}
                                    onChange={value => updateLang(surveyLang, user.uid).survey.aboutPageUID(value)}
                                />
                            )}
                        />
                    </Form>
                </div>
            </RowCard>
            <RowCard title={{ text: "Evaluation Tags" }}>
                <div className="flex w-full flex-col">
                    <SurveyEvaluationTagView surveyVersion={surveyVersion} setSurveyVersion={setSurveyVersion} surveyLang={surveyLang} user={user} />
                </div>
            </RowCard>
            <RowCard title={{ text: "Variants" }}>
                <div className="flex w-full flex-col">
                    <SurveyVariantView surveyVersion={surveyVersion} setSurveyVersion={setSurveyVersion} />
                </div>
            </RowCard>
        </div>
    );
}
