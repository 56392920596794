import {
    AnalysedCodeRepository,
    FULL_AUDIT_COLLECTION,
    FULL_AUDIT_REPORTS_CODE_REPOSITORIES_COLLECTION,
    FULL_AUDIT_REPORTS_COLLECTION
} from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import {
    collection,
    CollectionReference,
    DocumentData,
    DocumentSnapshot,
    getDocs,
    getFirestore,
    onSnapshot,
    QueryDocumentSnapshot
} from "@vaultinum/vaultinum-sdk";
import { isUndefined, omitBy } from "lodash";

function convertDocToAnalysedCodeRepository(doc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>): AnalysedCodeRepository {
    const data = doc.data();
    const analyses: Pick<AnalysedCodeRepository, "analyses"> = Object.keys(data?.analyses || {}).reduce((prev: AnalysedCodeRepository.Analyses, key) => {
        const analysis = data?.analyses?.[key];
        // eslint-disable-next-line no-param-reassign
        prev[key] = {
            ...analysis,
            options: {
                ...analysis?.options,
                updatedDate: analysis?.options?.updatedDate?.toDate()
            },
            lastRunDate: analysis?.lastRunDate?.toDate()
        };
        return prev;
    }, {});

    return {
        ...(data as Omit<AnalysedCodeRepository, "id">),
        analyses: omitBy(analyses, isUndefined),
        creationDate: data?.creationDate?.toDate(),
        id: doc.id
    };
}

function getCodeRepositoriesCollection(fullAuditId: string, reportId: string): CollectionReference<DocumentData> {
    return collection(
        getFirestore(),
        FULL_AUDIT_COLLECTION,
        fullAuditId,
        FULL_AUDIT_REPORTS_COLLECTION,
        reportId,
        FULL_AUDIT_REPORTS_CODE_REPOSITORIES_COLLECTION
    );
}

export function getCodeAnalysisReportCodeRepositories(
    report: CodeAnalysisReport | undefined | null,
    onUpdate: (repositories: AnalysedCodeRepository[]) => void
): () => void;
export function getCodeAnalysisReportCodeRepositories(report?: CodeAnalysisReport | null): Promise<AnalysedCodeRepository[]>;
export function getCodeAnalysisReportCodeRepositories(
    report?: CodeAnalysisReport | null,
    onUpdate?: (repositories: AnalysedCodeRepository[]) => void
): Promise<AnalysedCodeRepository[]> | (() => void) {
    if (onUpdate) {
        if (!report) {
            onUpdate([]);
            return () => {};
        }
        return onSnapshot(
            getCodeRepositoriesCollection(report.fullAuditId, report.id),
            querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToAnalysedCodeRepository)),
            () => onUpdate([])
        );
    }
    if (!report) {
        return Promise.resolve([]);
    }
    return getDocs(getCodeRepositoriesCollection(report.fullAuditId, report.id)).then(repositoriesDocs =>
        repositoriesDocs.docs.map(convertDocToAnalysedCodeRepository)
    );
}
