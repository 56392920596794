import { Account, Event } from "@vaultinum/vaultinum-api";
import { Column, Tag } from "@vaultinum/vaultinum-sdk";
import { useCallback } from "react";
import { getTagLevel } from "../../../common/TagTools";
import { getEvents } from "../../../services/eventService";
import { AccountTabPage, getAccountLinkById } from "../../../services/routing.service";
import { StandardModelListView } from "./StandardModelListView";

function getEventsColumns(accountId?: string, defaultFilteredValues: Event.Level[] = []): Column<Account.Event>[] {
    return [
        {
            header: "Level",
            accessorKey: "level",
            cell: cell => getTagLevel(cell.getValue<Event.Level>()),
            defaultFilteredValues
        },
        { header: "Message", accessorKey: "messageKey" },
        {
            header: "Source",
            accessorKey: "source",
            cell: cell => <Tag message={cell.getValue<Account.Event.Source>().toUpperCase()} />
        },
        ...(accountId ? [{ header: "AccountId", accessorKey: "accountId" }] : []),
        {
            header: "Metadata",
            enableColumnFilter: false,
            accessorFn: row => Object.keys(row.metaData).sort(),
            cell: cell => {
                const keys = cell.getValue<string[]>();
                const metaData = cell.row.original.metaData;
                return (
                    <ul>
                        {keys.map(key => (
                            <li key={key}>
                                <>
                                    {key} → {JSON.stringify(metaData[key])}
                                </>
                            </li>
                        ))}
                    </ul>
                );
            }
        }
    ];
}

export function EventsList({ accountId, defaultLevelsFiltered }: { accountId?: string; defaultLevelsFiltered?: Event.Level[] }): JSX.Element {
    const columns = getEventsColumns(accountId, defaultLevelsFiltered);

    function goToAccount(event: Account.Event) {
        return getAccountLinkById(event.accountId, AccountTabPage.Tab.events);
    }

    const loadData = useCallback((onUpdate: (events: Account.Event[]) => void) => getEvents(accountId, onUpdate), [accountId]);

    return (
        <StandardModelListView<Account.Event>
            name="Events"
            getModelItems={loadData}
            modelPageLink={!accountId ? goToAccount : undefined}
            creationDateIndex="insertDate"
            searchPlaceholder="Search by message"
            searchOptions={{ hideIgnored: true }}
            columns={columns}
            storageKey="table-events"
        />
    );
}
