import {
    ApiOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    BranchesOutlined,
    BugFilled,
    BugOutlined,
    CheckCircleTwoTone,
    CheckSquareTwoTone,
    CloseCircleTwoTone,
    CopyOutlined,
    DeleteOutlined,
    DeleteTwoTone,
    DisconnectOutlined,
    EditOutlined,
    EditTwoTone,
    EllipsisOutlined,
    EnterOutlined,
    EnvironmentOutlined,
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    EyeInvisibleFilled,
    EyeInvisibleOutlined,
    EyeOutlined,
    FileOutlined,
    FileZipFilled,
    FileZipOutlined,
    FlagOutlined,
    FlagTwoTone,
    FolderOutlined,
    FolderTwoTone,
    FormOutlined,
    GlobalOutlined,
    HighlightTwoTone,
    InboxOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    LayoutFilled,
    LinkedinOutlined,
    LoadingOutlined,
    LogoutOutlined,
    MenuOutlined,
    MessageOutlined,
    PhoneOutlined,
    PieChartOutlined,
    PlayCircleOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    PoweroffOutlined,
    ProfileOutlined,
    QuestionCircleFilled,
    QuestionCircleOutlined,
    QuestionCircleTwoTone,
    RedoOutlined,
    SecurityScanFilled,
    SecurityScanOutlined,
    SettingOutlined,
    ShareAltOutlined,
    ShoppingOutlined,
    SlidersOutlined,
    StarTwoTone,
    TeamOutlined,
    ThunderboltOutlined,
    ToTopOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import classNames from "classnames";
import { ElementType } from "react";
import { COLOR_SCHEME } from "../../../referentials";
import { IconProps, IconSize } from "../Icons";

const iconComponent =
    (CustomIconComponent: ElementType) =>
    ({ color, shade = "primary", size = "sm", isTwoToneColor = false, ...props }: IconProps) => (
        <CustomIconComponent
            {...props}
            className={classNames({
                "hover:cursor-pointer": !!props.onClick
            })}
            style={{ fontSize: IconSize[size], color: color ? COLOR_SCHEME[color][shade] : color }}
            twoToneColor={isTwoToneColor && color ? COLOR_SCHEME[color][shade] : COLOR_SCHEME.blue.primary}
            children={<CustomIconComponent />}
        />
    );

// System icons
export const ExclamationIcon = iconComponent(ExclamationCircleOutlined);
export const ExclamationFilledIcon = iconComponent(ExclamationCircleFilled);
export const SettingsIcon = iconComponent(SettingOutlined);
export const LogoutIcon = iconComponent(LogoutOutlined);
export const TeamIcon = iconComponent(TeamOutlined);
export const AccountIcon = iconComponent(ShoppingOutlined);
export const UnlockIcon = iconComponent(UnlockOutlined);
export const AddIcon = iconComponent(PlusOutlined);
export const AddCircleIcon = iconComponent(PlusCircleOutlined);
export const EditIcon = iconComponent(EditOutlined);
export const RedoIcon = iconComponent(RedoOutlined);
export const DeleteIcon = iconComponent(DeleteOutlined);
export const OptionsIcon = iconComponent(EllipsisOutlined);
export const MenuIcon = iconComponent(MenuOutlined);
export const FileIcon = iconComponent(FileOutlined);
export const FolderIcon = iconComponent(FolderOutlined);
export const EyeInvisibleFilledIcon = iconComponent(EyeInvisibleFilled);
export const EyeInvisibleIcon = iconComponent(EyeInvisibleOutlined);
export const EyeIcon = iconComponent(EyeOutlined);
export const AnalysisIcon = iconComponent(PieChartOutlined);
export const GitBranchIcon = iconComponent(BranchesOutlined);
export const ConnectIcon = iconComponent(ApiOutlined);
export const PoweroffIcon = iconComponent(PoweroffOutlined);
export const UpgradeIcon = iconComponent(ToTopOutlined);
export const GlobeIcon = iconComponent(GlobalOutlined);
export const LinkedinIcon = iconComponent(LinkedinOutlined);
export const PhoneIcon = iconComponent(PhoneOutlined);
export const ArchiveIcon = iconComponent(InboxOutlined);
export const LocationIcon = iconComponent(EnvironmentOutlined);
export const ArrowLeft = iconComponent(ArrowLeftOutlined);
export const ArrowRight = iconComponent(ArrowRightOutlined);
export const InfoIcon = iconComponent(InfoCircleOutlined);
export const MessageIcon = iconComponent(MessageOutlined);
export const StarTwoToneIcon = iconComponent(StarTwoTone);
export const CopyIcon = iconComponent(CopyOutlined);
export const EnterIcon = iconComponent(EnterOutlined);
export const DeleteTwoToneIcon = iconComponent(DeleteTwoTone);
export const EditTwoToneIcon = iconComponent(EditTwoTone);
export const FolderTwoToneIcon = iconComponent(FolderTwoTone);
export const InfoCircleTwoToneIcon = iconComponent(InfoCircleTwoTone);
export const QuestionCircleTwoToneIcon = iconComponent(QuestionCircleTwoTone);
export const HighlightTwoToneIcon = iconComponent(HighlightTwoTone);
export const FormIcon = iconComponent(FormOutlined);
export const PlayCircleIcon = iconComponent(PlayCircleOutlined);
export const ProfileIcon = iconComponent(ProfileOutlined);
export const ShareAltIcon = iconComponent(ShareAltOutlined);
export const SlidersIcon = iconComponent(SlidersOutlined);
export const ThunderboltIcon = iconComponent(ThunderboltOutlined);
export const CheckSquareTwoToneIcon = iconComponent(CheckSquareTwoTone);
export const PositiveTwoToneIcon = iconComponent(CheckCircleTwoTone);
export const NegativeTwoToneIcon = iconComponent(CloseCircleTwoTone);
export const FlagIcon = iconComponent(FlagOutlined);
export const FlagTwoToneIcon = iconComponent(FlagTwoTone);
export const LoadingIcon = iconComponent(LoadingOutlined);
export const DisconnectedIcon = iconComponent(DisconnectOutlined);

// Business icons
export const KiuwanOutlinedIcon = iconComponent(SecurityScanOutlined);
export const KiuwanIcon = iconComponent(SecurityScanFilled);
export const SonarOutlinedIcon = iconComponent(BugOutlined);
export const SonarIcon = iconComponent(BugFilled);
export const StructureOutlinedIcon = iconComponent(FileZipOutlined);
export const StructureIcon = iconComponent(FileZipFilled);
export const UnknownAnalyserOutlinedIcon = iconComponent(QuestionCircleOutlined);
export const UnknownAnalyserIcon = iconComponent(QuestionCircleFilled);
export const StrutureMapIcon = iconComponent(LayoutFilled);
