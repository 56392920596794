import { getNodeTargetValue, getScoreFromQuestionEvaluation, query, Question, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, CheckSquareTwoToneIcon, InfoCircleTwoToneIcon, message, PositiveTwoToneIcon, Tooltip, User } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { groupBy, mapValues } from "lodash";
import { ReactNode } from "react";
import { sameTarget } from "../../../../../../common/AccountTools";
import { isSurveyPublished, recursiveDeleteSection } from "../../../../../../common/SurveyTools";
import { updateLang } from "../../../../../../services/surveyLangService";
import { EditableCellActionProps } from "../../../components/EditableCell";
import SurveyNodeEditableCell from "../../../components/SurveyNodeEditableCell";
import { getIconFromEvaluation } from "../../SurveyBuilderTools";
import { createAddNode } from "./AddNode";
import { sectionToTreeNode } from "./SectionTreeNode";
import { newEmptySection } from "./SurveyTreeNodeTools";
import { SurveyTreeNode } from "./TreeNode";

function getSurveyQuestionPossibleEvaluations(question: Question): Question.TagEvaluation[] {
    return question.options.flatMap(option => option.evaluations);
}

function EmptyVariant(): JSX.Element {
    return <span className="text-xs text-gray-500">-</span>;
}

function getVariantLabel(variants: Record<string, string>, variant: Survey.Variant, fromPreamble: boolean, isSurveyQuestion: boolean): ReactNode {
    if (fromPreamble) {
        if (!isSurveyQuestion) {
            return variants?.[variant.key] || <EmptyVariant />;
        }
    } else if (isSurveyQuestion) {
        return variants?.[variant.key] || variant.defaultValue || <EmptyVariant />;
    }
    return null;
}

export const getTagEvalColumns = (questions: Question[]): { [key: string]: ReactNode } => {
    const evalColumns: { [key: string]: ReactNode } = {};
    const evalTags = questions.flatMap(getSurveyQuestionPossibleEvaluations);
    const groupedEvalTags = groupBy(evalTags, evalTag => evalTag.tag);
    Object.keys(groupedEvalTags).forEach(tag => {
        const byEvaluation = groupBy(groupedEvalTags[tag], evalTag => evalTag.evaluation);
        const evaluations = Object.keys(byEvaluation)
            .map(item => item as Question.Evaluation)
            .sort((a, b) => getScoreFromQuestionEvaluation(b) - getScoreFromQuestionEvaluation(a));
        evalColumns[tag] = (
            <Tooltip
                title={evaluations.map(evaluation => (
                    <div key={evaluation}>
                        {getIconFromEvaluation(evaluation)} {byEvaluation[evaluation].length}
                    </div>
                ))}
            >
                <span className="block">{groupedEvalTags[tag].length}</span>
            </Tooltip>
        );
    });
    return evalColumns;
};

function isQuestion(value: Question | Question.Option): value is Question {
    return (value as Question).optionsType !== undefined;
}

export const getVariantColumns = (
    surveyVersion: Survey.Version,
    questionOrOption: Question | Question.Option,
    fromPreamble?: boolean,
    onClick?: () => void
): { [key: string]: ReactNode } => {
    if (surveyVersion.variants) {
        const isSurveyQuestion = isQuestion(questionOrOption);
        const variants = isSurveyQuestion ? mapValues(questionOrOption.variants, values => values.join(", ")) : questionOrOption.attributes;
        const variantColumns: { [key: string]: ReactNode } = {};
        for (const variant of surveyVersion.variants) {
            const variantLabel = getVariantLabel(variants || {}, variant, !!fromPreamble, isSurveyQuestion);
            if (variantLabel) {
                variantColumns[variant.key] = (
                    <Tooltip title={variantLabel}>
                        <div className={classNames("w-full truncate text-center", { "cursor-pointer": !!onClick })} onClick={onClick}>
                            {variantLabel}
                        </div>
                    </Tooltip>
                );
            }
        }
        return variantColumns;
    }
    return {};
};

export function TreeNodeEditableCell({
    nodeKey,
    target,
    actions,
    surveyVersion,
    selectedSurveyLang,
    defaultSurveyLang,
    onChange,
    comments,
    onDelete
}: {
    nodeKey: string;
    target: Survey.NodeTarget;
    actions: EditableCellActionProps[];
    surveyVersion: Survey.Version;
    selectedSurveyLang: Survey.Lang;
    defaultSurveyLang?: Survey.Lang;
    onChange: (surveyLang: Survey.Lang, text: string) => void;
    comments: Survey.Comment[];
    onDelete: () => Promise<void>;
}) {
    const isPublished = isSurveyPublished(surveyVersion);
    return (
        <SurveyNodeEditableCell
            nodeKey={nodeKey}
            target={target}
            onChange={onChange}
            onDelete={!isPublished ? onDelete : undefined}
            actions={isPublished ? [] : actions}
            nodeValue={getNodeTargetValue(target, selectedSurveyLang)}
            defaultValue={getNodeTargetValue(target, defaultSurveyLang)}
            surveyLang={selectedSurveyLang}
            suggestionComment={
                comments.filter(comm => sameTarget(comm.target, target)).sort((a, b) => b.creationDate?.getTime() - a.creationDate?.getTime())[0]
            }
        />
    );
}

export const surveyToTreeNodes = (
    lang: BaseLang,
    surveyVersion: Survey.Version,
    selectedSurveyLang: Survey.Lang,
    defaultSurveyLang: Survey.Lang | undefined,
    surveyLangs: Survey.Lang[],
    setSurveyVersion: (surveyVersion: Survey.Version) => void,
    user: User,
    comments: Survey.Comment[]
): SurveyTreeNode[] => {
    const nodes = [
        // Section nodes
        ...surveyVersion.sections.map((section, i) =>
            sectionToTreeNode(
                lang,
                surveyVersion,
                selectedSurveyLang,
                defaultSurveyLang,
                surveyLangs,
                "",
                `${i + 1}`,
                section,
                setSurveyVersion,
                updatedSection => setSurveyVersion(query(surveyVersion).updateSection(updatedSection)),
                async () => {
                    try {
                        await recursiveDeleteSection(surveyLangs, section, user.uid);
                        setSurveyVersion(query(surveyVersion).deleteSection(section.id));
                    } catch (err) {
                        void message.error(err.message);
                    }
                },
                user,
                comments
            )
        )
    ];

    if (!isSurveyPublished(surveyVersion)) {
        nodes.push(
            createAddNode("", "", [
                {
                    type: "section",
                    onClick: () => {
                        const newSection = newEmptySection();
                        updateLang(selectedSurveyLang, user.uid).section(newSection.id).title("");
                        setSurveyVersion({
                            ...surveyVersion,
                            sections: [...surveyVersion.sections, newSection]
                        });
                    }
                }
            ])
        );
    }
    return nodes;
};

export function getIcon(optionType: Question.OptionType): JSX.Element | null {
    switch (optionType) {
        case Question.OptionType.Checkbox:
            return <CheckSquareTwoToneIcon isTwoToneColor />;
        case Question.OptionType.Radio:
            return <PositiveTwoToneIcon isTwoToneColor />;
        case Question.OptionType.Context:
            return <InfoCircleTwoToneIcon isTwoToneColor />;
        default:
            return null;
    }
}
