import * as Dialog from "@radix-ui/react-dialog";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Button, CrossIcon, Separator, openNotificationWithIcon } from "../..";
import "./Modal.css";
import { ModalProps } from "./ModalHelper";

export default function Modal({ hideFooter = false, size = "sm", maxHeight = "500px", ...props }: ModalProps) {
    const [isLoading, setIsLoading] = useState(false);

    function closeModal() {
        props.onClose?.();
    }

    useEffect(() => {
        setIsLoading(props.isLoading ?? false);
    }, [props.isLoading]);

    const onOk = async () => {
        try {
            setIsLoading(true);
            await props.onConfirm?.();
            closeModal();
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: e.message });
        } finally {
            setIsLoading(false);
        }
    };

    const keyListener = useCallback(
        async (event: KeyboardEvent) => {
            if (event.key === "Enter" && !props.isSubmitKeyPressDisabled) {
                await onOk();
            } else if (event.key === "Escape") {
                closeModal();
            }
        },
        [onOk, closeModal]
    );

    useEffect(() => {
        if (!isLoading && props.isOpen) {
            document.addEventListener("keydown", keyListener, false);
        }
        return () => {
            document.removeEventListener("keydown", keyListener, false);
        };
    }, [keyListener, isLoading, props.isOpen]);

    return (
        <Dialog.Root open={props.isOpen}>
            <Dialog.Portal>
                <Dialog.Overlay className="animation-overlay fixed top-0 bottom-0 left-0 right-0 z-50 grid w-full grid-cols-1 place-items-center items-start overflow-y-auto bg-grey-dark/90">
                    <Dialog.Content
                        className={classNames("relative top-14 max-w-[1500px] rounded-lg bg-white shadow-md focus:outline-none", {
                            "w-1/4": size === "sm",
                            "w-1/2": size === "md",
                            "w-4/5": size === "lg"
                        })}
                        data-id="modal-content"
                    >
                        {props.title && (
                            <>
                                <Dialog.Title className="flex w-full items-center justify-between gap-2 p-4">
                                    <div className="flex items-center gap-2 truncate">
                                        {props.icon && <props.icon size="sm" color="slate" />}
                                        <div className="truncate text-sm font-bold text-slate-primary">{props.title}</div>
                                    </div>
                                    {!isLoading && (
                                        <div className="cursor-pointer" onClick={closeModal}>
                                            <CrossIcon color="slate" shade="dark" size="sm" />
                                        </div>
                                    )}
                                </Dialog.Title>
                                <Separator />
                            </>
                        )}
                        <div
                            className={classNames("p-4", { [`max-h-[${maxHeight}] min-h-[70px] overflow-auto whitespace-pre-line`]: !hideFooter })}
                            children={props.children}
                        />
                        {!hideFooter && (
                            <>
                                <Separator />
                                <div className="flex items-center justify-end gap-2 p-2">
                                    {props.onConfirm && (
                                        <Button
                                            type="default"
                                            children={props.cancelText || props.lang.shared.cancel}
                                            onClick={closeModal}
                                            isDisabled={isLoading}
                                            isLoading={false}
                                            data-id="cancel-button"
                                        />
                                    )}
                                    <Button
                                        children={props.okText || props.lang.shared.confirm}
                                        onClick={onOk}
                                        isLoading={isLoading}
                                        isDisabled={props.isDisabled}
                                        data-id="confirm-button"
                                    />
                                </div>
                            </>
                        )}
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
}
