import { useVirtualizer } from "@tanstack/react-virtual";
import { AnimatePresence, Spring, Target, motion } from "framer-motion";
import { useRef } from "react";
import { Spin } from "../Spin/Spin";

type Transition = {
    initial?: Target;
    animate?: Target;
    exit?: Target;
    transition?: Spring;
};

type ListInterface<T> = {
    list: T[] | undefined;
    render: (item: T) => JSX.Element | null;
    emptyText?: string;
    disableAnimation?: boolean;
    isVirtualized?: boolean;
};

type TransitionedListInterface<T> = {
    transition: Transition;
} & ListInterface<T>;

function StaticList<T>({ list, render, transition }: Omit<TransitionedListInterface<T>, "isVirtualized" | "list"> & { list: T[] }) {
    return (
        <motion.ol className="flex flex-col gap-2">
            <AnimatePresence>
                {list.map((listItem, i) => (
                    <motion.li key={`item_${i}`} children={render(listItem)} {...transition} />
                ))}
            </AnimatePresence>
        </motion.ol>
    );
}

function VirtualizedList<T>({ list, render, transition }: Omit<TransitionedListInterface<T>, "isVirtualized" | "list"> & { list: T[] }) {
    const listContainerRef = useRef<HTMLOListElement>(null);

    const rowVirtualizer = useVirtualizer({
        count: list.length,
        getScrollElement: () => listContainerRef.current,
        estimateSize: () => 25,
        overscan: 5
    });

    const totalSize = rowVirtualizer.getTotalSize();
    const virtualRows = rowVirtualizer.getVirtualItems();

    const paddingTop = virtualRows[0]?.start || 0;
    const paddingBottom = virtualRows.length ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0) : 0;
    const hasTransition = Object.keys(transition).length > 0;

    return (
        <motion.ol ref={listContainerRef} className="relative flex max-h-full w-full flex-col overflow-auto overflow-x-hidden" style={{ height: totalSize }}>
            <AnimatePresence>
                {!!paddingTop && <div style={{ height: paddingTop }} />}
                {virtualRows.map(row => (
                    <motion.li
                        key={row.index}
                        data-index={row.index}
                        ref={rowVirtualizer.measureElement}
                        style={{ transform: `translateY(${row.start}px)` }}
                        className="absolute top-0 left-0 flex w-full items-center"
                        animate={hasTransition ? { ...transition.animate, y: row.start } : false}
                        exit={hasTransition ? { ...transition.exit, y: row.start } : undefined}
                        transition={transition.transition}
                        children={<div className="my-1 w-full">{render(list[row.index])}</div>}
                    />
                ))}
                {!!paddingBottom && <div style={{ height: paddingBottom }} />}
            </AnimatePresence>
        </motion.ol>
    );
}

export function List<T>({ list, emptyText, isVirtualized, disableAnimation, ...props }: ListInterface<T>) {
    if (!list) {
        return <Spin className="w-full" />;
    }
    if (list.length === 0) {
        return <div className="text-center text-grey-primary">{emptyText}</div>;
    }

    const transition: Transition = disableAnimation
        ? {}
        : {
              initial: { scale: 0.95, opacity: 0 },
              animate: { scale: 1, opacity: 1 },
              exit: { scale: 0.95, opacity: 0 },
              transition: { type: "spring", bounce: 0.25 }
          };

    if (isVirtualized) {
        return <VirtualizedList list={list} transition={transition} {...props} />;
    }
    return <StaticList list={list} transition={transition} {...props} />;
}
