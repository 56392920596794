import { REFERENTIAL } from "@vaultinum/vaultinum-api";
import { DATA_ROOM_DEFAULT_STRUCTURE, DATA_ROOM_DEFAULT_STRUCTURE_TEMPLATES, DataRoom, DataRoomTemplateStructure } from "@vaultinum/vaultinum-kys-api";
import { collection, converter, doc, getFirestore, onSnapshot } from "@vaultinum/vaultinum-sdk";

export function getDataRoomFolders(onUpdate: (folders: Omit<DataRoom.FolderMetaInfo, "id">[] | null) => void): () => void {
    return onSnapshot(
        doc(collection(getFirestore(), REFERENTIAL, DATA_ROOM_DEFAULT_STRUCTURE, DATA_ROOM_DEFAULT_STRUCTURE_TEMPLATES), "tdd_full").withConverter(
            converter<DataRoomTemplateStructure>()
        ),
        doc => onUpdate(doc.data()?.folders ?? null)
    );
}
