import classNames from "classnames";
import { last } from "lodash";
import { ElementType, useEffect, useState } from "react";
import { CheckIcon } from "../Icons";

type Step<T> = {
    key: T;
    title: string;
    subtitle?: string;
    icon?: ElementType;
};

function isPrevious(index: number, currentStepIndex: number): boolean {
    return currentStepIndex < 0 || index < currentStepIndex;
}

function isVisited(index: number, maxVisitedStepIndex: number): boolean {
    return index <= maxVisitedStepIndex;
}

function isCurrent(index: number, currentStepIndex: number): boolean {
    return index === currentStepIndex;
}

function isMaxVisited(index: number, maxVisitedStepIndex: number): boolean {
    return index === maxVisitedStepIndex;
}

function StepperContent<T>({ steps, index, currentStep }: { steps: Step<T>[]; index: number; currentStep: string }): JSX.Element {
    const Icon = steps[index].icon;
    const currentStepIndex = steps.findIndex(step => step.key === currentStep);
    if (isPrevious(index, currentStepIndex)) {
        return <CheckIcon color="white" />;
    }
    if (Icon) {
        return <Icon color={isCurrent(index, currentStepIndex) ? "pink" : "grey"} />;
    }
    return <>{index + 1}</>;
}

function isStepAccessible<T extends string = string>(steps: Step<T>[], stepDisabled: Record<T, boolean> | undefined, targetIndex: number): boolean {
    if (targetIndex === 0 || !stepDisabled) {
        return true;
    }
    return steps.slice(0, targetIndex).every(step => !stepDisabled[step.key]);
}

export function Stepper<T extends string = string>({
    steps,
    currentStepKey,
    setCurrentStepKey,
    stepDisabled,
    isEditing,
    isDisabled
}: {
    steps: Step<T>[];
    currentStepKey: string;
    setCurrentStepKey?: (key: T) => void;
    stepDisabled?: Record<T, boolean>;
    isEditing?: boolean;
    isDisabled?: boolean;
}) {
    const [maxVisitedStepKey, setMaxVisitedStepKey] = useState<string>((isEditing && last(steps)?.key) || currentStepKey);
    const currentStepIndex = steps.findIndex(step => step.key === currentStepKey);
    const maxVisitedStepIndex = steps.findIndex(step => step.key === maxVisitedStepKey);

    useEffect(() => {
        if (currentStepIndex > maxVisitedStepIndex) {
            setMaxVisitedStepKey(currentStepKey);
        }
    }, [currentStepKey, maxVisitedStepKey]);

    return (
        <div className="flex">
            {steps.map(({ key, title, subtitle }, index) => {
                const isCurrentStep = isCurrent(index, currentStepIndex);
                const isVisitedStep = isVisited(index, maxVisitedStepIndex);
                const isPreviousStep = isPrevious(index, currentStepIndex);
                const isCompletedStep = isPrevious(index, currentStepIndex);
                const isUpToCurrentStep = isCompletedStep || isCurrentStep;
                const isStepEnabled = isStepAccessible(steps, stepDisabled, index) && (isPreviousStep || (isVisitedStep && !isDisabled));
                return (
                    <div
                        key={index}
                        className={classNames("flex w-full flex-col gap-2", {
                            "text-slate-dark": !isUpToCurrentStep,
                            "text-pink-primary": isUpToCurrentStep
                        })}
                    >
                        <div className="flex items-center">
                            <div
                                className={classNames("h-0.5 grow border-b-2", {
                                    invisible: index === 0,
                                    "border-pink-primary": isUpToCurrentStep,
                                    "border-grey-light": !isCompletedStep && !isCurrentStep,
                                    "border-opacity-30": !isVisitedStep
                                })}
                            />
                            <div
                                className={classNames("flex h-8 w-8 items-center justify-center rounded-full border-2 font-bold", {
                                    "border-pink-primary bg-pink-primary": isCompletedStep,
                                    "border-pink-primary bg-white": isCurrentStep,
                                    "border-grey-light bg-grey-light": !isVisitedStep,
                                    "bg-white": !isCompletedStep,
                                    "opacity-30": !isVisitedStep,
                                    "cursor-pointer": isStepEnabled,
                                    "cursor-not-allowed": !isCurrentStep && !isStepEnabled
                                })}
                                onClick={() => isStepEnabled && setCurrentStepKey?.(key)}
                                children={<StepperContent steps={steps} index={index} currentStep={currentStepKey} />}
                            />
                            <div
                                className={classNames("h-0.5 grow border-b-2", {
                                    invisible: index === steps.length - 1,
                                    "border-pink-primary": isCompletedStep,
                                    "border-grey-light": (!isCompletedStep || isCurrentStep) && index <= steps.length,
                                    "border-opacity-30": !isVisitedStep || isMaxVisited(index, maxVisitedStepIndex)
                                })}
                            />
                        </div>
                        <div
                            className={classNames("flex flex-col text-center", {
                                "opacity-30": !isVisitedStep
                            })}
                        >
                            <span className="text-xs font-bold">{title}</span>
                            {subtitle && <span className="text-xs text-grey-dark">{subtitle}</span>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
