import { Route, Routes } from "react-router-dom";
import { EditNDAView, NonDisclosureAgreementView } from ".";
import { EDIT_NDA_PAGE } from "../../../services/routing.service";

export default function NDARouter(): JSX.Element {
    return (
        <Routes>
            <Route index element={<NonDisclosureAgreementView />} />
            <Route path={`/${EDIT_NDA_PAGE}`}>
                <Route path=":ndaVersion" element={<EditNDAView />} />
            </Route>
        </Routes>
    );
}
