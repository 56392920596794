import classNames from "classnames";
import { isArray, isString } from "lodash";
import { ElementType, Fragment, ReactNode } from "react";
import { Size } from "../../referentials";

export namespace SummaryProps {
    export type Cell = {
        label: ElementType | string;
        children: ReactNode;
    };
    export type Row = Cell | Cell[];
}

export interface SummaryProps {
    rows: SummaryProps.Row[];
    labelSize?: Size;
}

function mapLabelSize(labelSize: SummaryProps["labelSize"]): string {
    switch (labelSize) {
        case "xxs":
            return "w-16 max-w-16";
        case "xs":
            return "w-24 max-w-24";
        case "sm":
            return "w-32 max-w-32";
        case "md":
            return "w-48 max-w-48";
        case "lg":
            return "w-64 max-w-64";
        case "xl":
            return "w-96 max-w-96";
        default:
            return "";
    }
}

function Label({ label, labelSize }: Pick<SummaryProps.Cell, "label"> & Pick<SummaryProps, "labelSize">): JSX.Element {
    const sharedClasses = `table-cell py-1 pr-8 truncate ${mapLabelSize(labelSize)}`;
    if (isString(label)) {
        return <div title={label} className={classNames(sharedClasses, "text-grey-primary")} children={label} />;
    }
    const Icon = label;
    return <div className={sharedClasses} children={<Icon color="grey" />} />;
}

function Children({ children, isSingleCell, isLast = true }: Pick<SummaryProps.Cell, "children"> & { isSingleCell?: boolean; isLast?: boolean }): JSX.Element {
    return <div {...(isSingleCell && { colSpan: 100 })} className={classNames("table-cell py-1 text-dark", { "pr-16": !isLast })} children={children} />;
}

function hasMultipleCells(row: SummaryProps.Row): row is SummaryProps.Cell[] {
    return isArray(row);
}

function getRowId(row: SummaryProps.Row): string | undefined {
    return !hasMultipleCells(row) ? getCellId(row) : undefined;
}

function getCellId(cell: SummaryProps.Cell): string | undefined {
    return isString(cell.label) ? cell.label : undefined;
}

export default function Summary({ rows, labelSize }: SummaryProps) {
    return (
        <div
            className="table-auto"
            children={rows.map((row, i) => (
                <div key={getRowId(row) ?? `row_${i}`} className="table-row">
                    {hasMultipleCells(row) ? (
                        row.map((cell, j, cells) => (
                            <Fragment key={getCellId(cell) ?? `cell_${j}`}>
                                <Label label={cell.label} labelSize={labelSize} />
                                <Children children={cell.children} isLast={j === cells.length - 1} />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <Label label={row.label} labelSize={labelSize} />
                            <Children children={row.children} isSingleCell />
                        </>
                    )}
                </div>
            ))}
        />
    );
}
