import { Row, Table } from "@tanstack/react-table";
import { message } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { CommonLang, useLang } from "../../../lang";
import { Buttons } from "../Button";
import { ChevronDownIcon, ChevronRightIcon } from "../Icons";

export function ExpandRowButton<T>({ row, table }: { row: Row<T>; table?: Table<T> }): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const lang = useLang<CommonLang>();
    const meta: { isTree?: boolean; loadChildren?: (row: T) => Promise<void> } | undefined = table?.options.meta;

    async function onExpandClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): Promise<void> {
        event.stopPropagation();
        const expandRowHandler = row.getToggleExpandedHandler();
        if (meta?.loadChildren && !row.getIsExpanded() && row.subRows.length === 0) {
            try {
                setIsLoading(true);
                await meta.loadChildren(row.original);
            } catch {
                void message.error(lang.shared.failMessage);
            } finally {
                setIsLoading(false);
            }
        }
        expandRowHandler();
    }

    return (
        <div
            className={classNames("place-items-center", {
                "grid grid-cols-2 ": !meta?.isTree,
                "hover:cursor-pointer": row.getCanExpand()
            })}
            style={{ paddingLeft: `${row.depth}rem` }}
            {...(row.getCanExpand() && { onClick: onExpandClick })}
        >
            <div
                className={classNames("flex gap-2 items-center", {
                    "w-7": !meta?.isTree,
                    "w-4": meta?.isTree
                })}
            >
                {row.getCanExpand() && (
                    <Buttons.Icon
                        type="default"
                        fill="link"
                        isLoading={isLoading}
                        icon={row.getIsExpanded() ? ChevronDownIcon : ChevronRightIcon}
                        color="slate"
                    />
                )}
            </div>
            <div
                className={classNames("font-medium text-slate-dark", {
                    "col-start-2": !row.getCanExpand()
                })}
                {...(!meta?.isTree && { children: row.index + 1 })}
            />
        </div>
    );
}
