import classNames from "classnames";

export type CustomCardProps<T extends string = string> = {
    id: T;
    icon?: React.ElementType;
    label?: string;
    description?: string;
    onClick?: (id: T) => void;
    isSelected?: boolean;
    disabled?: boolean;
    labelClassName?: string;
    "data-id"?: string;
};

export function CustomCard<T extends string = string>({
    id,
    icon: Icon,
    label,
    description,
    onClick,
    isSelected,
    disabled,
    labelClassName,
    "data-id": dataId
}: CustomCardProps<T>): JSX.Element {
    return (
        <div
            onClick={!disabled ? () => onClick?.(id) : undefined}
            className={classNames("flex w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border p-6 text-center md:max-w-xs", {
                "bg-white": !isSelected,
                "border-primary bg-slate-primary text-white": isSelected,
                "cursor-not-allowed opacity-60": disabled
            })}
            data-id={dataId}
        >
            {Icon && <Icon color={isSelected ? "white" : "slate"} size="lg" />}
            {label && (
                <span
                    className={classNames(labelClassName, {
                        "text-slate-primary": !isSelected,
                        "text-white": isSelected
                    })}
                    children={label}
                />
            )}
            {description && (
                <span
                    className={classNames("text-xs font-normal", {
                        "text-grey-light": !isSelected,
                        "text-white": isSelected
                    })}
                    children={description}
                />
            )}
        </div>
    );
}
