import classNames from "classnames";
import { HTMLInputTypeAttribute, ReactNode } from "react";

export type FieldsetInterface = {
    children: ReactNode;
    label: string;
    className?: string;
    type?: "danger" | "success";
    isRequired?: boolean;
    isDisabled?: boolean;
};

export function Fieldset({ children, label, className, type, isRequired, isDisabled }: FieldsetInterface): JSX.Element {
    return (
        <fieldset
            className={classNames(`rounded border border-solid p-2 ${className || ""}`, {
                "cursor-not-allowed opacity-60": isDisabled,
                "border-success": type === "success",
                "border-danger": type === "danger",
                "border-neutral-300": !type
            })}
        >
            <legend
                className={classNames("m-0 w-auto px-2 text-sm", {
                    "text-success": type === "success",
                    "text-danger": type === "danger",
                    "text-neutral-400": !type,
                    "after:text-danger after:content-['*']": isRequired
                })}
                children={label}
            />
            {children}
        </fieldset>
    );
}

export type FieldsetInputInterface = {
    inputType?: HTMLInputTypeAttribute;
    inputClassName?: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<FieldsetInterface, "children">;

export function FieldsetInput({ inputType, inputClassName, placeholder, onChange, ...props }: FieldsetInputInterface): JSX.Element {
    const { isRequired, isDisabled, type } = props;
    return (
        <Fieldset {...props}>
            <input
                type={inputType}
                onChange={onChange}
                placeholder={placeholder}
                required={isRequired}
                disabled={isDisabled}
                className={classNames(`w-full border-0 outline-transparent ${inputClassName || ""}`, {
                    "text-success": type === "success",
                    "text-danger": type === "danger",
                    "text-neutral-800": type === undefined,
                    "cursor-not-allowed bg-transparent text-neutral-500": isDisabled
                })}
            />
        </Fieldset>
    );
}
