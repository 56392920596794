import { merge } from "lodash";
import { enCommon } from "../../../common";
import { BaseLang } from "../BaseLang";

export const en: BaseLang = merge(enCommon, {
    apps: {
        account: {
            name: "Account",
            description: "Manage your account and your organisation."
        },
        deposit: {
            name: "IP Deposit",
            description: "Deposit your digital assets in a few clicks on our secured servers."
        },
        escrow: {
            name: "Escrow agreement",
            description: "Set up the software escrow agreement adapted to your needs."
        },
        "full-audit": {
            name: "Due Diligence",
            description: "Perform a Software due diligence to mitigate your IP, cybersecurity and maintainability risks."
        },
        timestamping: {
            name: "Timestamping",
            description: "Prove the exact date and the data integrity of your important documents."
        }
    },
    register: {
        slogan: "The online solution to protect and audit your digital assets"
    }
});
