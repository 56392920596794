// ONLY CHANGE COLORS HERE
// Will be moved at build time
export const COLOR_SCHEME = {
    slate: {
        primary: "#294966",
        dark: "#163046",
        light: "#4A7398",
        extraLight: "#72ACE1"
    },
    grey: {
        primary: "#8A8E92",
        dark: "#585A5C",
        light: "#DEE1E4",
        extraLight: "#F2F3F4"
    },
    green: {
        primary: "#11C58F",
        dark: "#127759",
        light: "#69E0BC",
        extraLight: "#B8F6E4"
    },
    blue: {
        primary: "#5985F6",
        dark: "#4B66AC",
        light: "#8BC0FE",
        extraLight: "#C7E1FF"
    },
    orange: {
        primary: "#FBAA5F",
        dark: "#DA7C24",
        light: "#FFD058",
        extraLight: "#FFEAB4"
    },
    red: {
        primary: "#E33737",
        dark: "#BE2B2B",
        light: "#FF8B8B",
        extraLight: "#FFCBCB"
    },
    purple: {
        primary: "#A483CE",
        dark: "#7D4CBB",
        light: "#C2A3E9",
        extraLight: "#E9D8FF"
    },
    pink: {
        primary: "#C71585",
        dark: "#B72A83",
        light: "#F592D1",
        extraLight: "#FFE6F9"
    },
    white: {
        primary: "#fff",
        dark: "#fff",
        light: "#fff",
        extraLight: "#fff"
    }
} as const;
