(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@vaultinum/vaultinum-api"), require("axios"), require("classnames"), require("dayjs"), require("lodash"), require("react"), require("react-dom"), require("react-router-dom"), require("yup"));
	else if(typeof define === 'function' && define.amd)
		define(["@vaultinum/vaultinum-api", "axios", "classnames", "dayjs", "lodash", "react", "react-dom", "react-router-dom", "yup"], factory);
	else if(typeof exports === 'object')
		exports["VaultinumSDK"] = factory(require("@vaultinum/vaultinum-api"), require("axios"), require("classnames"), require("dayjs"), require("lodash"), require("react"), require("react-dom"), require("react-router-dom"), require("yup"));
	else
		root["VaultinumSDK"] = factory(root["@vaultinum/vaultinum-api"], root["axios"], root["classnames"], root["dayjs"], root["lodash"], root["react"], root["react-dom"], root["react-router-dom"], root["yup"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__6152__, __WEBPACK_EXTERNAL_MODULE__3300__, __WEBPACK_EXTERNAL_MODULE__8310__, __WEBPACK_EXTERNAL_MODULE__5760__, __WEBPACK_EXTERNAL_MODULE__467__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__6128__, __WEBPACK_EXTERNAL_MODULE__7589__) => {
return 