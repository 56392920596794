import { LANG_EN, SUPPORTED_LANGUAGES_CODES, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { NonDisclosureAgreement } from "@vaultinum/vaultinum-kys-api";
import { Button, message, Select, SUPPORTED_LANGUAGES } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { ChangeEvent, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import { useNDAVersions } from "../../../hooks";
import { getNDAVersion, NDA_PAGE, updateNDAVersion } from "../../../services";

function MarkdownEditor({
    nda,
    setNDA,
    ndaLangs,
    setNDALangs,
    isEditable
}: {
    nda: string;
    setNDA: (value: string) => void;
    ndaLangs: SupportedLanguageCode;
    setNDALangs: (value: SupportedLanguageCode) => void;
    isEditable: boolean;
}): JSX.Element {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setNDA(event.target.value);
    };

    return (
        <div className="flex flex-col gap-4">
            <Select.Basic
                value={ndaLangs}
                onChange={setNDALangs}
                options={SUPPORTED_LANGUAGES_CODES.map(language => ({
                    value: language,
                    label: SUPPORTED_LANGUAGES.find(lang => lang.code === language)?.label
                }))}
                className="w-32"
            />
            <div className="flex gap-4">
                <textarea
                    className={classNames("w-1/2 rounded border p-4", { "cursor-not-allowed": !isEditable })}
                    value={nda}
                    disabled={!isEditable}
                    onChange={handleChange}
                />
                <div className="w-1/2 overflow-scroll border-gray-300 bg-gray-100 p-8">
                    <ReactMarkdown className="markdown whitespace-pre-wrap" children={nda} />
                </div>
            </div>
        </div>
    );
}

export default function EditNDAView(): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const [nda, setNDA] = useState("");
    const [selectedNDA, setSelectedNDA] = useState<NonDisclosureAgreement | null>(null);
    const [ndaLangs, setNDALangs] = useState<SupportedLanguageCode>(LANG_EN);
    const { latestPublishedNDA } = useNDAVersions();
    const { ndaVersion } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        void (async () => {
            if (!ndaVersion) {
                return;
            }
            try {
                const selectedNDA = await getNDAVersion(ndaVersion);
                setSelectedNDA(selectedNDA);
                setNDA(selectedNDA?.langs?.[ndaLangs] || "");
            } catch (err) {
                await message.error("Failed to fetch NDA version");
            }
        })();
    }, [ndaLangs, ndaVersion]);

    const isNDAEditable = (selectedNDA?.latest === false && latestPublishedNDA && selectedNDA.version > latestPublishedNDA.version) || !latestPublishedNDA;

    async function saveNDA() {
        if (!selectedNDA) {
            return;
        }
        setIsLoading(true);
        await updateNDAVersion(selectedNDA.version, ndaLangs, nda);
        setIsLoading(false);
        navigate(NDA_PAGE);
    }

    return (
        <div className="flex flex-col gap-4">
            <MarkdownEditor nda={nda} setNDA={setNDA} ndaLangs={ndaLangs} setNDALangs={setNDALangs} isEditable={isNDAEditable} />
            <div className="flex justify-end gap-2">
                <Button onClick={() => navigate(NDA_PAGE)} children="Back" isLoading={false} type="default" />
                <Button onClick={saveNDA} isLoading={isLoading} children="Save" isDisabled={!nda || nda === selectedNDA?.langs?.[ndaLangs] || !selectedNDA} />
            </div>
        </div>
    );
}
