export enum SortDirection {
    ASCENDING = "asc",
    DESCENDING = "desc"
}

export interface FiltersSortService {
    saveFilter: (key: string, filters?: unknown[]) => void;
    loadFilter: (key: string) => unknown[];
    loadFilters: () => Record<string, unknown[]>;
    saveSort: (key: string, direction: string | boolean) => void;
    loadSort: (key: string) => string | boolean;
    loadSorts: () => Record<string, SortDirection>;
}

export enum ListStorageKey {
    FULL_AUDIT = "list-full-audit"
}
