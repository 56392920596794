import { isNumber } from "lodash";
import { useState } from "react";
import { Input, plural, useLang } from "../../../../common";

export function Layout({
    render,
    setSearchText,
    searchProps,
    resultsCount,
    totalCount,
    filters,
    buttons
}: {
    render: JSX.Element;
    setSearchText: (text: string) => void;
    searchProps?: {
        navigation: {
            onNext: () => void;
            onPrevious: () => void;
        };
    };
    resultsCount?: number;
    totalCount?: number;
    filters?: JSX.Element;
    buttons?: JSX.Element;
}): JSX.Element {
    const lang = useLang();
    const [value, setValue] = useState<string>("");

    return (
        <div className="h-full">
            <div className="md:flex items-center justify-between space-y-2 sm:space-y-0 mb-4">
                <div className="flex gap-2 items-center">
                    <Input.Search
                        className="w-72"
                        placeholder={lang.shared.search}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        debounce={{
                            wait: 300,
                            callback: e => {
                                if (e.target.value.length) {
                                    setSearchText(e.target.value);
                                } else {
                                    setSearchText("");
                                }
                            }
                        }}
                        {...searchProps}
                    />
                    {isNumber(resultsCount) && (
                        <span className="text-slate-light font-semibold whitespace-nowrap">{plural(lang.shared.results, resultsCount, totalCount)}</span>
                    )}
                </div>
                <div className="flex justify-center md:justify-end gap-2">
                    {filters}
                    {buttons}
                </div>
            </div>
            {render}
        </div>
    );
}
