import { Result } from "antd";
import { ResultStatusType } from "antd/lib/result";
import { ReactNode } from "react";
import { Skeleton } from "../Skeleton";

/**
 * Responsive card with a left and right panel used for small form pages (authentication pages for example).
 * Left panel (called "extra") should not contain important elements since it gets hidden on small screens.
 */
export default function CardWithFeedback({
    extra,
    title,
    isLoading,
    status,
    successTitle,
    successExtra,
    errorTitle,
    errorExtra,
    children
}: {
    extra: ReactNode;
    title?: ReactNode;
    isLoading?: boolean;
    status?: "success" | "error";
    successTitle?: ReactNode;
    successExtra?: ReactNode;
    errorTitle?: ReactNode;
    errorExtra?: ReactNode;
    children?: ReactNode;
}): JSX.Element {
    const getContent = (resultStatus?: ResultStatusType): ReactNode => {
        switch (resultStatus) {
            case "success":
                return <Result status={resultStatus} title={successTitle} extra={successExtra} />;
            case "error":
                return <Result status={resultStatus} title={errorTitle} extra={errorExtra} />;
            default:
                return children;
        }
    };

    return (
        <div className="bg-white shadow-md min-w-1/2">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="hidden items-center justify-center p-4 md:flex border-r">{extra}</div>
                {isLoading ? (
                    <div className="flex flex-col gap-4 p-6">
                        {Array.from({ length: 3 }).map((_, index) => (
                            <Skeleton key={index} className="h-4 w-full" />
                        ))}
                        <Skeleton className="h-4 w-2/3" />
                    </div>
                ) : (
                    <div>
                        {title && !status && <h1 className="text-lg font-normal p-6 border-b">{title}</h1>}
                        <div className="p-6">{getContent(status)}</div>
                    </div>
                )}
            </div>
        </div>
    );
}
