import { Account, LANG_EN } from "@vaultinum/vaultinum-api";
import { Audit } from "@vaultinum/vaultinum-kys-api";
import { Column, formatDate, formatDateTime, Table, Tag } from "@vaultinum/vaultinum-sdk";

function getFullAuditColumns(accountId: string): Column<Audit>[] {
    return [
        {
            header: "Creation date",
            accessorKey: "creationDate",
            cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN)
        },
        {
            header: "Target Completion Date",
            accessorKey: "targetCompletionDate",
            cell: cell => {
                const targetCompletionDate = cell.getValue<Date>();
                return targetCompletionDate ? formatDate(targetCompletionDate, LANG_EN) : "-";
            }
        },
        {
            header: "Completion Date",
            accessorKey: "completionDate",
            cell: cell => {
                const completionDate = cell.getValue<Date>();
                return completionDate ? formatDate(completionDate, LANG_EN) : "-";
            }
        },
        {
            header: "Product name",
            accessorFn: row => row.product?.name
        },
        {
            header: "Audited / Beneficiary",
            enableColumnFilter: false,
            enableSorting: false,
            accessorFn: row => row.roles,
            cell: cell => {
                const fullAudit = cell.row.original;
                return (
                    <div className="flex flex-col gap-1">
                        {fullAudit.roles[accountId].includes(Audit.Role.AUDITED) && <Tag type="info" message="🧑‍💻 Audited" />}
                        {fullAudit.roles[accountId].includes(Audit.Role.BENEFICIARY) && <Tag type="info" message="🔖 Beneficiary" isOutlined />}
                    </div>
                );
            }
        },
        {
            header: "Scopes",
            accessorKey: "scopes",
            cell: cell => (
                <div className="flex items-center space-x-1 space-y-1">{cell.getValue<string[]>()?.map(scope => <Tag key={scope} message={scope} />)}</div>
            )
        }
    ];
}

export default function FullAudits({ account, fullAudits }: { account: Account; fullAudits: Audit[] }) {
    const fullAuditColumns = getFullAuditColumns(account.id);

    return <Table<Audit> data={fullAudits} columns={fullAuditColumns} />;
}
