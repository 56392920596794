import { Chart } from "react-google-charts";
import { ChartColumnDefinition, getLegendConfig } from "../config";

export type PieChartDataSetItem = {
    label: string;
    value: number;
    color?: string;
};

export type PieChartParams = {
    dataset: PieChartDataSetItem[];
    isPercentage?: boolean;
    height?: string | number;
    width?: string | number;
    legendPosition?: "top" | "bottom" | "left" | "right" | "none" | "labeled";
    tooltip?: (values: PieChartDataSetItem, index: number) => string;
};

export function PieChart({ dataset, width, height, isPercentage, tooltip, legendPosition = "labeled" }: PieChartParams): JSX.Element {
    const columnDefintions: ChartColumnDefinition[] = ["key", "value"];
    if (tooltip) {
        columnDefintions.push({ role: "tooltip", type: "string", p: { html: true } });
    }
    return (
        <Chart
            chartType="PieChart"
            width={width ?? "100%"}
            height={height ?? "90%"}
            options={{
                legend: getLegendConfig(legendPosition, "center", { fontSize: 14 }),
                pieSliceText: isPercentage ? "percentage" : "value",
                chartArea: {
                    left: 10,
                    right: 10,
                    top: legendPosition === "top" ? 30 : 10,
                    bottom: legendPosition === "bottom" ? 30 : 10
                },
                slices: dataset.map(slice => ({ color: slice.color, textStyle: { fontSize: 14 } })),
                tooltip: {
                    isHtml: !!tooltip
                }
            }}
            data={[columnDefintions, ...dataset.map((slice, index) => [slice.label, slice.value, ...(tooltip ? [tooltip(slice, index)] : [])])]}
            className="pie-chart"
        />
    );
}
