import { Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Button, Buttons, Column, DeleteIcon, EditIcon, ModalHelper, Table, useLang, User } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { updateLang } from "../../../../../services/surveyLangService";
import AddOrEditSurveyEvaluationTagModal from "./AddOrEditSurveyEvaluationTagModal";
import { removeEvaluationFromSections } from "./evaluation-tag.helper";

type Tag = {
    key: string;
    tag: string;
    name: string;
    description: string;
};

function getColumns(onEdit: (tagKey: string) => void, onDelete: (tagKey: string) => void): Column<Tag>[] {
    return [
        {
            header: "Tag",
            accessorKey: "tag"
        },
        {
            header: "Name",
            accessorKey: "name"
        },
        {
            header: "Description",
            accessorKey: "description"
        },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => {
                const { tag } = cell.row.original;
                return (
                    <div className="flex gap-2">
                        <Buttons.Icon isLoading={false} type="default" icon={EditIcon} onClick={() => onEdit(tag)} title="Edit tag" />
                        <Buttons.Icon isLoading={false} type="default" icon={DeleteIcon} onClick={() => onDelete(tag)} title="Delete tag" />
                    </div>
                );
            }
        }
    ];
}

export default function SurveyEvaluationTagView({
    surveyVersion,
    setSurveyVersion,
    surveyLang,
    user
}: {
    surveyVersion: Survey.Version;
    setSurveyVersion: (surveyVersion: Survey.Version) => void;
    surveyLang: Survey.Lang;
    user: User;
}) {
    const lang = useLang<BaseLang>();
    const [editEvalTag, setEditEvalTag] = useState<{ tag: string } | null>(null);

    const onDeleteTagEvaluation = (tagToDelete: string) => {
        ModalHelper.Confirm({
            title: "Delete this evalution tag?",
            children: `tag=${tagToDelete}`,
            onConfirm: () => {
                updateLang(surveyLang, user.uid).evaluationTag(tagToDelete).delete();
                setSurveyVersion({
                    ...surveyVersion,
                    evaluationTags: surveyVersion.evaluationTags.filter(tag => tag !== tagToDelete),
                    sections: removeEvaluationFromSections(surveyVersion.sections, tagToDelete)
                });
            },
            lang
        });
    };

    const onEditTagEvaluation = (tag: string) => {
        setEditEvalTag({ tag });
    };
    const columns = getColumns(onEditTagEvaluation, onDeleteTagEvaluation);

    return (
        <div className="flex flex-col gap-2">
            <div className="ml-auto">
                <Button isLoading={false} onClick={() => setEditEvalTag({ tag: "" })} children="Add Evaluation Tag" />
            </div>
            {editEvalTag && (
                <AddOrEditSurveyEvaluationTagModal
                    isOpen={!!editEvalTag}
                    onClose={() => setEditEvalTag(null)}
                    evalTag={editEvalTag}
                    surveyVersion={surveyVersion}
                    surveyLang={surveyLang}
                    setSurveyVersion={setSurveyVersion}
                    user={user}
                />
            )}
            <Table<Tag>
                data={surveyVersion.evaluationTags.map(tag => ({
                    key: tag,
                    tag,
                    name: surveyLang.evaluationTag[tag]?.name,
                    description: surveyLang.evaluationTag[tag]?.description
                }))}
                columns={columns}
            />
        </div>
    );
}
