/**
 * @deprecated - Do not update, this file is kept to handle legacy code. It will be moved to kys-web-app in future.
 */
import {
    AssetRequest,
    FullAudit,
    FULL_AUDIT_ASSET_REQUESTS_COLLECTION,
    FULL_AUDIT_COLLECTION,
    FULL_AUDIT_FILES_COLLECTION,
    FULL_AUDIT_REPORTS_COLLECTION,
    Report,
    SurveyReport,
    Writable
} from "@vaultinum/vaultinum-api";
import {
    addDoc,
    collection,
    collectionGroup,
    CollectionReference,
    converter,
    deleteDoc,
    deleteField,
    doc,
    DocumentReference,
    getFirestore,
    getItem,
    getItems,
    orderBy,
    Query,
    query,
    Unsubscribe,
    updateDoc,
    where
} from "../../../common";

/*
 *  Full Audit
 */
function fullAuditCollection(): CollectionReference<FullAudit> {
    return collection(getFirestore(), FULL_AUDIT_COLLECTION).withConverter(converter<FullAudit>());
}

export function fullAuditDoc(fullAuditId: string): DocumentReference<FullAudit> {
    return doc(fullAuditCollection(), fullAuditId);
}

export function getFullAudits(): Promise<FullAudit[]>;
export function getFullAudits(onUpdate: (fullAudits: FullAudit[]) => void): Unsubscribe;
export function getFullAudits(onUpdate?: (fullAudits: FullAudit[]) => void): Unsubscribe | Promise<FullAudit[]> {
    if (onUpdate) {
        return getItems(fullAuditCollection(), onUpdate);
    }
    return getItems(fullAuditCollection());
}

export function deleteFullAuditCodeAnalysisReportId(fullAuditId: string): Promise<void> {
    return updateDoc(fullAuditDoc(fullAuditId), { ["reportIds.codeAuditReportId"]: deleteField() });
}

/*
 *  Full Audit Asset requests
 */

export function fullAuditAssetRequestsCollection(fullAuditId: string): CollectionReference<AssetRequest> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_ASSET_REQUESTS_COLLECTION).withConverter(converter<AssetRequest>());
}

/*
 *  Full Audit Reports
 */

const CONVERTER = converter<SurveyReport>();

export function reportGroupCollection(): Query<SurveyReport> {
    return collectionGroup(getFirestore(), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

function reportCollection(fullAuditId: string): CollectionReference<SurveyReport> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

export function reportDoc(fullAuditId: string, reportId: string): DocumentReference<SurveyReport> {
    return doc(reportCollection(fullAuditId), reportId);
}

export function getSurveyReports(onUpdate: (surveyReports: SurveyReport[]) => void): () => void;
export function getSurveyReports(fullAuditId: string): Promise<SurveyReport[]>;
export function getSurveyReports(fullAuditId: string, onUpdate: (surveyReports: SurveyReport[]) => void): () => void;
export function getSurveyReports(
    idOrUpdate: string | ((surveyReports: SurveyReport[]) => void),
    onUpdate?: (surveyReports: SurveyReport[]) => void
): Promise<SurveyReport[]> | (() => void) {
    const constraints = [where("reportType", "==", Report.ReportType.SURVEY), where("fullAuditId", "!=", null)];
    if (typeof idOrUpdate === "function") {
        const q = query(reportGroupCollection(), ...constraints);
        return getItems(q, idOrUpdate);
    }
    const q = query(reportCollection(idOrUpdate), ...constraints);
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}

export async function getSurveyReport(fullAuditId: string, reportId: string): Promise<SurveyReport | null> {
    return getItem(reportDoc(fullAuditId, reportId));
}

/*
 *  Full Audit Files
 */

function fullAuditFilesCollection(fullAuditId: string): CollectionReference<FullAudit.File> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_FILES_COLLECTION).withConverter(converter<FullAudit.File>());
}

function fullAuditFilesDoc(fullAuditId: string, fileId: string): DocumentReference<FullAudit.File> {
    return doc(fullAuditFilesCollection(fullAuditId), fileId);
}

export function getFullAuditFiles(fullAuditId: string): Promise<FullAudit.File[]>;
export function getFullAuditFiles(fullAuditId: string, onUpdate: (files: FullAudit.File[]) => void): Unsubscribe;
export function getFullAuditFiles(fullAuditId: string, onUpdate?: (files: FullAudit.File[]) => void): Unsubscribe | Promise<FullAudit.File[]> {
    const q = query(fullAuditFilesCollection(fullAuditId), orderBy("name"));
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}

export async function addFullAuditFile(fullAuditId: string, file: Writable<FullAudit.File>): Promise<FullAudit.File> {
    const fileToAdd = { ...file, fullAuditId };
    const addedFile = await addDoc(fullAuditFilesCollection(fullAuditId), fileToAdd);
    return { ...fileToAdd, id: addedFile.id };
}

export async function updateFullAuditFile(fullAuditId: string, file: FullAudit.File, name: string, description: string): Promise<FullAudit.File> {
    await updateDoc(fullAuditFilesDoc(fullAuditId, file.id), { name, description });
    return { ...file, name, description };
}

export function deleteFullAuditFile(fullAuditId: string, fileId: string): Promise<void> {
    return deleteDoc(fullAuditFilesDoc(fullAuditId, fileId));
}
